import {
  Box,
  Divider,
  IconButton,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { Player as Lottie } from '@lottiefiles/react-lottie-player'
import ThumbsUpAnimation from 'assets/lottie/thumb-success.json'
import { AnimatePresence, motion } from 'framer-motion'
import { Button, Input } from 'common/components'
import { useFormik } from 'formik'
import { useFeedbackQuery } from 'hooks'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FeedbackPayloadTypes } from 'services'
import { MoodTypes } from '../../AsideFeedback.types'
import { MOOD_LIST } from '../../utils/moodList'
import { AsideMoodItemComponent } from '../moodItem/AsideMoodItem'
import {
  AsideFeedbackContainerStyles,
  AsideFeedbackHeaderStyles,
  AsideFeedbackInitialContentStyles,
  AsideFeedbackMessageStyles,
  AsideFeedbackMoodStyles
} from './Content.styles'
import { ContentPropTypes } from './Content.types'

const animationProps = {
  initial: {
    opacity: 0
  },
  exit: {
    opacity: 0
  },
  animate: {
    opacity: 1
  },
  transition: {
    duration: 0.25
  }
}

export const AsideFeedbackContent: React.FC<ContentPropTypes> = ({
  onClose,
  onMoodChange
}) => {
  const { t } = useTranslation()
  const isMobileScreen = useMediaQuery(useTheme().breakpoints.down('md'))

  const [selectedMood, setSelectedMood] = useState<MoodTypes | null>(null)

  const { feedbackQuery } = useFeedbackQuery()

  const formik = useFormik<FeedbackPayloadTypes>({
    initialValues: {
      rating: null,
      description: null
    },
    onSubmit: (values, { resetForm }) => {
      feedbackQuery.mutate(values)
      if (feedbackQuery.isSuccess) resetForm()
    }
  })

  const inputRef = useRef<HTMLInputElement>(null)

  const handleClose = () => {
    if (onClose) onClose()

    setTimeout(() => {
      setSelectedMood(null)
      formik.resetForm()
      feedbackQuery.reset()
    }, 1000)
  }
  return (
    <AsideFeedbackContainerStyles
      onSubmit={formik.handleSubmit}
      autoComplete='off'
    >
      <AnimatePresence exitBeforeEnter>
        <AsideFeedbackInitialContentStyles>
          <AsideFeedbackHeaderStyles>
            <Box
              display='flex'
              alignItems='center'
              gap={2}
              color={feedbackQuery.isSuccess ? 'primary.main' : undefined}
            >
              {feedbackQuery.isSuccess && (
                <motion.div
                  {...animationProps}
                  key='LottieIconAside'
                  onAnimationStart={() => {
                    if (onMoodChange) onMoodChange()
                  }}
                >
                  <Lottie
                    autoplay
                    src={ThumbsUpAnimation}
                    style={{
                      height: 32,
                      width: 32,
                      position: 'relative',
                      transform: 'scale(2)'
                    }}
                  />
                </motion.div>
              )}
              <Typography
                color='inherit'
                component='h6'
                variant={isMobileScreen ? 'h4' : 'body1'}
                fontWeight={(theme) => theme.typography.fontWeightBold}
              >
                {t(
                  feedbackQuery.isSuccess
                    ? 'navigation.feedback.popover.success.title'
                    : 'navigation.feedback.popover.title'
                )}
              </Typography>
            </Box>
            <IconButton onClick={() => handleClose()} sx={{ mx: -1.5 }}>
              <SvgIcon component={Close} />
            </IconButton>
          </AsideFeedbackHeaderStyles>
          {!feedbackQuery.isSuccess && (
            <AsideFeedbackMoodStyles>
              {MOOD_LIST.map((mood) => (
                <AsideMoodItemComponent
                  key={mood.id}
                  mood={mood}
                  isUnselected={!selectedMood}
                  isActive={selectedMood?.id === mood.id}
                  onClick={() => {
                    setSelectedMood(mood)
                    formik.setFieldValue('rating', mood.value)
                    inputRef.current?.focus()
                  }}
                />
              ))}
            </AsideFeedbackMoodStyles>
          )}
        </AsideFeedbackInitialContentStyles>
        {selectedMood && !feedbackQuery.isSuccess && (
          <motion.div
            {...animationProps}
            key='FeedbackInput'
            onAnimationStart={() => {
              if (onMoodChange) onMoodChange()
            }}
          >
            <Divider />
            <AsideFeedbackMessageStyles>
              <Input
                name='description'
                type='text'
                multiline
                fullWidth
                margin='none'
                placeholder={t('navigation.feedback.popover.input.placeholder')}
                minRows={3}
                maxRows={6}
                value={formik.values.description}
                helperText={formik.errors.description}
                error={!!formik.errors.description}
                onChange={formik.handleChange}
                ref={inputRef}
              />
              <Box display='flex' alignItems='flex-end' px={3} pb={3}>
                <Button
                  type='submit'
                  loading={feedbackQuery.isLoading}
                  variant='contained'
                  color='primary'
                  value={t('navigation.feedback.popover.button.text')}
                  disabled={formik.values.rating === null}
                />
              </Box>
            </AsideFeedbackMessageStyles>
          </motion.div>
        )}
      </AnimatePresence>
    </AsideFeedbackContainerStyles>
  )
}
