import React, { lazy } from 'react'
import { Route } from 'react-router-dom'

import { ROUTES } from 'router'

const NotFound = lazy(() => import('views/generic/notFound/NotFound'))

export const renderGenericRoutes = () => {
  const { UNKNOWN } = ROUTES

  return <Route path={UNKNOWN} element={<NotFound />} />
}
