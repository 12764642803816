import { api, ApiResponseTypes, ModeTypes } from 'services'

const getGameMode = async (gameModeId: ModeTypes['id']) => {
  const { data: response } = await api.get<ApiResponseTypes<ModeTypes>>(
    `/gameModes/${gameModeId}`
  )

  return response.data
}

export default {
  get: getGameMode
}
