import axios from 'axios'
import { ServiceInterceptors } from 'services/services.interceptors'

const licenseApi = axios.create({
  baseURL: process.env.REACT_APP_LICENSE_API_URL,
  withCredentials: true
})

ServiceInterceptors(licenseApi)

export * from './lib'
export { licenseApi }
