import { useInfiniteStudentQuery, useParamsSelector } from 'hooks'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'

import { PostUserGroupTypes, PutUserGroupTypes, StudentTypes } from 'services'

import { GroupDialogPropTypes } from '../GroupDialog.types'
import { GroupDialogContextTypes } from './GroupDialog.context.types'

const DialogGroupContext = createContext<GroupDialogContextTypes>(
  {} as GroupDialogContextTypes
)

export const GroupDialogContextWrapper: React.FC<GroupDialogPropTypes> = ({
  children,
  group,
  ...rest
}) => {
  const { t } = useTranslation()
  const initialState: PostUserGroupTypes = {
    name: t('pages.group.header.groupName'),
    students: []
  }

  const params = useParamsSelector()

  const updateInitialState = group
    ? {
        name: group.name,
        students: group.students.flatMap((student) => student.id)
      }
    : initialState

  const [createdUserGroup, setToCreateUserGroup] =
    useState<PostUserGroupTypes>(initialState)

  const [updatedUserGroup, setToUpdateUserGroup] =
    useState<PutUserGroupTypes>(updateInitialState)

  const [
    selectedStudentsToCreateUserGroup,
    setSelectedStudentsToCreateUserGroup
  ] = useState<StudentTypes[]>([])

  const [
    selectedStudentsToUpdateUserGroup,
    setSelectedStudentsToUpdateUserGroup
  ] = useState(group?.students || [])

  const isCreation = !group
  const isEdition = !isCreation

  const isCustomGroup = !group?.feideId
  const isFeideGroup = !isCustomGroup

  const handleClose = () => {
    if (rest.onClose) {
      rest.onClose({}, 'backdropClick')
      setSelectedStudentsToCreateUserGroup([])
      params.delete('searchStudent')
    }
  }

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteStudentQuery({
      enabled: true,
      useParams: true
    })

  useEffect(() => {
    setToCreateUserGroup((cur) => ({
      ...cur,
      students: selectedStudentsToCreateUserGroup.flatMap(
        (student) => student.id
      )
    }))
  }, [selectedStudentsToCreateUserGroup])

  useEffect(() => {
    setToUpdateUserGroup((cur) => ({
      ...cur,
      students: selectedStudentsToUpdateUserGroup.flatMap(
        (student) => student.id
      )
    }))
  }, [selectedStudentsToUpdateUserGroup])

  const studentQuery = { data, fetchNextPage, isFetchingNextPage, hasNextPage }

  const value: GroupDialogContextTypes = useMemo(
    () => ({
      ...rest,
      createdUserGroup,
      updatedUserGroup,
      handleClose,
      setToUpdateUserGroup,
      setToCreateUserGroup,
      isCreation,
      isEdition,
      selectedStudentsToCreateUserGroup,
      setSelectedStudentsToCreateUserGroup,
      selectedStudentsToUpdateUserGroup,
      setSelectedStudentsToUpdateUserGroup,
      isCustomGroup,
      isFeideGroup,
      studentQuery
    }),
    [
      rest,
      createdUserGroup,
      updatedUserGroup,
      selectedStudentsToCreateUserGroup,
      isCustomGroup,
      isFeideGroup,
      studentQuery,
      isCreation,
      isEdition
    ]
  )

  return (
    <DialogGroupContext.Provider value={value}>
      {children}
    </DialogGroupContext.Provider>
  )
}

export const useGroupDialogContext = () => {
  const context = useContext(DialogGroupContext)
  if (!context) {
    throw new Error('undefined context')
  }
  return context
}
