import {
  CheckCircleOutlineRounded,
  ErrorOutlineRounded
} from '@mui/icons-material'
import { useActionState, useForwardedRef } from 'hooks'
import React, { forwardRef, useRef } from 'react'
import {
  HelperTextStyles,
  InputAdornmentStyles,
  InputStyles,
  InputLabelStyles,
  FormControlStyles
} from './Input.styles'
import { InputPropTypes } from './Input.types'

export const Input = forwardRef<HTMLInputElement, InputPropTypes>(
  (
    {
      name,
      label,
      inputProps,
      helperText,
      error = false,
      success = false,
      focused = false,
      hovered = false,
      flex,
      ...rest
    },
    ref
  ) => {
    const inputElementRef = useForwardedRef<HTMLInputElement>(ref)
    const hoverElementRef = useRef<HTMLDivElement>(null)

    const { hasError, hasFocus, hasHover, hasSuccess } = useActionState(
      { error, focused, success, hovered },
      inputElementRef,
      hoverElementRef
    )

    const endAdornment = () => {
      const { type, endAdornment } = rest

      if (type === 'date') return null

      if (endAdornment) return endAdornment

      return (
        <InputAdornmentStyles position='end'>
          {hasError && <ErrorOutlineRounded color='error' />}
          {hasSuccess && <CheckCircleOutlineRounded color='success' />}
        </InputAdornmentStyles>
      )
    }

    return (
      <FormControlStyles
        fullWidth={rest.fullWidth}
        variant='standard'
        sx={{ flex }}
        ref={hoverElementRef}
      >
        {label && (
          <InputLabelStyles
            shrink
            htmlFor={name}
            required={rest.required}
            error={hasError}
            success={hasSuccess}
            focused={hasFocus}
          >
            {label}
          </InputLabelStyles>
        )}
        <InputStyles
          {...rest}
          inputProps={{ 'data-testid': 'inputComponent', ...inputProps }}
          id={name}
          name={name}
          type={rest.type || 'text'}
          inputRef={inputElementRef}
          error={hasError}
          success={hasSuccess}
          focused={hasFocus}
          hovered={hasHover}
          endAdornment={endAdornment()}
        />
        {Boolean(helperText) && (
          <HelperTextStyles
            as='span'
            id={name}
            error={hasError}
            success={hasSuccess}
          >
            {helperText}
          </HelperTextStyles>
        )}
      </FormControlStyles>
    )
  }
)

export * from './Input.types'
export { HelperTextStyles as HelperTextComponent } from './Input.styles'
export { InputLabelStyles as InputLabelComponent } from './Input.styles'
