import { CheckCircle } from '@mui/icons-material'
import { from } from 'common/theme'
import styled, { css } from 'styled-components'
import { Box } from '@mui/material'
import { GameCardPropTypes } from './GameCard.types'

export const GameCardStyles = styled(Box)<{
  isSelected?: GameCardPropTypes['isSelected']
}>`
  display: flex;
  flex-direction: column;

  position: relative;

  width: 100%;
  height: 100%;

  background: ${({ theme }) => theme.palette.common.white};

  border-radius: 0.8rem;
  border: 0.1rem solid ${({ theme }) => theme.palette.grey[100]};
  outline: 0.2rem solid transparent;

  overflow: hidden;

  transition: all 0.3s ease-in;

  :hover {
    box-shadow: 0 0.3rem 2rem ${({ theme }) => theme.palette.grey[50]};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      outline-color: ${({ theme }) => theme.palette.secondary.main};
      border-color: ${({ theme }) => theme.palette.secondary.main};
      border-radius: 1.2rem;
    `}
`

export const GameCardImageStyles = styled.div`
  position: relative;
  .MuiChip-root {
    z-index: 10;
    position: absolute;
    background: ${({ theme }) => theme.palette.common.white};
    margin: 0.8rem;
  }
  img {
    display: none;
    z-index: 5;
  }

  ${from.sm} {
    img {
      object-fit: cover;
      display: initial;
      min-height: 14.4rem;
      width: 100%;
      height: 100%;
    }
  }
`

export const GameCardContentStyles = styled.div`
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 1.6rem;
`

export const CheckCircleTwoToneStyles = styled(CheckCircle)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: 50%;
  border: 0.1rem solid ${({ theme }) => theme.palette.common.white};

  transition: all 0.3s ease-in;
`

export const GameCardDescriptionStyles = styled.div`
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  word-break: break-all;
  box-orient: vertical;
  -webkit-box-orient: vertical;

  span {
    hyphens: manual;
    word-break: keep-all;
    display: block;
    height: 3.2rem;
  }
`
