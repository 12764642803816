import React, { useCallback } from 'react'
import { IconButton } from '@mui/material'
import { Edit, CancelSharp } from '@mui/icons-material'

import { ReactComponent as FeideLogo } from 'assets/svg/logo_feide.svg'
import { useGroupDialogContext } from '../../context'
import { GroupDialogHeaderStyles } from './GroupDialogHeader.styles'
import { CustomInputComponent } from './components'

export const GroupDialogHeaderComponent: React.FC = () => {
  const {
    handleClose,
    isCreation,
    setToCreateUserGroup,
    setToUpdateUserGroup,
    updatedUserGroup,
    createdUserGroup,
    isCustomGroup,
    isFeideGroup,
    isEdition
  } = useGroupDialogContext()

  const handleUpdateUserGroupName = (newName: string) => {
    if (isCreation) {
      setToCreateUserGroup((cur) => ({
        ...cur,
        name: newName
      }))
    }

    if (isEdition) {
      setToUpdateUserGroup((cur) => ({
        ...cur,
        name: newName
      }))
    }
  }

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      handleUpdateUserGroupName(e.currentTarget.value)
    },
    []
  )

  return (
    <GroupDialogHeaderStyles>
      <CustomInputComponent
        name='groupName'
        defaultValue={updatedUserGroup.name || createdUserGroup.name}
        autoFocus={isCustomGroup}
        autoSelect={isCustomGroup}
        disabled={isFeideGroup}
        icon={isCustomGroup ? <Edit color='inherit' /> : <FeideLogo />}
        onChange={handleInputChange}
        autoComplete='off'
      />
      <IconButton sx={{ padding: 0 }} onClick={handleClose} color='primary'>
        <CancelSharp color='primary' fontSize='large' />
      </IconButton>
    </GroupDialogHeaderStyles>
  )
}
