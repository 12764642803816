import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import { ROUTES } from 'router'

const OfflineContent = lazy(() => import('views/offlineContent/OfflineContent'))

export const renderOfflineContentRoutes = () => {
  const { OFFLINE_CONTENT } = ROUTES

  return (
    <Route path={OFFLINE_CONTENT}>
      <Route index element={<OfflineContent />} />
    </Route>
  )
}
