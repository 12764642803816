import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'common/components'
import { ActionGroupPropTypes } from './ActionGroup.types'

import { RowStyles } from './ActionGroup.styles'

export const ActionGroupComponent: React.FC<ActionGroupPropTypes> = ({
  footer,
  onConfirm,
  onClose
}) => {
  const { t } = useTranslation()
  return (
    <RowStyles>
      {footer.buttons ? (
        footer.buttons.map((props, index) => (
          <Button key={`button-${index.toString()}`} {...props} />
        ))
      ) : (
        <>
          <Button
            variant={onConfirm ? 'outlined' : 'contained'}
            color='primary'
            value={
              onConfirm
                ? t('general.actions.cancel')
                : t('general.actions.confirm')
            }
            onClick={onClose}
          />
          {onConfirm && (
            <Button
              variant='contained'
              color='primary'
              value={t('general.actions.confirm')}
              onClick={onConfirm}
            />
          )}
        </>
      )}
    </RowStyles>
  )
}
