import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { useAuth } from 'context'
import { ROUTES, BackdropComponent } from 'router'

import { ProtectedRoutePropTypes } from './ProtectedRoute.types'
import { AccessDeniedComponent } from './components/accessDenied/AccessDenied'

export const ProtectedRoute: React.FC<ProtectedRoutePropTypes> = ({
  roles,
  children,
  redirectPath = `/${ROUTES.AUTH}/${ROUTES.LOGIN}`
}) => {
  const location = useLocation()
  const { user, hasCookie } = useAuth()

  if (hasCookie === undefined) return <BackdropComponent open />

  if (hasCookie === false) {
    return <Navigate to={redirectPath} state={{ from: location }} replace />
  }

  const containsRoles = roles.some((role) => user?.roles.includes(role))

  const userHasRequiredRole = !!(user && containsRoles)

  if (user && !userHasRequiredRole) {
    return <AccessDeniedComponent redirectPath={redirectPath} />
  }

  return children as React.ReactElement
}

export * from './ProtectedRoute.types'
export * from './components'
