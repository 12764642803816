import { api, ApiResponseTypes } from '../../api'
import { TeacherBalanceTypes } from './Teachers.types'

const getTeacherBalance = async () => {
  const response = await api.get<ApiResponseTypes<TeacherBalanceTypes>>(
    `/teachers/me/balance`
  )

  return response.data.data
}

export default {
  get: getTeacherBalance
}
