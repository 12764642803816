import { useActionState } from 'hooks'
import React, { useRef } from 'react'
import { SwitchStyles } from './Switch.styles'
import { SwitchPropTypes } from './Switch.types'

export const SwitchComponent: React.FC<SwitchPropTypes> = ({
  error,
  success,
  focused,
  hovered,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const { hasError, hasFocus, hasHover, hasSuccess } = useActionState(
    { error, focused, success, hovered },
    inputRef
  )

  return (
    <SwitchStyles
      {...rest}
      inputRef={inputRef}
      error={hasError}
      success={hasSuccess}
      focused={hasFocus}
      hovered={hasHover}
      inputProps={{ tabIndex: 0 }}
    />
  )
}
