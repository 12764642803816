import { ApiResponseTypes } from 'services/api/api.types'
import { api, PaginatedReportsResponseTypes } from '../../api'
import {
  GameLogByAssignmentResumeTypes,
  GameLogByStudentResumeTypes
} from './GameLog.types'

const gameLogByAssignmentResume = async ({
  assignmentId
}: {
  assignmentId: string
}) => {
  return api
    .get<PaginatedReportsResponseTypes<GameLogByAssignmentResumeTypes>>(
      `game-logs/report/assignment/${assignmentId}`
    )
    .then((response) => response.data)
}

const gameLogByStudentResume = async ({ studentId }: { studentId: string }) => {
  return api
    .get<ApiResponseTypes<GameLogByStudentResumeTypes>>(
      `/game-logs/report/students/${studentId}/resume`
    )
    .then((response) => response.data)
}

export default {
  gameLogByAssignmentResume,
  gameLogByStudentResume
}
