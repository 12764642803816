/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'
import { Box, Typography } from '@mui/material'
import { langTags } from 'i18n'

import { MdReaderPropTypes } from './MdReader.types'

export const MdReaderComponent: React.FC<MdReaderPropTypes> = (props) => {
  const {
    information: { mdText },
    children
  } = props

  return (
    <>
      <ReactMarkdown
        components={{
          b: ({ node: _, ...props }) => (
            <Typography
              fontWeight={(theme) => theme.typography.fontWeightBold}
              lang={langTags.bokmal}
              {...props}
            />
          ),
          p: ({ node: _, ...props }) => (
            <Typography variant='body1' lang={langTags.bokmal} {...props} />
          ),
          blockquote: ({ node: _, ...props }) => (
            <Box
              component='blockquote'
              border={({ palette }) => `0.1rem solid ${palette.divider}`}
              borderRadius={1}
              display='flex'
              flexDirection='column'
              p={2}
              gap={2}
              bgcolor='white'
              {...props}
            />
          ),
          section: ({ node: _, ...props }) => (
            <Box
              component='section'
              display='flex'
              flexDirection='column'
              gap={2}
              {...props}
            />
          )
        }}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
      >
        {`<section>${mdText}</section>`}
      </ReactMarkdown>
      {children}
    </>
  )
}
