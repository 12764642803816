import React from 'react'
import { Box, Typography } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { getStringInitials } from 'utils'

import { Button } from 'common/components'
import { AvatarComponent } from 'components'

import { StudentItemStyles } from './StudentItem.styles'
import { StudentItemPropTypes } from './StudentItem.types'

export const StudentItemComponent: React.FC<StudentItemPropTypes> = ({
  student,
  isAdded,
  onAddClick,
  hideActionButtons = false,
  ...rest
}) => {
  const { t } = useTranslation()
  const firstName = student.name.split(' ')[0]
  const secondName = getStringInitials(student.name).secondLetter
  const splicedName = `${firstName} ${secondName}.`

  return (
    <StudentItemStyles {...rest}>
      <Box display='flex' gap={1} alignItems='center'>
        <AvatarComponent name={student.name} />
        <Box display='flex' flexDirection='column' gap='0.5rem'>
          <Typography variant='body2' noWrap>
            {splicedName}
          </Typography>
          {isAdded && (
            <Typography variant='body2' color='grey.400'>
              {t('general.state.studentAdded')}
            </Typography>
          )}
        </Box>
      </Box>
      {!hideActionButtons &&
        (isAdded ? (
          <CheckCircle color='success' />
        ) : (
          <Button
            size='small'
            color='primary'
            variant='text'
            value={t('general.actions.add')}
            onClick={onAddClick}
          />
        ))}
    </StudentItemStyles>
  )
}

export * from './StudentItem.types'
