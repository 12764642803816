import { AssignmentsOfReportServices } from './assignments'
import { StudentsOfReportServices } from './students'

export const ReportsServices = {
  assignments: AssignmentsOfReportServices,
  students: StudentsOfReportServices
}

export * from './assignments'
export * from './students'
export * from './Reports.types'
