import { atom } from 'recoil'
import { GameLogsListsTypes } from './types'

export const gameLogsLists = atom<GameLogsListsTypes>({
  key: 'gameLogs',
  default: {
    gameLogList: [],
    gameLogResume: {
      avgScore: 0,
      completedLevels: 0,
      playedLevels: 0,
      progress: 0,
      totalTimeSpent: '00:00:00'
    }
  }
})
