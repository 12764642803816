import { AxiosError } from 'axios'
import { useFeedback } from 'context'
import { ApiResponseTypes, GameServices } from 'services'
import { useQuery } from 'react-query'

export const useGameByIdQuery = (gameId: number) => {
  const { alert } = useFeedback()

  const gameByIdQuery = useQuery({
    queryKey: ['/games', gameId],
    queryFn: () => GameServices.id.get(gameId),
    enabled: !!gameId,
    onError: (error: AxiosError<ApiResponseTypes<unknown>>) => {
      alert.showError(error.response?.data.message || error.message)
    }
  })

  return gameByIdQuery
}
