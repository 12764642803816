import React from 'react'
import { css, CssBaseline } from '@mui/material'
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles'
import { Colors } from '../tools/colors'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    headerTitle: React.CSSProperties
    headerSubtitle: React.CSSProperties
    inputLabel: React.CSSProperties
    institutionDescriptionTitle: React.CSSProperties
    institutionDescription: React.CSSProperties
    sectionTitle: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    headerTitle?: React.CSSProperties
    headerSubtitle?: React.CSSProperties
    inputLabel?: React.CSSProperties
    institutionDescriptionTitle?: React.CSSProperties
    institutionDescription?: React.CSSProperties
    sectionTitle?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    headerTitle: true
    headerSubtitle: true
    inputLabel: true
    institutionDescriptionTitle: true
    institutionDescription: true
    sectionTitle: true
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    light: true
  }
}

export const MuiTheme: ThemeOptions = {
  palette: {
    primary: {
      main: Colors.primary,
      light: Colors.primaryLight,
      dark: Colors.primaryDark
    },
    secondary: {
      main: Colors.secondary,
      light: Colors.secondaryLight,
      dark: Colors.secondaryDark
    },
    info: {
      main: Colors.info,
      light: Colors.infoLight,
      dark: Colors.infoDark
    },
    success: {
      main: Colors.success,
      light: Colors.successLight,
      dark: Colors.successDark
    },
    warning: {
      main: Colors.warning,
      light: Colors.warningLight,
      dark: Colors.warningDark
    },
    error: {
      main: Colors.danger,
      light: Colors.dangerLight,
      dark: Colors.dangerDark
    }
  },
  typography: {
    fontFamily: ['Muli', 'Rajdhani', 'Venera', 'sans-serif'].join(','),
    htmlFontSize: 10
  },
  components: {
    MuiPaper: {
      defaultProps: {
        style: {
          boxShadow: `0 0.6rem 2rem ${Colors.black}15`
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          PaperProps: {
            style: {
              boxShadow: `0 0.6rem 2rem ${Colors.black}15`,
              marginTop: 1
            }
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          color: Colors.dark,
          boxShadow: 'none',
          textTransform: 'none',
          paddingLeft: '2.4rem',
          paddingRight: '2.4rem',
          position: 'relative',
          ':hover': css`
            box-shadow: none;
          `
        }
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: { color: Colors.dark }
        },
        {
          props: {
            variant: 'outlined',
            color: 'primary'
          },
          style: {
            color: Colors.secondary,
            ':hover': css`
              color: ${Colors.white};
              background-color: ${Colors.secondary};
            `,
            ':active': css`
              color: ${Colors.white};
              background-color: ${Colors.secondary};
            `
          }
        },
        {
          props: {
            variant: 'outlined',
            color: 'secondary'
          },
          style: {
            color: Colors.greyishBlue,
            ':hover': css`
              color: ${Colors.white};
              background-color: ${Colors.secondaryLight};
            `,
            ':active': css`
              color: ${Colors.white};
              background-color: ${Colors.secondaryDark};
            `
          }
        },
        {
          props: { variant: 'text' },
          style: {
            ':hover': css`
              color: ${Colors.white};
            `
          }
        },
        {
          props: { variant: 'text', color: 'info' },
          style: {
            color: Colors.info
          }
        },
        {
          props: { variant: 'text', color: 'error' },
          style: {
            color: Colors.danger
          }
        },
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            color: Colors.white
          }
        },
        {
          props: { variant: 'contained', color: 'info' },
          style: {
            color: Colors.white
          }
        }
      ]
    }
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1200,
      xl: 1440
    }
  }
}

export const MuiThemeWrapper: React.FC = ({ children }) => {
  const theme = createTheme(MuiTheme)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}
