import { AxiosError } from 'axios'
import { useMutation } from 'react-query'
import { useRecoilState } from 'recoil'

import { skillsList, topicsList, planetsList } from 'atoms'
import { useFeedback } from 'context'
import {
  ApiResponseTypes,
  PlanetServices,
  SkillsServices,
  TopicServices
} from 'services'

import { UseQueryOptionsTypes } from '../queries.types'

export const useContentQuery = (
  _props: UseQueryOptionsTypes | undefined = {}
) => {
  const [, setSkills] = useRecoilState(skillsList)
  const [, setTopics] = useRecoilState(topicsList)
  const [, setPlanets] = useRecoilState(planetsList)

  const { alert } = useFeedback()

  const planetMutation = useMutation(['/planets'], () => PlanetServices.get(), {
    onSuccess: (data) => {
      setPlanets(data)
    },
    onError(error: AxiosError<ApiResponseTypes<unknown>>) {
      alert.showError(error.response?.data.message || error.message)
    }
  })

  const topicMutation = useMutation(['/topics'], () => TopicServices.get(), {
    onSuccess: (data) => {
      setTopics(data)
    },
    onError(error: AxiosError<ApiResponseTypes<unknown>>) {
      alert.showError(error.response?.data.message || error.message)
    }
  })

  const skillsMutation = useMutation(['/skills'], () => SkillsServices.get(), {
    onSuccess: (data) => {
      setSkills(data)
    },
    onError(error: AxiosError<ApiResponseTypes<unknown>>) {
      alert.showError(error.response?.data.message || error.message)
    }
  })

  return {
    planetMutation,
    topicMutation,
    skillsMutation
  }
}
