import { makeQueryParams } from 'utils/makeQueryParams'
import {
  AssignmentTypes,
  GameModeFromFromAssignment,
  PaginatedReportsResponseTypes,
  ReportSearchPropTypes,
  StudentTypes,
  api
} from 'services'

const allGameModes = async ({
  studentId,
  assignmentId,
  params: { page = 1, ...rest }
}: {
  studentId: StudentTypes['id']
  assignmentId: AssignmentTypes['id']
} & ReportSearchPropTypes) => {
  const response = await api.get<
    PaginatedReportsResponseTypes<GameModeFromFromAssignment>
  >(
    `v2/reports/students/${studentId}/assignments/${assignmentId}/gameModes${makeQueryParams(
      {
        selectedPage: page,
        ...rest
      }
    )}`
  )

  return response.data
}

export default allGameModes
