import { LinearProgress } from '@mui/material'
import styled, { css } from 'styled-components'

export const ProgressWrapperStyles = styled.div<{ fullWidth?: boolean }>`
  width: 100%;
  height: 100%;
  max-width: 11rem;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      max-width: 100%;
    `}

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const ProgressStyles = styled(LinearProgress)`
  height: 50%;
  min-height: 2.5rem;
  width: 100%;
  border-radius: 2.5rem;

  border: 0.1rem solid ${({ theme }) => theme.palette.secondary[200]};

  background-color: ${({ theme }) => theme.palette.secondary[50]};

  .MuiLinearProgress-bar {
    border-radius: 2.5rem;
    background-color: ${({ theme }) => theme.palette.secondary[200]};

    ${({ value }) =>
      value === 100 &&
      css`
        border: 0.1rem solid ${({ theme }) => theme.palette.secondary.main};
      `}
  }
`

export const ProgressLabelStyles = styled.div`
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`
