import { DialogActionsProps } from '@mui/material'
import React from 'react'
import { DialogFooterStyles } from './DialogFooter.styles'

export const DialogFooter: React.FC<DialogActionsProps> = ({
  children,
  ...rest
}) => {
  return <DialogFooterStyles {...rest}>{children}</DialogFooterStyles>
}
