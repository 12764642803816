import { api, ApiResponseTypes } from 'services'
import {
  TeacherCountTypes,
  TeachersCountBySchoolParamTypes
} from './[id].types'

const teachersCountBySchool = async ({
  schoolId
}: TeachersCountBySchoolParamTypes) => {
  const response = await api.get<ApiResponseTypes<TeacherCountTypes>>(
    `/licenseManagement/schools/${schoolId}/teachersCount`
  )

  return response.data.data
}

export default {
  get: { teachersCountBySchool }
}
