import React from 'react'
import { Typography, Box, Stack } from '@mui/material'

import { Button } from 'common/components'

import { EmptyContainerStyles, EmptyContentStyles } from './EmptyContent.styles'
import { EmptyContentPropTypes } from './EmptyContent.types'

export const EmptyContentComponent: React.FC<EmptyContentPropTypes> = ({
  Icon,
  title,
  description,
  button
}) => {
  return (
    <EmptyContainerStyles>
      <EmptyContentStyles>
        {Boolean(Icon) && Icon}
        <Stack component={Box} spacing={1}>
          <Typography
            variant='subtitle1'
            fontWeight={(theme) => theme.typography.fontWeightBold}
          >
            {title}
          </Typography>
          <Typography variant='body1'>{description}</Typography>
        </Stack>
        {button && <Button {...button} />}
      </EmptyContentStyles>
    </EmptyContainerStyles>
  )
}
