import { ComponentsLocaleTypes } from '../locales.types'

const componentsLocaleNo: ComponentsLocaleTypes = {
  layout: {
    skipLink: {
      overall: 'Hopp til {{ content }}',
      header: 'overskrift',
      aside: 'menysider',
      main: 'innhold'
    }
  },
  search: {
    placeholder: {
      default: 'Søk...',
      assignments: 'Søk etter oppgavenavn​...',
      students: 'Søk etter studenter...',
      class: 'Søk etter klasse...',
      games: 'Søk etter spill...'
    },
    noOptions: {
      default: 'Ingen resultat'
    },
    suggestions: {
      title: 'Forslag'
    },
    loading: {
      default: 'Laster...'
    }
  },
  button: {
    template: {
      addNewGroup: {
        text: 'Legg til ny gruppe'
      }
    },
    text: {
      viewResults: 'Vis resultat',
      report: 'Sjå rapport',
      edit: 'Rediger',
      delete: 'Slett',
      newAssignment: 'Ny oppgåve',
      createNewGroup: 'Lag ei ny gruppe',
      selectLanguage: 'Vel språk',
      createNewAssignment: 'Lag ei ny oppgåve',
      reports: 'Rapportar',
      moreInfo: 'Meir info',
      readMore: 'Les meir',
      offlineContent: 'Oppdragsark',
      buyLicenses: 'Kjøp lisensar',
      assignLicenses: 'Tildel lisensar',
      manage: 'Administrer',
      addCustomer: 'Legg til kunde',
      addLicenses: 'Legg til lisenser',
      addInvoice: 'Legg til lisens',
      successfullyAdded: 'Vallykket lagt til'
    }
  },
  groupListItem: {
    students: '{{ studentCount }} elevar'
  },
  gameCard: {
    more: 'Meir...',
    topics: 'Tema:',
    skills: 'Ferdigheiter:',
    tags: 'Tags:',
    boards: '{{ boardsCount }} oppgaver',
    chooseGameModes: 'Vel spelmodus:',
    chooseModes: 'Velg moduser',
    planet: 'Planet: {{ planetName }}',
    needsSchoolLicense: 'Krever Numetry Skole lisens'
  },
  gamesMode: {
    modeOne: 'Modus I',
    modeTwo: 'Modus II',
    modeThree: 'Modus III',
    modeFour: 'Modus IV',
    challengeModeOne: 'Utfordring I',
    challengeModeTwo: 'Utfordring II'
  },
  modals: {
    confirmation: {
      assignment: {
        create: {
          title: 'Oppgåve oppretta',
          subtitle: '<strong>{{ name }}</strong> er lagt til oppgavelisten din'
        },
        update: {
          title: 'Endringar lagret',
          subtitle:
            '<strong>{{ name }}</strong> har blitt oppdatert med dine endringar.'
        }
      }
    },
    error: {
      assignment: {
        create: {
          title: 'Det har oppstått ein uventa feil',
          subtitle:
            'Oi, noko gjekk gale. Prøv på nytt, eller kontakt oss, så skal vi hjelpe deg.'
        },
        update: {
          title: 'Det har oppstått ein uventa feil',
          subtitle:
            'Oi, noko gjekk gale. Prøv på nytt, eller kontakt oss, så skal vi hjelpe deg.'
        }
      }
    },
    gameDetails: {
      topics: 'Tema:',
      skills: 'Ferdigheiter:',
      gameModes: 'Modusar:',
      tags: 'Tags:',
      levelInfo: 'Oversyn',
      preClassroom: 'Fagleg bakgrunn',
      afterPlaying: 'Relaterte ressursar',
      whatTheKidsShouldDoNow: 'Ressursar',
      relatedGames: 'Sjå også',
      tasks: '{{ nrOfTasks }} Oppgåver',
      curriculumObjectives: 'Læreplanmål',
      funFact: 'Fun Fact',
      missionDetails: 'Oppdragsdetaljer',
      selectModes: 'Velg modus',
      planet: 'Planet: {{ planetName }}',
      modesSubtitle:
        'Nedenfor finner du en oversikt over det faglige innholdet i de ulike modusene. For å tildele oppdrag til elevene dine, trykk på “Lag en oppgave” i hovedmenyen.',
      readTheGaneHelpArticle: 'Les hjelpeartikkelen for {{ gameName }}'
    },
    assignTo: {
      title: 'Legg til elevar',
      subtitle: 'Vel kva elevar eller grupper som skal motta denne oppgåva.',
      wholeClass: 'Heile klassa',
      students: 'Elever',
      groups: 'Grupper'
    },
    editGroup: {
      title: 'Rediger gruppe',
      groupLabel: 'Gruppenavn',
      enterGroupName: 'Skriv inn et gruppenavn',
      showingNStudents: 'Viser {{ count }} elever',
      showingNStudents_zero: 'Ingen elev funnet',
      studentsInThisGroup: 'Elever i denne gruppen',
      addedToThisGroup: 'lagt til i denne gruppen',
      studentCount_one: '<strong>{{ count }} elev</strong>',
      studentCount_other: '<strong>{{ count }} elevar</strong>',
      findStudentsToAdd: 'Finn elevar å leggje til',
      groupSelectPlaceholder: 'Vel gruppe',
      errorMessage: {
        pleasePutGroupName: 'Vennligst skriv inn et gruppenavn'
      }
    },
    createGroup: {
      title: 'Lag gruppe',
      groupLabel: 'Gruppenavn',
      table: {
        name: 'Studenter lagt til i gruppen',
        userGroups: 'Feide klasse'
      },
      input: {
        placeholder: 'Skriv inn et gruppenavn'
      },
      filters: {
        select: 'Velg etter gruppe',
        search: 'Søk etter student'
      },
      button: {
        confirm: 'Lag ny gruppe'
      }
    }
  },
  slider: {
    from: 'Frå',
    to: 'Til'
  },
  userTableRow: {
    results: '{{ name }} sine resultat'
  },
  table: {
    pagination: {
      previous: 'Forrige',
      next: 'Neste'
    },
    notFoundImageAlt: 'Jente forvirret',
    notFoundTip: 'Prøv å søke etter en annen {{ entity }}tittel',
    noResultsFound: 'Ingen resultater',
    noResultsFoundDescription:
      'Ingen resultater ble funnet for denne tabellen, prøv å opprette et nytt element eller oppdater siden',
    noRowsWithoutSearch: 'Ingen {{ entity }} funnet',
    noRowsWithSearch: 'Vi fann ingen {{ entity }}  med namnet «{{ search }}»'
  },
  picker: {
    from: 'Fra',
    to: 'Til'
  },
  pdfs: {
    symbols: {
      equals: {
        symbol: '=',
        name: 'er lik'
      },
      multiplication: {
        symbol: '·',
        name: 'multiplikasjon'
      },
      division: {
        symbol: ':',
        name: 'inndeling'
      },
      fraction: {
        symbol: '/',
        name: 'brøkdel'
      },
      addition: {
        symbol: '+',
        name: 'addisjon'
      },
      subtraction: {
        symbol: '-',
        name: 'subtraksjon'
      }
    },
    worksheet: {
      name: 'Navn',
      class: 'Klasse',
      answerSheet: {
        title: 'Fasit'
      }
    }
  }
}

export default componentsLocaleNo
