import { Breadcrumbs, Skeleton, Typography } from '@mui/material'
import React from 'react'
import { LayoutHeaderStyles } from './components/layoutHeader/LayoutHeader.styles'

import { LayoutStyles } from './Layout.styles'

export const LayoutSkeleton: React.FC<{
  hideBreadcrumbs: boolean
}> = ({ children, hideBreadcrumbs = false }) => {
  return (
    <LayoutStyles>
      <LayoutHeaderStyles>
        {!hideBreadcrumbs && (
          <Skeleton sx={{ maxWidth: '10rem' }}>
            <Breadcrumbs>
              <Typography>Breadcrumb</Typography>
            </Breadcrumbs>
          </Skeleton>
        )}

        <Skeleton width='100%' sx={{ maxWidth: '51rem' }}>
          <Typography
            variant='h1'
            fontWeight={(theme) => theme.typography.fontWeightBold}
          >
            Loading title
          </Typography>
        </Skeleton>
        <Skeleton sx={{ maxWidth: '47rem' }}>
          <Typography
            variant='subtitle1'
            fontWeight={(theme) => theme.typography.fontWeightBold}
          >
            Loading subtitle
          </Typography>
        </Skeleton>
      </LayoutHeaderStyles>
      {children}
    </LayoutStyles>
  )
}

export * from './Layout.types'
