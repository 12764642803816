import React from 'react'
import { TipsAndUpdatesOutlined } from '@mui/icons-material'
import { Alert, SvgIcon } from '@mui/material'
import { InfoSectionPropTypes } from './InfoSection.types'

export const InfoSectionComponent: React.FC<InfoSectionPropTypes> = ({
  description
}) => {
  return (
    <Alert
      variant='standard'
      severity='info'
      icon={
        <SvgIcon
          component={TipsAndUpdatesOutlined}
          sx={{ fontSize: 36 }}
          color='secondary'
        />
      }
      sx={{
        borderRadius: '0.5rem',
        alignItems: 'center',
        py: 3,
        px: 2,
        backgroundColor: ({ palette }) => palette.secondary[50],
        color: ({ palette }) => palette.text.primary
      }}
    >
      {description}
    </Alert>
  )
}
