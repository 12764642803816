import React, { useEffect, useRef } from 'react'

import { CustomInputStyles } from './CustomInput.styles'
import { CustomInputPropTypes } from './CustomInput.types'

export const CustomInputComponent: React.FC<CustomInputPropTypes> = ({
  icon,
  autoFocus,
  autoSelect,
  disabled,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleSelectText = () => inputRef.current?.select()
  const handleFocusInput = () => inputRef.current?.focus()

  useEffect(() => {
    if (autoFocus) handleFocusInput()
    if (autoSelect) handleSelectText()
  }, [inputRef.current])

  return (
    <CustomInputStyles onClick={handleFocusInput} disabled={disabled}>
      <input
        {...rest}
        ref={inputRef}
        autoFocus={autoFocus}
        onFocus={handleSelectText}
        disabled={disabled}
      />
      {icon}
    </CustomInputStyles>
  )
}
