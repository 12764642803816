import customerMethods from './Customers'
import customerIdMethods from './Customers.id'
import paginated from './Customers.paginated'

export const CustomerServices = {
  ...customerMethods,
  id: {
    ...customerIdMethods
  },
  paginated
}

export * from './Customers.types'
