import React from 'react'
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon
} from '@mui/material'
import { Logout, Menu } from '@mui/icons-material'

import { ReactComponent as NumetryLogo } from 'assets/svg/numetry-logo.svg'
import { useDisclosure } from 'hooks'
import { ROUTES } from 'router'

import { LeftNavigationPropTypes } from './LeftNavigation.types'
import { LanguageSelectorComponent } from '../languageSelector/LanguageSelector'
import { LeftNavigationItem } from './components'

export const LeftNavigationComponent: React.FC<LeftNavigationPropTypes> = ({
  items,
  isHeaderOverflow
}) => {
  const { onToggle, isOpen, onClose } = useDisclosure()

  const MAIN_ITEMS = items.filter(
    (item) =>
      item.position === 'default' ||
      (!item.position && !item.hideElement && !item.hideInLeftNavigation)
  )
  const BOTTOM_ITEMS = items.filter(
    (item) =>
      item.position === 'bottom' &&
      !item.hideElement &&
      !item.hideInLeftNavigation
  )

  const renderMenuButton = () => (
    <IconButton onClick={onToggle}>
      <Menu />
    </IconButton>
  )

  return (
    <>
      {renderMenuButton()}
      <Drawer
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: 250
          }
        }}
      >
        <Box
          role='presentation'
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          height='100%'
          paddingX={2}
        >
          <div>
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={onToggle}>
                  <ListItemIcon>
                    <Menu />
                  </ListItemIcon>
                  <NumetryLogo width={80} height='auto' />
                </ListItemButton>
              </ListItem>
            </List>
            <Divider />
            <List>
              {MAIN_ITEMS.map((item) => (
                <LeftNavigationItem
                  item={item}
                  key={item.id}
                  onClose={onClose}
                />
              ))}
            </List>
          </div>
          <div>
            <List disablePadding>
              {BOTTOM_ITEMS.map((item) => (
                <LeftNavigationItem
                  item={item}
                  key={item.id}
                  onClose={onClose}
                />
              ))}
            </List>
            {isHeaderOverflow && (
              <List>
                <Divider />
                <List disablePadding>
                  <LanguageSelectorComponent
                    sx={{
                      justifyContent: 'flex-start',
                      width: '100%'
                    }}
                  />
                </List>
                <Divider />
                <List>
                  <LeftNavigationItem
                    item={{
                      to: `/${ROUTES.AUTH}/${ROUTES.LOGOUT}`,
                      icon: Logout,
                      title: {
                        main: 'auth.logout'
                      },
                      id: items.length + 1,
                      name: 'logout',
                      position: 'bottom'
                    }}
                    onClose={onClose}
                  />
                </List>
              </List>
            )}
          </div>
        </Box>
      </Drawer>
    </>
  )
}
