import styled, { css } from 'styled-components'
import { Box } from '@mui/material'
import { Colors, FontSizes, FontWeights } from 'styles'

import { HeaderProps } from './PopUpModal.types'

export const PopUpContainerStyles = styled(Box)`
  display: block;
  box-shadow: 0 -0.4rem 2rem ${Colors.black}29;
`
export const PopUpHeaderStyles = styled(Box)<HeaderProps>`
  padding: 1.8rem;

  background: ${Colors.lighterBlue};
  color: ${Colors.dark};
  border-bottom: 0.2rem solid ${Colors.lightBlue};

  p {
    font-weight: ${FontWeights.bold};
    font-size: ${FontSizes.normalPlus};
  }
`
export const PopUpContentStyles = styled(Box)<{ disablePadding?: boolean }>`
  ${({ disablePadding }) =>
    !disablePadding &&
    css`
      padding: 2.8rem;
      padding-bottom: 0;
    `}
  background: ${Colors.white};
`
export const PopUpFooterStyles = styled(Box)<{ disablePadding?: boolean }>`
  background: ${Colors.white};
  display: flex;
  min-width: 100%;
  ${({ disablePadding }) =>
    !disablePadding &&
    css`
      padding: 2.8rem;
      padding-top: 0;
    `}
`
