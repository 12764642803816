import { createSearchParams } from 'react-router-dom'

export const makeQueryParams = <T extends object = { [k: string]: string }>(
  params: T
) => {
  const listOfParams = Object.entries(params)

  const filteredList = listOfParams.filter(
    ([_key, value]) => value !== undefined && value !== null
  )

  const objectParams = Object.fromEntries(filteredList)
  const query = createSearchParams(objectParams).toString()

  return query ? `?${query}` : ''
}
