import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  CheckOutlined
} from '@mui/icons-material'
import { Menu, MenuItem, Box } from '@mui/material'
import { Button } from 'common/components'
import React, { useState } from 'react'
import {
  IconButtonStyles,
  OrderByContainerStyles,
  TypographyStyles
} from './OrderBy.styles'
import { OrderByPropTypes } from './OrderBy.types'

export const OrderByComponent: React.FC<OrderByPropTypes> = (props) => {
  const {
    initialOrderType = 'ASC',
    options = [],
    onOptionChange = () => null,
    onOrderChange = () => null
  } = props

  const [orderType, setOrderType] =
    useState<OrderByPropTypes['initialOrderType']>(initialOrderType)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [selectedIndex, setSelectedIndex] = React.useState(0)

  const handleMenuItemClick = (
    _event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setSelectedIndex(index)
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <OrderByContainerStyles>
      <Button
        color='grey'
        aria-haspopup='true'
        size='small'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickListItem}
        value={options[selectedIndex].label}
        style={{ padding: 0 }}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
      >
        {options.map(({ label, value }, index) => (
          <MenuItem
            sx={{ padding: '0.6rem' }}
            key={value}
            selected={index === selectedIndex}
            onClick={(event) => {
              handleMenuItemClick(event, index)
              onOptionChange({ label, value })
            }}
          >
            <TypographyStyles>
              <Box>{index === selectedIndex && <CheckOutlined />}</Box>
              {label}
            </TypographyStyles>
          </MenuItem>
        ))}
      </Menu>
      <IconButtonStyles
        onClick={() =>
          setOrderType((current) => {
            const newOrder = current === 'ASC' ? 'DESC' : 'ASC'
            onOrderChange(newOrder)
            return newOrder
          })
        }
      >
        {orderType === 'ASC' ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </IconButtonStyles>
    </OrderByContainerStyles>
  )
}

export * from './OrderBy.types'
