import { useTheme } from '@mui/material'
import React from 'react'

import { getStringInitials } from 'utils'

import { AvatarStyles } from './Avatar.styles'
import { AvatarPropTypes } from './Avatar.types'

export const AvatarComponent: React.FC<AvatarPropTypes> = ({
  name,
  ...rest
}) => {
  const { palette } = useTheme()
  const { firstLetter, secondLetter } = getStringInitials(name)
  return (
    <AvatarStyles
      sx={{ background: palette.secondary.A700, color: palette.secondary.dark }}
      alt={`${name.replace(/ /g, '_')}`}
      {...rest}
    >
      {firstLetter + secondLetter}
    </AvatarStyles>
  )
}

export * from './Avatar.types'
