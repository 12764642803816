import i18next, { t } from 'i18next'
import { randomInRange } from 'utils'
import { GeneratorInputTypes } from '../../../views/pdfGenerator/subViews/generate/Generate.types'
import {
  OPERATIONS,
  OPERATION_KEY,
  OperationTypes,
  SYMBOL,
  SYMBOL_KEY
} from '../data'
import { ElementType, GeneratorOutputTypes } from './calc.types'
import {
  createDimensionalArray,
  shouldExecuteRandomly,
  swapArrayElements
} from './utils'

const DEFAULT_NUMBER_OF_WORKSHEETS = 1

const getOperationByKey = (operationKey: OPERATION_KEY) => {
  const indexOfOperation = OPERATIONS.findIndex(
    (operation) => operation.key === operationKey
  )
  return OPERATIONS[indexOfOperation]
}

const getListOfOperationsByKeys = (operationKeys: OPERATION_KEY[]) => {
  return OPERATIONS.filter((operation) => operationKeys.includes(operation.key))
}

const getElements = (
  operation: OperationTypes,
  factors: GeneratorInputTypes['factors']
): ElementType[] => {
  const { n1, n2, result } = operation.getOperationValues(factors)

  return [
    { value: n1, type: 'n1', shouldBeMissed: false },
    {
      value: operation.symbol.getSymbol(),
      type: 'operation',
      shouldBeMissed: false
    },
    { value: n2, type: 'n2', shouldBeMissed: false },
    {
      value: SYMBOL[SYMBOL_KEY.EQUALS].getSymbol(),
      type: 'equals',
      shouldBeMissed: false
    },
    { value: result, type: 'result', shouldBeMissed: false }
  ]
}

const getExerciseWithShuffledEqualsSign = (exercise: ElementType[]) => {
  const newExercise = [...exercise]

  // Take and remove 2 last elements from array:
  const newArrayWithResultAndEqualsElements = newExercise.splice(
    newExercise.length - 2,
    2
  )

  const equalsIndex = newArrayWithResultAndEqualsElements.findIndex(
    (element) => element.type === 'equals'
  )

  const resultIndex = newArrayWithResultAndEqualsElements.findIndex(
    (element) => element.type === 'result'
  )

  const equals = newArrayWithResultAndEqualsElements[equalsIndex]
  const result = newArrayWithResultAndEqualsElements[resultIndex]

  // At position 0, add 2 elements (equals and result):
  newExercise.splice(0, 0, result, equals)

  return newExercise
}

const getExerciseWithSwappedOrderOfFactors = (exercise: ElementType[]) => {
  const n1Index = exercise.findIndex((element) => element.type === 'n1')
  const n2Index = exercise.findIndex((element) => element.type === 'n2')

  return swapArrayElements(exercise, n1Index, n2Index)
}

const getExerciseWithMissingFactor = (
  exercise: ElementType[],
  missingFactors: ElementType['type'][]
) => {
  const newExercise = [...exercise]

  const necessaryElements = newExercise.filter((necessaryElement) =>
    missingFactors.includes(necessaryElement.type)
  )

  const whichFactorShouldBeMissed = randomInRange(
    0,
    necessaryElements.length - 1
  )

  const exerciseIndex = newExercise.findIndex(
    (element) =>
      element.type === necessaryElements[whichFactorShouldBeMissed].type
  )

  const missedFactor: ElementType = {
    ...newExercise[exerciseIndex],
    shouldBeMissed: true
  }

  const newMissedFactorArray = newExercise.filter(
    (element) => element.type !== missedFactor.type
  )

  newMissedFactorArray.splice(exerciseIndex, 0, missedFactor)

  return newMissedFactorArray
}

const getExerciseWithOptions = (
  operation: OperationTypes,
  options: GeneratorInputTypes
) => {
  let exercise = getElements(operation, options.factors)

  if (shouldExecuteRandomly(options.shuffleEqualsSign)) {
    exercise = getExerciseWithShuffledEqualsSign(exercise)
  }

  if (
    options.randomizeOrderOfFactors &&
    shouldExecuteRandomly(options.randomizeOrderOfFactors)
  ) {
    exercise = getExerciseWithSwappedOrderOfFactors(exercise)
  }

  if (options.randomizeMissingFactorProduct) {
    exercise = getExerciseWithMissingFactor(exercise, ['n1', 'n2', 'result'])
  } else {
    exercise = getExerciseWithMissingFactor(exercise, ['result'])
  }

  return exercise
}

const getNumberOfPagesPerTask = () => 1

const getActivities = (
  operationKeyOrKeys: OPERATION_KEY | OPERATION_KEY[],
  options: GeneratorInputTypes
): ElementType[][][][] => {
  const numberOfWorkSheets =
    options.numberOfWorkSheets && options.generateDifferentExercises
      ? options.numberOfWorkSheets
      : DEFAULT_NUMBER_OF_WORKSHEETS

  const dimensions = [numberOfWorkSheets, getNumberOfPagesPerTask()]

  const getPageExerciseList = () =>
    Array.from({ length: options.tasksPerSheet }, (_, i) => {
      let operations
      let operation

      if (Array.isArray(operationKeyOrKeys)) {
        operations = getListOfOperationsByKeys(operationKeyOrKeys)
        operation = operations[i % operations.length]
      } else {
        operation = getOperationByKey(operationKeyOrKeys)
      }
      return getExerciseWithOptions(operation, options)
    })

  return createDimensionalArray(dimensions, getPageExerciseList)
}

const getDocumentTitle = (
  operationKeyOrKeys: OPERATION_KEY | OPERATION_KEY[]
) => {
  const operations = Array.isArray(operationKeyOrKeys)
    ? operationKeyOrKeys
    : [operationKeyOrKeys]

  const operationNames = operations.flatMap((operationKey) => {
    return getOperationByKey(operationKey).getName()
  })

  if (!operationNames.length) {
    throw new Error('OperationNames cannot return and array without elements')
  }

  if (operationNames.length === 1) return operationNames[0]

  const lastOperation = operationNames.pop()
  const otherOperations = operationNames.join(', ')

  const isNorwegianLang = i18next.language === 'nb' || i18next.language === 'nn'

  const stringOfOperations = t('general.somethingAndAnotherThing', {
    something: otherOperations,
    anotherThing: isNorwegianLang ? lastOperation?.toLowerCase() : lastOperation
  })

  return stringOfOperations
}

export const getGeneratedCalculation = (
  operationKeyOrKeys: OPERATION_KEY | OPERATION_KEY[],
  options: GeneratorInputTypes
): GeneratorOutputTypes => {
  const activities = getActivities(operationKeyOrKeys, options)

  const answers = options.generateAnswerSheet ? activities : []

  const title = getDocumentTitle(operationKeyOrKeys)

  return {
    document: {
      page: {
        size: 'A4',
        orientation: 'portrait',
        answers,
        activities
      },
      title
    },
    options
  }
}

export const getGeneratedPreview = (
  operationKeyOrKeys: OPERATION_KEY | OPERATION_KEY[],
  options: GeneratorInputTypes
) => {
  return getGeneratedCalculation(operationKeyOrKeys, {
    ...options,
    numberOfWorkSheets: DEFAULT_NUMBER_OF_WORKSHEETS
  })
}

export * from './calc.types'
