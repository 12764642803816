import styled, { css } from 'styled-components'
import { Stack } from '@mui/material'
import { from } from 'common/theme'
import { FiltersBarDesktopPropTypes } from './FiltersBarDesktop.types'

export const FiltersBarDesktopStyles = styled.div<{ isStickedOnTop: boolean }>`
  width: 100%;
  ${({ isStickedOnTop }) =>
    isStickedOnTop &&
    css`
      position: sticky;
      top: 0;
      z-index: 5;
    `}
`

export const FiltersBarDesktopWrapperStyles = styled.div<FiltersBarDesktopPropTypes>`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  flex-direction: ${({ flexDirection }) => flexDirection};

  > div {
    ${({ variant }) =>
      variant === 'row-reverse' &&
      css`
        justify-content: flex-end;
      `}
  }
  ${({ borderBottom, theme }) =>
    borderBottom && `border-bottom: 0.1rem solid ${theme.palette.grey[100]};`}
`

export const FiltersBarDesktopLeftStyles = styled(Stack)`
  flex-direction: row;
  align-items: center;
  width: 100%;
  display: flex;
  overflow-x: auto;
  min-width: max-content;
`

export const FiltersBarDesktopRightStyles = styled(Stack)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  ${from.md} {
    width: 100%;
    max-width: 36rem;
  }
`
