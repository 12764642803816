import styled from 'styled-components'
import { Paper, Popper } from '@mui/material'

import { Colors, FontSizes, FontWeights } from 'styles'

export const PopperStyles = styled(Popper)`
  z-index: ${({ theme }) => theme.zIndex.tooltip};
  position: relative;
  max-width: 30rem;
`
export const PopperContainerStyles = styled(Paper)`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  margin: 0 1.2rem;
  padding: 1.2rem;

  background-color: ${Colors.white};

  box-shadow: -0.5rem 0 2rem ${Colors.black}29;
  border-radius: 0.5rem;
`

export const PopperHeaderStyles = styled.div`
  display: flex;

  p {
    color: ${Colors.info};
    font-size: ${FontSizes.normal};
    font-weight: ${FontWeights.bold};
  }

  svg {
    margin-right: 1rem;
    color: ${Colors.info};
  }
`

export const PopperBodyStyles = styled.div`
  p {
    text-transform: capitalize;
  }
`

export const PopperFooterStyles = styled.div`
  background-color: ${Colors.ghostWhite};
  border-radius: 0.5rem;
  padding: 1rem;
`
