import React from 'react'
import { Typography } from '@mui/material'
import { ModalComponent, PopUpPropTypes } from 'components'
import {
  PopUpContainerStyles,
  PopUpHeaderStyles,
  PopUpContentStyles,
  PopUpFooterStyles
} from './PopUpModal.styles'
import { ResponseModalTemplate } from './templates'

import { ActionGroupComponent } from './components'

export const resolveTemplate = (data: PopUpPropTypes) => {
  if (!data.template) return <Typography>{data.content}</Typography>

  if (data.template === 'response') return <ResponseModalTemplate {...data} />
  return <div />
}

interface PopUpHeaderProps {
  title: string
}

const PopUpHeader: React.FC<PopUpHeaderProps> = ({ title }) => {
  const parts = title.split(/(<strong>.*?<\/strong>)/)
  return (
    <>
      {parts.map((part) => {
        if (part.startsWith('<strong>')) {
          return (
            <Typography
              key={part}
              variant='h6'
              component='span'
              fontWeight='bold'
              style={{ fontSize: '1.667rem', color: 'black' }}
            >
              {part.replace('<strong>', '').replace('</strong>', '')}
            </Typography>
          )
        }
        return (
          <React.Fragment key={part}>
            <Typography
              variant='h6'
              component='span'
              fontWeight='bold'
              style={{ fontSize: '1.8rem' }}
            >
              {part}
            </Typography>
          </React.Fragment>
        )
      })}
    </>
  )
}

export const PopUpComponent: React.FC<PopUpPropTypes> = ({
  children,
  ...props
}) => {
  const {
    title,
    onClose,
    onConfirm,
    open,
    variant = 'light',
    footer = {
      visible: true,
      align: 'flex-end'
    },
    disablePadding = false,
    zIndexPriority
  } = props

  return (
    <ModalComponent
      scroll={props.scroll}
      open={open}
      onClose={onClose}
      zIndexPriority={zIndexPriority}
    >
      <PopUpContainerStyles>
        {variant !== 'noHeader' && (
          <PopUpHeaderStyles variant={variant}>
            <PopUpHeader title={title} />
          </PopUpHeaderStyles>
        )}
        {children && (
          <PopUpContentStyles disablePadding={disablePadding}>
            {children}
          </PopUpContentStyles>
        )}
        {footer.visible && (
          <PopUpFooterStyles
            disablePadding={disablePadding}
            justifyContent={footer.align}
          >
            {footer.content ? (
              footer.content(
                <ActionGroupComponent
                  onConfirm={onConfirm}
                  onClose={onClose}
                  footer={footer}
                />
              )
            ) : (
              <ActionGroupComponent
                onConfirm={onConfirm}
                onClose={onClose}
                footer={footer}
              />
            )}
          </PopUpFooterStyles>
        )}
      </PopUpContainerStyles>
    </ModalComponent>
  )
}

export * from './PopUpModal.types'
export * from './templates'
