import { ApiResponseTypes } from 'services/api/api.types'
import { api } from '../../api'
import { UserGroupTypes } from './UserGroups.types'

const putToChangeState = async (id: string, enabled: boolean) => {
  return api.put<ApiResponseTypes<UserGroupTypes>>(
    `/user-groups/${id}/changeState?state=${enabled}`
  )
}

export default {
  put: putToChangeState
}
