import { CustomerOverviewContextWrapper } from 'context'
import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import { ContextRoute, ROUTES } from 'router'

const CustomerOverview = lazy(
  () => import('views/customerOverview/CustomerOverview')
)

export const renderCustomerOverviewRoutes = () => {
  const { SALES_TEAM } = ROUTES

  return (
    <Route
      path={SALES_TEAM}
      element={<ContextRoute wrapper={CustomerOverviewContextWrapper} />}
    >
      <Route index element={<CustomerOverview />} />
    </Route>
  )
}
