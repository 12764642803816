import React from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { Dialog } from 'common/components'
import { GroupContextWrapper } from 'context'
import { GroupDialogPropTypes } from './GroupDialog.types'
import { GroupDialogContextWrapper } from './context'
import {
  GroupDialogFooterComponent,
  GroupDialogHeaderComponent
} from './components'
import { GroupDialogContentComponent } from './components/content/GroupDialogContent'

export const GroupDialogComponent: React.FC<GroupDialogPropTypes> = (props) => {
  const { open = true, children: _, group, onClose, hideBackdrop } = props

  const isMobileScreen = useMediaQuery(useTheme().breakpoints.down('md'))

  const isFeideGroup = Boolean(group?.feideId)

  return (
    <GroupDialogContextWrapper {...props}>
      <Dialog
        maxWidth={isFeideGroup ? 'md' : 'lg'}
        fullWidth
        height='lg'
        open={open}
        onClose={onClose}
        fullScreen={isMobileScreen}
        hideBackdrop={hideBackdrop}
      >
        <GroupDialogHeaderComponent />
        <GroupDialogContentComponent />
        <GroupContextWrapper>
          <GroupDialogFooterComponent group={group} />
        </GroupContextWrapper>
      </Dialog>
    </GroupDialogContextWrapper>
  )
}
