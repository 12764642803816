import styled from 'styled-components'
import { Popover, Box } from '@mui/material'
import { FontSizes, FontWeights, Colors } from 'styles'

export const PopoverStyles = styled(Popover)`
  display: flex;
  flex-direction: column;
  pointer-events: none;
  .MuiPaper-root {
    transform: translate(2rem);
  }
`

export const PopoverDescriptionStyles = styled(Box)`
  padding: 0 0.8rem;
  margin-bottom: 0.5rem;
  width: 21.5rem;

  p {
    color: ${Colors.dark};
    font-size: 1rem;
    word-wrap: break-word;
  }
`

export const PopoverHeaderStyles = styled(Box)`
  width: 21.5rem;
  color: ${Colors.info};
  padding: 0.8rem;

  svg {
    font-size: ${FontSizes.small};
  }
  p {
    font-size: ${FontSizes.smaller};
    font-weight: ${FontWeights.bold};
    margin-left: 0.5rem;
  }

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`
