import i18n from 'i18next'
import languageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { translationEn } from './locales/en'
import { translationNn } from './locales/nn'
import { translationNb } from './locales/nb'

const resources = {
  en: translationEn,
  nn: translationNn,
  nb: translationNb
}

declare module 'react-i18next' {
  interface CustomTypeOptions {
    defaultNS: 'nb'
    resources: typeof resources
  }
}

i18n.on('languageChanged', (language) => {
  document.documentElement.setAttribute('lang', language)
})

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem('i18nextLng') || 'nb',
    fallbackLng: 'nb',
    interpolation: {
      escapeValue: false
    },
    react: {
      transSupportBasicHtmlNodes: true
    },
    resources: {
      en: { translation: translationEn },
      nn: { translation: translationNn },
      nb: { translation: translationNb }
    }
  })

export { i18n }
