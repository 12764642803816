import React from 'react'
import { View, Text } from '@react-pdf/renderer'

import { item } from './Item.styles'
import { PDFItemPropTypes } from './Item.types'
import { VerticalItem } from './components'

export const PDFItemComponent: React.FC<PDFItemPropTypes> = ({
  exercise,
  index,
  options,
  isAnswerSheet
}) => {
  const styleNumberOfItemsPerSheet = {
    6: () => item.sixItems,
    25: () => item.twentyFiveItems,
    50: () => item.fiftyItems
  }

  return (
    <View
      style={[
        item.default,
        styleNumberOfItemsPerSheet[options.tasksPerSheet]()
      ]}
    >
      <View style={item.pagination}>
        <Text style={item.paginationText}>{index + 1}</Text>
      </View>
      <View style={item.exercise}>
        <View style={item.exerciseItem}>
          {options?.verticalOperation ? (
            <VerticalItem
              exercise={exercise}
              options={options}
              hideResult={!isAnswerSheet}
            />
          ) : (
            exercise.map(({ value, shouldBeMissed }) => {
              if (!isAnswerSheet && shouldBeMissed) {
                return <View style={item.nullElement} />
              }

              if (shouldBeMissed) {
                return <Text style={item.answerElement}>{value}</Text>
              }

              return <Text style={item.exerciseText}>{value}</Text>
            })
          )}
        </View>
      </View>
    </View>
  )
}
