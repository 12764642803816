import { PaginatedResponse } from 'services'

export const getNextPageParam = <T = unknown>(
  lastPage: PaginatedResponse<T>,
  allPages: PaginatedResponse<T>[]
) => {
  const { dataCount, pageNumber } = lastPage.pagination

  if (dataCount === 0) return false

  const totalDataCount = allPages
    .flatMap((page) => page.data.length)
    .reduce((accumulator, currentLength) => accumulator + currentLength, 0)

  if (totalDataCount >= dataCount) return false

  const nextPageNumber = pageNumber + 1
  return nextPageNumber
}
