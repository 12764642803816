import { api, ApiResponseTypes } from '../../api'
import { FeedbackTypes } from './Feedbacks.types'

const getFeedback = async (id: number) => {
  return api
    .get<ApiResponseTypes<FeedbackTypes>>(`/feedbacks/${id}`)
    .then((response) => response.data.data)
}

export default {
  get: getFeedback
}
