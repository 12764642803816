import { OPERATION_KEY } from '../operation/operator.types'

export enum SYMBOL_KEY {
  EQUALS = 'equals'
}

export type SymbolKeyTypes = SYMBOL_KEY | OPERATION_KEY

export type SymbolTypes = {
  key: SymbolKeyTypes
  getSymbol: () => string
  getName: () => string
}
