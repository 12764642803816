import { makeQueryParams } from 'utils/makeQueryParams'
import { api } from '../../api'
import { ApiPaginatedParamTypes, PaginatedResponse } from '../../api.types'
import { OrganizationTypes } from './Organizations.types'

const paginated = async ({
  params: {
    page = 1,
    pageSize = 20,
    search = null,
    withProgress,
    withCustomGroups
  }
}: ApiPaginatedParamTypes) => {
  const response = await api.get<PaginatedResponse<OrganizationTypes>>(
    `/organizations${makeQueryParams({
      selectedPage: page,
      pageSize,
      search,
      withProgress,
      withCustomGroups
    })}`
  )

  return response.data
}

export default {
  paginated
}
