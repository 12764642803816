import styled from 'styled-components'

export const EditableContentStyles = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  padding: 1rem;
  margin: -1rem;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  :focus {
    text-overflow: initial;
    overflow: overlay hidden;
  }
`

export const EditableIconContainerStyles = styled.div`
  position: sticky;
  right: 0;
  padding: 0 1rem;
  transform: translateX(0.8rem);

  ::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    width: 100%;
    height: calc(100% + 0.5rem);
    transform: translate(-50%, -50%);
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`
