import React from 'react'
import { AddRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { useDisclosure } from 'hooks'
import { GroupDialogComponent } from 'components'
import { Button } from 'common/components'

import { AddGroupButtonPropTypes } from './AddGroupButton.types'
import { AddGroupButtonWithModalStyles } from './AddGroupButton.styles'

export const AddGroupButtonWithModalComponent: React.FC<
  AddGroupButtonPropTypes
> = () => {
  const { t } = useTranslation()
  const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen: false })

  return (
    <AddGroupButtonWithModalStyles>
      {isOpen && <GroupDialogComponent open onClose={onClose} hideBackdrop />}
      <Button
        color='primary'
        startIcon={<AddRounded />}
        value={t('components.button.template.addNewGroup.text')}
        onClick={onOpen}
      />
    </AddGroupButtonWithModalStyles>
  )
}

export * from './AddGroupButton.types'
