import { SvgIcon, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { BottomNavigationItemPropTypes } from './BottomNavigation.types'
import { BottomNavigationItemStyles } from './BottomNavigationItem.styles'

export const BottomNavigationItemComponent: React.FC<
  BottomNavigationItemPropTypes
> = ({ item }) => {
  const { t } = useTranslation()
  const { icon, title, filledIcon } = item

  return (
    <NavLink to={item.to || '#'}>
      {({ isActive }) => (
        <BottomNavigationItemStyles isActive={item.to ? isActive : false}>
          <SvgIcon component={filledIcon && isActive ? filledIcon : icon} />
          <Typography fontWeight={(theme) => theme.typography.fontWeightBold}>
            {t(title.mobile || title.main)}
          </Typography>
        </BottomNavigationItemStyles>
      )}
    </NavLink>
  )
}
