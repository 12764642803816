import { SearchContainerTypes, SearchPropTypes } from 'components'
import styled, { css } from 'styled-components'
import { Colors, inputState } from 'styles'

import { to } from 'common/theme'

export const SearchStyles = styled.div<SearchContainerTypes>`
  width: 100%;
  max-height: 3.4rem;
  max-width: 36rem;

  display: flex;
  align-items: center;

  background: ${Colors.white};
  border: 0.1rem solid ${({ theme }) => theme.palette.grey[100]};
  border-radius: 0.4rem;

  transition: all 0.2s;

  .MuiIconButton-root {
    color: ${Colors.dark};
  }

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      max-width: 100%;
    `}

  ${({ error }) =>
    error &&
    css`
      ${inputState.error(error)}
      .MuiIconButton-root {
        color: ${Colors.danger};
      }
    `}

  ${({ success }) =>
    success &&
    css`
      ${inputState.success(success)}
      .MuiIconButton-root {
        color: ${Colors.success};
      }
    `}

  ${({ focused }) => inputState.focused(focused)}
  ${({ hovered }) => inputState.hovered(hovered)}
  ${({ focused }) => inputState.focused(focused)}


  ${to.lg} {
    width: 100%;
    min-width: 5rem;
  }
`

export const SearchInputStyles = styled.input<SearchPropTypes>`
  border: none;
  background-color: transparent;
  padding-left: 1rem;
  width: inherit;
  height: inherit;
  transition: all 0.2s;
  height: 100%;

  ::placeholder {
    color: ${Colors.blue};
    transition: all 0.2s;

    ::first-letter {
      text-transform: uppercase;
    }
  }

  ${({ hovered }) =>
    hovered &&
    css`
      ::placeholder {
        color: ${Colors.grey};
      }
    `}
`
