import { from } from 'common/theme'
import styled from 'styled-components'

export const LayoutStyles = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;

  min-height: 100vh;
`

export const LayoutContentStyles = styled.div`
  position: relative;
  padding-top: 8.1rem;

  display: flex;
  flex-direction: column;
  flex: 1;

  ${from.md} {
    margin-bottom: 0;
  }
`

export const LayoutMainStyles = styled.main<{ isAsideHidden?: boolean }>`
  display: flex;
  flex: 1;

  width: 100%;

  ${from.md} {
    ${({ isAsideHidden }) => !isAsideHidden && `padding-left: 6.7rem;`}
  }
`
