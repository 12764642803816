import React, { createContext, useContext, useMemo } from 'react'

import { useInfiniteCustomersQuery } from 'hooks'
import { CustomerOverviewContextTypes } from './CustomerOverview.context.types'

export const CustomerOverviewContext =
  createContext<CustomerOverviewContextTypes>(
    {} as CustomerOverviewContextTypes
  )

export const useCustomerOverview = () => useContext(CustomerOverviewContext)

export const CustomerOverviewContextWrapper: React.FC = ({ children }) => {
  const infiniteCustomersQuery = useInfiniteCustomersQuery()

  const value = useMemo(
    () => ({
      infiniteCustomersQuery
    }),
    [infiniteCustomersQuery]
  )

  return (
    <CustomerOverviewContext.Provider value={value}>
      {children}
    </CustomerOverviewContext.Provider>
  )
}
