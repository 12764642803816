import React from 'react'
import { Breadcrumbs, Link, Typography, Skeleton } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { BreadCrumbPropTypes } from './BreadCrumb.types'

export const BreadCrumbComponent: React.FC<BreadCrumbPropTypes> = (props) => {
  const { breadcrumbs, loading, ...breadcrumbsProps } = props

  const lastPathNameIndex = breadcrumbs.length - 1
  const hasOnlyOneChild = breadcrumbs.length === 1

  const renderCrumb = (path: string, label: string) => {
    if (hasOnlyOneChild) return null

    return (
      <Link
        key={`breadcrumb-${path}-${label}`}
        color={({ palette }) => palette.secondary.main}
        component={RouterLink}
        fontSize={({ typography }) => typography.subtitle1.fontSize}
        underline='hover'
        to={path}
      >
        <Typography variant='subtitle1' color='inherit'>
          {label}
        </Typography>
      </Link>
    )
  }

  const renderLabel = (label: string) => {
    return (
      <Typography
        key={`item-${lastPathNameIndex.toString()}`}
        variant='subtitle1'
      >
        {label}
      </Typography>
    )
  }

  if (loading) {
    return (
      <Skeleton width='100%' sx={{ maxWidth: '25rem' }}>
        <Breadcrumbs>{renderLabel('Loading')}</Breadcrumbs>
      </Skeleton>
    )
  }

  return (
    <Breadcrumbs
      aria-label='breadcrumb'
      separator='&rsaquo;'
      {...breadcrumbsProps}
    >
      {breadcrumbs.map(({ label, path }, index) => {
        if (index === lastPathNameIndex) return renderLabel(label)
        return renderCrumb(path, label)
      })}
    </Breadcrumbs>
  )
}

export * from './BreadCrumb.types'
