import { Stack, Typography } from '@mui/material'
import { LazyImageComponent } from 'components'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Button } from '../button/Button'
import { Dialog } from '../dialog/Dialog'
import { FooterStyles, ImageContainerStyles, PopUpStyles } from './PopUp.styles'
import { PopUpPropTypes } from './PopUp.types'

export const PopUp: React.FC<Partial<PopUpPropTypes>> = (props) => {
  const { t } = useTranslation()
  const { information, open = true, onClose = () => null, children } = props
  return (
    <Dialog {...props} open={open} height='min' scroll='body'>
      <PopUpStyles>
        <Stack direction='row' justifyContent='space-between'>
          <Trans>
            <Typography
              fontWeight={(theme) => theme.typography.fontWeightBold}
              variant='h3'
            >
              {information?.title}
            </Typography>
          </Trans>
        </Stack>
        {information?.image && (
          <ImageContainerStyles>
            <LazyImageComponent
              src={information.image}
              alt={information.title}
            />
          </ImageContainerStyles>
        )}
        <Trans>
          <Typography variant='subtitle1' textAlign='center'>
            {information?.description}
          </Typography>
        </Trans>
        <FooterStyles>
          {children ?? (
            <Button
              variant='contained'
              onClick={() => onClose({}, 'backdropClick')}
            >
              {t('general.state.ok')}
            </Button>
          )}
        </FooterStyles>
      </PopUpStyles>
    </Dialog>
  )
}
