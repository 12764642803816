import { Colors } from '..'

export const genericReset = (): string => `
  /* Hide webpack error overlay */
  iframe#webpack-dev-server-client-overlay {
    display: none;
  }
  
  /* ===== Scrollbar CSS ===== */
  * {
    scrollbar-width: thin;
    scrollbar-color: ${Colors.lightGrey} ${Colors.white};
  }

  *::-webkit-scrollbar {
    width: 1.7rem;
  }


  *::-webkit-scrollbar-thumb {
    border-radius: 1.2rem;
    border: 0.4rem solid transparent;
    background-color: ${Colors.lightGrey};
    background-clip: content-box;
    transition: 0.3s;
  }
  
  * {
    &,
    &:before,
    &:after {
      box-sizing: border-box;
      outline: none;
      font-family: 'Muli', sans-serif;
      text-transform: none;
      font-size: 1.6rem;
    }
  }
  
  *:not(input, button, textarea, .MuiButtonBase-root, .MuiAccordion-root, .MuiSelect-select, .MuiPaper-elevation, [role="presentation"], [tabindex="-1"]):focus {
    outline: 0.2rem solid ${Colors.info} !important;
    outline-offset: -0.2rem;
    box-shadow: none;
  }

  body,
  h1, h2, h3, h4, h5, h6,
  blockquote, p, pre,
  dl, dd, ol, ul,
  figure,
  hr,
  fieldset, legend {
    margin: 0;
    padding: 0;
  }

  html, body, #root {
    font-family: 'Muli', sans-serif;
    font-size: 62.5%;
  }

  body {
    background-color:${Colors.greyBg};
  }

  li > {
    ol,
    ul {
      margin-bottom: 0;
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  fieldset {
    min-width: 0;
    border: 0;
  }

  button {
    cursor: pointer;
  }

  a, li {
    text-decoration: none;
  }

  a {
    color: inherit
  }

  img {
    width:100%;
    max-width:100%;
  }

  .MuiCalendarPicker-root {
    .MuiTypography-root {
      font-size: 1.4rem;
    }
  }

  button.MuiButtonBase-root.MuiPickersDay-root {
    font-size: 1.4rem;
  }
`
