import styled from 'styled-components'

export const BottomNavigationStyles = styled.div`
  position: sticky;
  bottom: 0;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.palette.common.white};

  padding: 0.6rem 0;

  z-index: ${({ theme }) => theme.zIndex.mobileStepper};
  box-shadow: 0 0.3rem 2rem ${({ theme }) => theme.palette.grey[50]};
`
