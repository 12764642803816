/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { Box, SvgIcon, Typography } from '@mui/material'
import {
  Button,
  DisplayHeaderComponent,
  TableComponent
} from 'common/components'
import { useInfiniteTeachersBySchoolQuery } from 'hooks'
import { Cancel, CheckCircle } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { TeacherHasLicenseTypes, TeacherTypes } from 'services'
import { createColumnHelper } from '@tanstack/react-table'
import { useLicense } from 'context'
import { MutateOptions } from 'react-query'
import { TableContainerStyles } from '../../../../License.styles'

export const TeachersView = () => {
  const { t } = useTranslation()
  const { schoolId } = useParams()

  const { teacherActions } = useLicense()
  const infiniteTeachersBySchoolQuery = useInfiniteTeachersBySchoolQuery({
    schoolId,
    useParams: true
  })

  const columnHelper = createColumnHelper<TeacherHasLicenseTypes>()

  const columns = [
    columnHelper.accessor('name', {
      header: () => (
        <DisplayHeaderComponent
          title={t('pages.license.teacherListTable.header.teacherName')}
        />
      ),
      cell: (params) => <Typography>{params.getValue()}</Typography>
    }),
    columnHelper.accessor('hasLicense', {
      minSize: 12,
      maxSize: 70,
      header: () => (
        <DisplayHeaderComponent
          title={t('pages.license.teacherListTable.header.accessActivated')}
        />
      ),
      cell: (params) => {
        const hasLicense = params.getValue()

        return (
          <Box display='flex' flexDirection='row' alignItems='center'>
            <SvgIcon
              {...(hasLicense
                ? { component: CheckCircle, color: 'success' }
                : { component: Cancel, color: 'error' })}
            />
            <Typography ml={1}>
              {t(hasLicense ? 'general.yes' : 'general.no')}
            </Typography>
          </Box>
        )
      }
    }),
    columnHelper.display({
      id: 'actions',
      header: () => '',
      cell: (params) => {
        const teacher = params.row.original

        const options: MutateOptions<
          TeacherTypes,
          unknown,
          TeacherTypes['id'] | TeacherTypes['id'][],
          unknown
        > = {
          onSuccess: () => infiniteTeachersBySchoolQuery.refetch()
        }

        const assignLicenseToTeacher = teacherActions.assignLicensesToTeacher()

        const revokeLicenseFromTeacher =
          teacherActions.revokeLicensesFromTeacher()

        return (
          <Box display='flex' width='100%' gap={2} justifyContent='flex-end'>
            <Button
              disabled={teacher.hasLicense}
              loading={assignLicenseToTeacher.isLoading}
              color='secondary'
              variant='contained'
              size='small'
              onClick={() => {
                assignLicenseToTeacher.mutate(teacher.id, options)
              }}
            >
              {t('pages.license.teacherListTable.activateAccess')}
            </Button>
            <Button
              disabled={!teacher.hasLicense}
              loading={revokeLicenseFromTeacher.isLoading}
              color='secondary'
              variant='text'
              size='small'
              onClick={() => {
                revokeLicenseFromTeacher.mutate(teacher.id, options)
              }}
            >
              {t('pages.license.teacherListTable.revokeAccess')}
            </Button>
          </Box>
        )
      }
    })
  ]
  return (
    <TableContainerStyles>
      <TableComponent
        isLoading={infiniteTeachersBySchoolQuery.isLoading}
        columns={columns}
        data={infiniteTeachersBySchoolQuery.data}
        footer={{
          totalDataCount: infiniteTeachersBySchoolQuery.dataCount
        }}
        infiniteScroll={{
          fetchNextPage: infiniteTeachersBySchoolQuery.fetchNextPage,
          hasNextPage: infiniteTeachersBySchoolQuery.hasNextPage,
          isFetchingNextPage: infiniteTeachersBySchoolQuery.isFetchingNextPage
        }}
      />
    </TableContainerStyles>
  )
}
