import studentsMethods from './Students'
import studentsIdMethods from './Students.id'
import userGroupsMethods from './Students.id.user-group'
import studentsPaginated from './Students.paginated'
import studentsAssignLicenses from './Students.assignLicenses'
import studentsRevokeLicenses from './Students.revokeLicenses'

export const StudentServices = {
  ...studentsMethods,
  id: {
    userGroups: {
      ...userGroupsMethods
    },
    ...studentsIdMethods
  },
  ...studentsPaginated,
  assignLicenses: studentsAssignLicenses,
  revokeLicenses: studentsRevokeLicenses
}

export * from './Students.types'
