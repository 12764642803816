import styled from 'styled-components'
import { inputState } from 'styles'
import { GeneralActionNumberInputTypes } from './NumberInput.types'

export const NumberInputStyles = styled.div`
  display: flex;
  flex-direction: column;
`

export const NumberInputContainerStyles = styled.div<GeneralActionNumberInputTypes>`
  display: flex;
  gap: 0.8rem;
  align-items: center;

  input {
    text-align: center;
    max-width: 10rem;
    border-radius: 0.4rem;
    border: 0.1rem solid ${({ theme }) => theme.palette.grey[200]};
    padding: 0.8rem 0;

    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    ${({ hovered }) => inputState.hovered(hovered)}
    ${({ success }) => inputState.success(success)}
    ${({ error }) => inputState.error(error)}
    ${({ focused }) => inputState.focused(focused)}
  }
`

export const NumberInputButtonContainerStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 0.1rem solid ${({ theme }) => theme.palette.grey[200]};
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: 0.4rem;
  height: -webkit-fill-available;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 0;
    font-size: 1.6rem;
  }
`
