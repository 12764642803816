import { ApiResponseTypes } from 'services/api/api.types'
import { api } from '../../api'
import { OrganizationTypes } from './Organizations.types'

const getOrganizationById = async (id: OrganizationTypes['id']) => {
  const { data: response } = await api.get<ApiResponseTypes<OrganizationTypes>>(
    `/organizations/${id}`
  )

  return response.data
}

export default {
  get: getOrganizationById
}
