import { Font, StyleSheet } from '@react-pdf/renderer'
import { palette } from 'common/theme'
import { PDFFontFamilies } from 'styles'

Font.register({
  family: PDFFontFamilies.rajdhani.regular.name,
  src: PDFFontFamilies.rajdhani.regular.source
})

Font.register({
  family: PDFFontFamilies.rajdhani.semiBold.name,
  src: PDFFontFamilies.rajdhani.semiBold.source
})

Font.register({
  family: PDFFontFamilies.rajdhani.bold.name,
  src: PDFFontFamilies.rajdhani.bold.source
})

export const page = StyleSheet.create({
  default: {
    height: '100%',
    width: '100%',
    backgroundColor: palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    padding: 20
  }
})

export const body = StyleSheet.create({
  default: {
    width: '100%',
    height: '683px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: palette.common.black,
    border: `0.4px solid ${palette.common.black}`
  }
})
