import { AxiosError } from 'axios'
import { useFeedback } from 'context'
import { useParamsSelector } from 'hooks'
import { useInfiniteQuery } from 'react-query'
import { ApiResponseTypes, LicenseServices } from 'services'
import { getNextPageParam } from 'utils/getNextParam'
import { UseQueryOptionsTypes } from '../../queries.types'

export const useInfiniteUserGroupsBySchoolQuery = (
  options: UseQueryOptionsTypes | undefined = {}
) => {
  const { useParams, enabled, schoolId } = options

  const params = useParamsSelector()

  const queryParams = useParams ? params.objParams : {}

  const { alert } = useFeedback()

  const { data, ...infiniteQuery } = useInfiniteQuery(
    ['/licenseManagement/schools/schoolId/user-groups', queryParams],
    (paginatedParams) =>
      LicenseServices.schools.id.userGroups.paginated({
        params: {
          schoolId,
          page: paginatedParams.pageParam,
          search: queryParams.search
        }
      }),
    {
      keepPreviousData: true,
      getNextPageParam,
      onError: (error: AxiosError<ApiResponseTypes<unknown>>) => {
        alert.showError(error.response?.data.message || error.message)
      },
      enabled
    }
  )

  return {
    data: data?.pages.flatMap((page) => page.data) || [],
    dataCount: data?.pages.flatMap((page) => page.pagination.dataCount)[0] || 0,
    ...infiniteQuery
  }
}
