import { ThemeOptions, createTheme } from '@mui/material'

export const customBreakpoints: ThemeOptions['breakpoints'] = {
  values: {
    xs: 0,
    sm: 768,
    md: 1025,
    lg: 1200,
    xl: 1440
  }
}

export const { breakpoints } = createTheme({ breakpoints: customBreakpoints })

export const from = {
  xs: breakpoints.up('xs'),
  sm: breakpoints.up('sm'),
  md: breakpoints.up('md'),
  lg: breakpoints.up('lg'),
  xl: breakpoints.up('xl')
}

export const to = {
  xs: breakpoints.down('xs'),
  sm: breakpoints.down('sm'),
  md: breakpoints.down('md'),
  lg: breakpoints.down('lg'),
  xl: breakpoints.down('xl')
}
