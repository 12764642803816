import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { Backdrop, ClickAwayListener } from '@mui/material'

import { useDevice } from 'hooks'
import { useLayout } from 'context'

import {
  AsideBottomContentStyles,
  AsideMainContentStyles,
  AsideStyles
} from './Aside.styles'
import { AsideItemComponent } from './components'

import { AsidePropTypes } from './Aside.types'

export const AsideComponent: React.FC<AsidePropTypes> = ({
  items,
  ...rest
}) => {
  const location = useLocation()
  const asideRef = useRef<HTMLDivElement | null>(null)
  const { asideMenuHandlers, feedbackModalHandlers } = useLayout()
  const { isDesktopDevice } = useDevice()

  const isCollapsed = !(
    asideMenuHandlers.isOpen || feedbackModalHandlers.isOpen
  )

  const MAIN_ITEMS = items.filter(
    (item) => item.position === 'default' || !item.position
  )
  const BOTTOM_ITEMS = items.filter((item) => item.position === 'bottom')

  useEffect(() => {
    asideMenuHandlers.onClose()
  }, [location])

  return (
    <>
      <Backdrop
        open={feedbackModalHandlers.isOpen || asideMenuHandlers.isOpen}
        sx={{
          zIndex: ({ zIndex }) => zIndex.fab
        }}
        onClick={asideMenuHandlers.onClose}
      />
      <ClickAwayListener onClickAway={asideMenuHandlers.onClose}>
        <AsideStyles
          ref={asideRef}
          onMouseEnter={() => {
            if (isDesktopDevice) asideMenuHandlers.onOpen()
          }}
          onMouseLeave={() => {
            if (isDesktopDevice) asideMenuHandlers.onClose()
          }}
          id='aside'
          {...rest}
        >
          <AsideMainContentStyles>
            {MAIN_ITEMS.map((item) => (
              <AsideItemComponent
                isCollapsed={isCollapsed}
                item={item}
                key={item.id}
              />
            ))}
          </AsideMainContentStyles>
          <AsideBottomContentStyles>
            {BOTTOM_ITEMS.map((item) => (
              <AsideItemComponent
                isCollapsed={isCollapsed}
                item={item}
                key={item.id}
              />
            ))}
          </AsideBottomContentStyles>
        </AsideStyles>
      </ClickAwayListener>
    </>
  )
}
