import {
  ApiPaginatedParamTypes,
  PaginatedResponse
} from 'services/api/api.types'
import { makeQueryParams } from 'utils/makeQueryParams'
import { licenseApi, OrganizationCustomer } from '../../license.api'

export const paginated = async ({
  params: { page = 1, pageSize = 20 }
}: ApiPaginatedParamTypes) => {
  const response = await licenseApi.get<
    PaginatedResponse<OrganizationCustomer>
  >(
    `/organization-licensing${makeQueryParams({
      selectedPage: page,
      pageSize
    })}`
  )

  return response.data
}

export default paginated
