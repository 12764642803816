import React, { createContext, useContext, useMemo, useState } from 'react'

import { useDisclosure } from 'hooks'

import { LayoutContextTypes } from './Layout.context.types'

export const LayoutContext = createContext({} as LayoutContextTypes)

export const useLayout = () => useContext(LayoutContext)

export const LayoutContextWrapper: React.FC = ({ children }) => {
  const [isPageLoading, setIsPageLoading] = useState(false)

  const asideMenuHandlers = useDisclosure()
  const feedbackModalHandlers = useDisclosure()

  const value = useMemo(
    () => ({
      isPageLoading,
      setIsPageLoading,
      asideMenuHandlers,
      feedbackModalHandlers
    }),
    [isPageLoading, asideMenuHandlers.isOpen, feedbackModalHandlers.isOpen]
  )

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  )
}

export * from './Layout.context.types'
