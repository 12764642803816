import { AxiosError } from 'axios'
import { useFeedback } from 'context'
import {
  ApiResponseTypes,
  CustomerServices,
  OrganizationCustomer
} from 'services'
import { useQuery } from 'react-query'

export const useCustomerByIdQuery = (
  customerId: OrganizationCustomer['id']
) => {
  const { alert } = useFeedback()

  const customerByIdQuery = useQuery({
    queryKey: ['/organization-licensing', customerId],
    queryFn: () => CustomerServices.id.get(customerId),
    enabled: !!customerId,
    onError: (error: AxiosError<ApiResponseTypes<unknown>>) => {
      alert.showError(error.response?.data.message || error.message)
    }
  })

  return customerByIdQuery
}
