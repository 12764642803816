import {
  api,
  ApiPaginatedParamTypes,
  PaginatedResponse,
  TeacherHasLicenseTypes
} from 'services'
import { makeQueryParams } from 'utils'

const allTeachersBySchool = async ({
  params: { page = 1, schoolId, ...restOfParams }
}: ApiPaginatedParamTypes) => {
  const response = await api.get<PaginatedResponse<TeacherHasLicenseTypes>>(
    `/licenseManagement/schools/${schoolId}/teachers?selectedPage=${page}${makeQueryParams(
      restOfParams
    )}`
  )

  return response.data
}

export default {
  paginated: allTeachersBySchool
}
