import styled from 'styled-components'
import { from } from 'common/theme'

export const GameResumeStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
`

export const ResumeBodyStyles = styled.div`
  padding: 2.4rem;
  padding-bottom: 0;
  gap: 1.6rem;
  display: grid;

  grid-template-rows: 1fr;
  grid-template-columns: max-content 1fr;

  .MuiTypography-subtitle1 {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    img {
      border-radius: 50%;
      width: 5rem;
      height: 5rem;
      ${from.sm} {
        display: none;
      }
    }
  }

  > img {
    display: none;
  }

  ${from.sm} {
    > img {
      object-fit: cover;
      display: initial;
      width: 20rem;
      height: 100%;
    }
  }

  ${from.md} {
    > img {
      height: 100%;
      width: 36.8rem;
    }
  }
`

export const ResumeFooterStyles = styled.div`
  padding: 0 2.4rem 1.4rem;
`
