import React from 'react'
import { useTranslation } from 'react-i18next'

import { LinkStyles } from './SkipLink.styles'
import { SkipLinkPropTypes } from './SkipLink.types'

export const SkipLinkComponent: React.FC<SkipLinkPropTypes> = () => {
  const { t } = useTranslation()
  const navigation = [
    { title: t('components.layout.skipLink.header'), href: '#header' },
    { title: t('components.layout.skipLink.main'), href: '#main' },
    { title: t('components.layout.skipLink.aside'), href: '#aside' }
  ]

  return (
    <div>
      {navigation.map(({ title, href }) => (
        <LinkStyles href={href} key={title}>
          {t('components.layout.skipLink.overall', {
            content: title
          })}
        </LinkStyles>
      ))}
    </div>
  )
}

export * from './SkipLink.types'
