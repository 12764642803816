export const randomInRange = (
  min: number,
  max: number,
  divisibleBy?: number
) => {
  const minCeil = Math.ceil(min)
  const maxFloor = Math.floor(max)

  const res = Math.floor(Math.random() * (maxFloor - minCeil + 1)) + minCeil

  if (divisibleBy) {
    return Math.round(res / divisibleBy) * divisibleBy
  }

  return res
}
