import { PagesLocaleTypes } from '../locales.types'

const pagesLocaleNn: PagesLocaleTypes = {
  login: {
    or: 'Eller',
    aGameBy: 'Eit spel av',
    form: {
      validation: {
        email: {
          required: 'E-post er påkrevd'
        },
        password: {
          required: 'Passord er påkrevd'
        }
      }
    }
  },
  assignment: {
    card: {
      startsAt: 'Starter: <strong>{{ date }}</strong>',
      endsAt: 'Slutter: <strong>{{ date }}</strong>'
    },
    emptyContent: {
      title: 'Ingen resultat å vise',
      description:
        'Prøv å tømme filtrene ved å klikke nedenfor eller vent til vi legger til flere spill',
      noStudents: 'ingen studente'
    },
    tabs: {
      list: 'Liste',
      calendar: 'Kalender'
    },
    dataList: {
      header: {
        title: 'Namn',
        status: 'Status',
        startDate: 'Startdato',
        endDate: 'Sluttdato',
        assignedTo: 'Tildelt',
        progress: 'Fremgang',
        actions: 'Val'
      },
      displayHeader: {
        scoreMeaning: 'Hva betyr poengsum?',
        statusColorMeaning: 'Status - fargebetydninger'
      },
      cell: {
        numberOfStudents_zero: 'Ingen elever',
        numberOfStudents_one: '{{ count }} elev',
        numberOfStudents_other: '{{ count }} elever',
        modals: {
          delete: {
            title: 'Slett oppgave',
            content:
              'Dette vil slette <strong>{{ assignmentName }}</strong> fra oppgavelisten din.<br/>Rapporten for <strong>{{ assignmentName }}</strong> vil også bli slettet.'
          }
        }
      }
    },
    form: {
      assignment: 'Oppgaven',
      topic: 'emne',
      student: 'elev',
      errors: {
        assignmentTitleIsRequired: 'Vennligst skriv inn et oppgavenavn',
        fieldRequired:
          'Du har  <strong>ikke</strong> valgt en {{ field }} for denne oppgaven. For å lagre denne oppgaven må du velge minst en {{ field }}.',
        noElementsSelected: 'Ingen {{ elements }} valgt',
        selectStudentOrGroups: 'Elever/grupper må legges til',
        assignToIsRequired:
          'Velg minst én elev eller en gruppe med elever for å lagre oppgaven.'
      }
    },
    games: {
      emptyContent: {
        title: 'Du har ikke lagt til noen oppdrag​',
        description:
          'Trykk på knappen i høgre hjørne for å leggje til eit oppdrag.​'
      }
    }
  },
  group: {
    header: {
      createAssignment: 'Lag oppdrag',
      groupName: 'Gruppenavn',
      hiddenGroups: 'Skjulte grupper',
      showingAllHiddenGroups: 'Viser alle <strong>skjulte grupper</strong>'
    },
    table: {
      header: {
        name: 'Gruppenavn',
        studentCount: 'Antall studenter',
        students: 'Studenter',
        hiddenGroups: 'Skjulte grupper'
      },
      emptyContent: {
        general: {
          title: 'Ingen skjulte grupper',
          subtitle: 'Hvis du skjuler en feide-grupper finner du den igjen her.'
        },
        landscape: {
          title: 'Finn elever du ønsker å legge til i gruppen.',
          subtitle: 'Dette gjør du fra listen til venstre.'
        },
        portrait: {
          title: 'Finn elever å ønsker å legge til i gruppen.',
          subtitle: 'Dette gjør du fra listen ovenfor.'
        }
      },
      content: {
        add: 'Legg til',
        more: '{{ count }} mer',
        students_zero: 'Ingen student',
        students_one: '{{ count }} elev',
        students_other: '{{ count }} elever',
        studentPlaceholderDraggingOver:
          'Dra og slepp her for å leggje til studentter',
        groupsPlaceholder: 'Slipp studenter her'
      }
    },
    emptyContent: {
      title: 'Du har ikke opprettet noen grupper enda',
      description:
        'Legg til elever i neste fane, eller gå til "Grupper" i hovedmenyen for å opprette en gruppe',
      mobileDescription: 'Gå til desktopversjonen for å opprette grupper',
      hiddenGroup: {
        title: 'Ingen skjulte grupper',
        description: 'Hvis du skjuler en feide-grupper finner du den igjen her.'
      }
    },
    modal: {
      table: {
        name: 'Navn',
        groups: 'Grupper'
      }
    },
    sideBar: {
      title: 'Studenter',
      description:
        'Du kan dra elevene hit til <strong>Grupper</strong>.<br/>Velg flere for å dra dem alle samtidig.',
      studentsAdded: 'Elevar lagt til',
      feedback: {
        success: 'Fantastisk, du har opprettet denne gruppen'
      },
      search: {
        header: {
          placeholder: 'Søk studenter...'
        },
        footer: {
          placeholder: 'Tast inn eit gruppenamn'
        }
      },
      button: {
        add: 'Legg til'
      },
      openStudents: 'Vie elever',
      closeStudents: 'Skjul elever'
    },
    warning: {
      userAddedToGroup: '{{ user }} lagt til i {{ group }}',
      deleteGroup: 'Gruppen vil bli slettet permanent.',
      showingGroup: 'Gruppa er nå synlig',
      hidingGroup: 'Gruppa har blitt skjult.'
    }
  },
  content: {
    addTopic: 'Legg til oppdrag',
    estimatedPlayTime: 'Estimert speletid',
    playTime: 'speletid',
    selectedItems: 'Oppgåva di',
    youHave: 'Du har valt',
    itemsAdded: 'Matteoppdrag',
    actions: {
      backToList: 'Attende',
      continue: 'Fortsett'
    },
    form: {
      assignment: 'Oppgavetittel',
      assignmentPlaceholder: 'Oppgåve #01',
      period: 'Dato',
      time: 'Tid',
      assignedTo: 'Tildelast',
      assignTo: 'Tildel',
      addMore: 'Legg til fleire elevar',
      addStudents: 'Legg til elevar',
      students_one: '<strong>{{ count }} elev</strong>',
      students_other: '<strong>{{ count }} elever</strong>',
      lock: 'Lås',
      disabledGames:
        'Du har lagt til <strong>{{ disabledGamesCount }} spill</strong> som ble deaktivert, vennligst fjern dem.',
      lockWarning:
        'Bruk lås-funksjonen dersom du ønskjer at elevane skal fullføre låste oppgåver før dei får tilgang til resten av spelet.'
    },
    details: {
      title: 'Oversyn',
      subtitle: 'Sjå detaljar for oppgåva nedanfor',
      topicsAdded: 'Mine valde oppdrag',
      addNewMission: 'Legg til eit oppdrag',
      missionsAdded: 'Mine valde oppdrag',
      assignBtnHint:
        'Legg til elevar, grupper og klassar som skal motta oppgåva ved å trykkje på knappen merkt «Legg til elevar» nedanfor.',
      noTopicsAdded: 'Ingen emner lagt til'
    }
  },
  reports: {
    actions: {
      seeStudents: 'Sjå elevar',
      seeDetails: 'Se detaljer'
    },
    descriptions: {
      overallResults: 'Totale resultater',
      listOfResultsFrom: 'Dette er listen over resultater fra {{ from }}',
      resultsFromEachMissionIn: 'Resultater fra hvert oppdrag i {{ in }}',
      resultsFor: 'Resultater for {{ for }}',
      studentDetails: 'Elevdetaljer',
      students: 'Elever',
      student: 'Elev',
      assignments: 'Oppgaver',
      assignment: 'Oppgave',
      missions: 'Spill',
      thisIsTheListOfStudentsFrom:
        'Dette er listen over elevresultater fra {{ from }}',
      thisIsTheListOfResultsFromIn:
        'Dette er listen over resultater fra {{ from }} i {{ in }}',
      noReportFound: 'Ingen rapporter funnet',
      tryToSelectAnotherReport: 'Prøv å velge en annen rapport',
      resultsForAllStudents: 'Resultater for alle studenter',
      thisIsTheListOfResultsFromGameMode:
        'Dette er listen over resultater fra {{ gameModeName }}'
    },
    table: {
      headers: {
        avgScore: {
          help: 'En høy poengsum (opptil 1000) betyr at eleven har hatt flere riktige svar på rad. En lav poengsum (nedtil 500) betyr at eleven har besvart feil før de svarte riktig.'
        }
      }
    },
    tabs: {
      assignments: {
        allAssignments: 'Alle oppgaver',
        showingAssignments: 'Viser alle oppgavene',
        showingAssignments_filtered:
          'Viser oppgaver i <strong>{{ in }}</strong>'
      },
      students: {
        showingStudents: 'Viser alle elever',
        allStudents: 'Alle elevar',
        showingStudents_filtered: 'Viser elever i <strong>{{ in }}</strong>'
      }
    }
  },
  report: {
    dataList: {
      titles: {
        resultsFromWholeAddition:
          'Viser resultat frå heile addisjonsøvingsgruppa',
        resultsFromAllStudents: 'Viser resultat frå alle elevar',
        assignmentNameAndGameModeName:
          '{{ assignmentName }} - {{ gameModeName }}'
      },
      help: {
        whatTheScoreMeans: `
        En høy poengsum (opptil 1000) betyr at eleven har hatt flere riktige svar på rad. En lav poengsum (minimum 500) betyr at eleven har besvart feil før de svarte riktig.
        `
      },
      header: {
        name: 'Namn',
        endDate: 'Sluttdato',
        startDate: 'Startdato',
        groups: 'Gruppe(r)',
        score: 'Poengsum',
        topics: `Emne`,
        tags: `Ferdighet`,
        avgScore: 'Poeng (gj.snitt)',
        timeSpent: 'Tid brukt',
        totalTimeSpent: 'Total tid',
        totalFailedAnswers: 'Tal på feil svar',
        playedLevels: 'Modusar spelt',
        completedLevels: 'Fullførte modusar',
        assignment: 'Oppgåve',
        totalScore: 'Poengsum',
        avgTimeSpent: 'Tid brukt (gj.snitt)',
        failedAnswers: 'Feil svar',
        avgFailedAnswers: 'Feil svar (gj.snitt)',
        progress: 'Fremgang',
        game: 'Spel',
        missionAndModes: 'Oppdrag og moduser',
        student: 'Elever',
        popover: {
          title: 'Kva tyder {{ field }}?',
          description:
            'Poengsummen vert regna ut basert på tal av rette svar og tid brukt. 1000 poeng tyder at ein elev har fått alle svara rett og gjennomført på god tid. Lågare poengsum vurderast ut frå tid brukt og/eller tal av feil svar.'
        }
      }
    },
    menu: {
      autoCompletePlaceholder: 'Søk etter elevar',
      buttons: {
        print: 'Skriv ut',
        assignment: {
          default: 'Alle oppgåver',
          mobileTitle: 'Vel ei oppgåve'
        },
        eye: {
          showStudents: 'Vis elevar',
          hideStudents: 'Skjul elevar',
          browseByStudents: 'Bla etter elevnamn'
        }
      }
    },
    sideBar: {
      filterSelectPlaceholderClosed: 'Filtrer elevar ut frå grupper',
      filterSelectPlaceholderOpen: 'Vel ei gruppe',
      wholeClass: 'Heile klassar',
      typeToSearch: 'Tast for å søke...',
      browseByStudent: 'Bla etter elevnamn'
    }
  },
  license: {
    schoolList: {
      dataList: {
        header: {
          schoolName: 'Skole navn',
          assignedLicenses: 'Lisenser tildelt',
          expiryDate: 'Utløpsdato'
        },
        manageSchool: 'Administrer skolen'
      }
    },
    schoolView: {
      teachers: {
        title: 'Lærer',
        card: {
          numberOfTeachers: '<strong>{{ count }}</strong> lærere',
          numberOfActivatedLicenses:
            '<strong>{{ count }}</strong> aktiverte lisenser'
        },
        manageTeachers: 'Administrer lærere'
      },
      classes: {
        title: 'Klasser',
        header: {
          className: 'Klassenavn',
          numberOfStudents: 'Antall studenter',
          licensesAssigned: 'Lisenser tildelt'
        },
        assignClass: 'Tildel til hele klassen',
        revokeClass: 'Tilbakekall alle lisenser'
      }
    },
    teacherListTable: {
      header: {
        teacherName: 'Lærernavn',
        accessActivated: 'Tilgang aktivert'
      },
      activateAccess: 'Aktiver tilgang',
      revokeAccess: 'Oppheve tilgangen'
    },
    studentListTable: {
      header: {
        studentName: 'Student navn',
        accessActivated: 'Tilgang aktivert',
        assignClass: 'Tildel til hele klassen',
        revokeClass: 'Tilbakekall alle lisenser'
      },
      assignLicense: 'Tildel lisens',
      revokeLicense: 'Oppheve lisensen'
    },
    header: {
      content: {
        totalOfLicenses: '<strong>Antall lisenser:</strong> {{ value }}',
        licensesAssigned: '<strong>Lisenser tildelt:</strong> {{ value }}',
        balance: '<strong>Saldo:</strong> {{ value }}'
      }
    }
  },
  offlineContent: {
    table: {
      assignment: 'Oppdrag',
      subject: 'Tema',
      downloadFile: 'Last ned fil'
    }
  },
  activityHub: {
    table: {
      assignment: 'Oppgåvetype',
      subject: 'Tema',
      downloadFile: 'Last ned fil'
    }
  },
  pdfGenerator: {
    generators: {
      multiplication: {
        title: 'Multiplikasjon',
        subtitle: 'Lag arbeidsark'
      },
      additionAndSubtraction: {
        title: 'Addisjon og subtraksjon',
        subtitle: 'Lag arbeidsark',
        addition: {
          title: 'Addisjon',
          subtitle: 'Under utvikling'
        },
        subtraction: {
          title: 'Subtraksjon',
          subtitle: 'Under utvikling'
        }
      },
      division: {
        title: 'Divisjon',
        subtitle: 'Lag arbeidsark'
      },
      fraction: {
        title: 'Brøk',
        subtitle: 'Under utvikling'
      }
    },
    subViews: {
      generate: {
        settings: 'Innstillingar',
        preview: 'Forhåndsvisning',
        summary: {
          title: 'Mine val'
        },
        buttons: {
          cancel: 'Avbryt',
          clearOptions: 'Nullstill val',
          createWorksheet: 'Lag arbeidark'
        },
        tasksPerSheet: {
          title: 'Oppgaver per ark',
          label: '{{ numberOfTasks }} oppgaver',
          summary: 'Oppgaver per ark: {{ numberOfTasks }} oppgaver'
        },

        shuffleEqualsSign: {
          title: 'Snu reknestykket iblant'
        },
        generateAnswerSheet: {
          title: 'Inkluder fasit'
        },
        numberOfWorkSheets: {
          title: 'Antal arbeidsark',
          helperText:
            'Kvart arbeidsark inneheld ulike oppgåver basert på dine valde innstillingar'
        },
        generateDifferentExercises: {
          title:
            'Fleire arbeidsark med nye reknestykke (velg antal ark i «Mine val»)'
        },
        randomizeMissingFactorProduct: {
          title: 'Varier ukjend (ledd/svar)'
        },
        range: {
          from: {
            label: 'Fra:',
            validation: {
              integer: '«Fra»-tallet må være et heltall.'
            }
          },
          to: {
            label: 'Til:',
            validation: {
              min: '«Til» må være større enn, eller like mye som tallet oppgitt i «Fra»-feltet.',
              integer: '«Til»-tallet må være et heltall.'
            }
          },
          options: {
            option_1: 'Ingen',
            option_10: 'Heile hundrarar',
            option_100: 'Heile hundrarar',
            title: 'Ekstra val:',
            summary: 'Ekstra val: {{ option }}'
          },
          firstFactor: {
            title: 'Talområde (1. ledd)',
            summary: 'Talområde (1. ledd): {{ from }} til {{ to }}'
          },
          secondFactor: {
            title: ' Talområde (2. ledd)',
            summary: ' Talområde (2. ledd): {{ from }} til {{ to }}'
          }
        },
        operations: {
          multiplication: {
            randomizeOrderOfFactors: {
              title: 'Tilfeldig plassering av 1. og 2. ledd'
            }
          },
          division: {
            randomizeMissingFactorProduct: {
              title: 'Varier ukjend (ledd/svar)'
            }
          },
          addictionAndSubtraction: {
            arithmeticOperation: {
              title: 'Regneart',
              validation: {
                operationsCantBeFalse: `Addisjon og subtraksjon må ikke begge være usann`
              }
            },
            horizontalOperation: {
              title: 'Sidestilte stykker'
            },
            verticalOperation: {
              title: 'Loddrett oppstilling'
            }
          }
        }
      },
      download: {
        loadingText: 'Vennligst vent, regnearket blir generert.',
        title: 'Arbeidsarket ditt er klart til nedlasting',
        fileName: '{{ operation }}-arbeidsark.pdf',
        buttons: {
          download: 'Last ned',
          generateAnotherWorksheet: 'Lag eit til arbeidsark'
        },
        info: 'Trykk her for å gå tilbake til generatoren med dine tidlegare innstillingar lagra.',
        createNewWorksheet: 'Lag eit nytt arbeidsark'
      }
    }
  },
  customerOverview: {
    dataList: {
      headers: {
        customerId: 'Customer ID',
        organization: 'Organization',
        municipality: 'Municipality',
        dealType: 'Deal Type',
        premiumStatus: 'Premium Status',
        actions: 'Actions'
      },
      content: {
        municipality: 'Kommune',
        school: 'Skole',
        manage: 'Administrer',
        premium: 'Premium',
        freemium: 'Freemium'
      }
    },
    filters: {
      municipality: 'Kommune'
    },
    forms: {
      create: 'Skape',
      addLicenseManager: 'Legg til lisensbehandling',
      primaryLicenseManager: 'Primær lisensadministrator',
      secondaryLicenseManager: 'Sekundær lisensadministrator'
    },
    tabs: {
      customerOverview: {
        title: 'Kundeoversikt',
        content: {
          customerId: {
            label: 'Kunde-ID',
            placeholder: 'Angi kunde-ID'
          },
          intercomUserId: {
            label: 'Intercom bruker-ID',
            placeholder: 'Skriv inn intercom bruker-ID'
          },
          dealType: {
            label: 'Avtale type',
            placeholder: 'Angi Avtale type'
          },
          dealOwner: {
            label: 'Deal eier',
            placeholder: 'Angi Deal eier'
          },
          jobTitle: {
            label: 'Jobbtittel',
            placeholder: 'Angi Jobbtittel'
          },
          role: {
            label: 'Rolle',
            placeholder: 'Angi Rolle'
          },
          organizationFeideId: {
            label: 'Organisasjon',
            placeholder: 'Angi Organisasjonsfeide-id'
          },
          managerName: {
            label: 'Ledernavn',
            placeholder: 'Angi Kontoansvarlig'
          },
          phoneNumber: {
            label: 'Telefonnummer',
            placeholder: 'Angi Telefonnummer'
          },
          email: {
            label: 'E-post',
            placeholder: 'Angi E-post'
          },
          municipality: {
            label: 'Kommune',
            placeholder: 'Angi kommune'
          },
          address: {
            label: 'Adresse',
            placeholder: 'Angi Adresse'
          },
          product: {
            label: 'Produkt',
            placeholder: 'Angi Produkt'
          },
          discount: {
            label: 'Rabatt',
            placeholder: 'Angi Rabatt'
          },
          numberOfLicenses: {
            label: 'Antall lisenser',
            placeholder: 'Angi Antall lisenser'
          },
          pricePerLicense: {
            label: 'Pris per lisens',
            placeholder: 'Angi Pris per lisens'
          },
          dealValue: {
            label: 'Deal verdi',
            placeholder: 'Angi Deal verdi'
          },
          balance: {
            label: 'Lisensbanksaldo',
            placeholder: 'Angi Lisensbank'
          },
          status: {
            label: 'Status',
            placeholder: 'Velg Kundestatus'
          },
          isPayPerLogin: {
            label: 'Betal-per-pålogging',
            checkbox: {
              enable: 'Muliggjøre'
            }
          },
          isTrial: {
            label: 'Er rettssak?',
            checkbox: {
              enable: 'Muliggjøre'
            }
          },
          dateEndTrial: {
            label: 'Dato Slutt på prøveperioden',
            placeholder: 'Angi Dato Slutt på prøveperioden'
          },
          endContract: {
            label: 'Dato Slutt på kontrakt',
            placeholder: 'Angi dato for slutt på kontrakt'
          }
        },
        footer: {
          submit: '{{ action }} Kunde'
        },
        modal: {
          create: {
            alert: {
              success: 'Kunden ble opprettet'
            }
          },
          update: {
            alert: {
              success: 'Kunden ble redigert'
            }
          }
        }
      },
      invoice: {
        title: 'Invoice',
        content: {
          status: {
            label: 'Status',
            placeholder: 'Velg fakturastatus'
          },
          plan: {
            label: 'Plan',
            placeholder: 'Angi Plan'
          },
          file: {
            label: 'Fil',
            placeholder: 'Angi Fil'
          },
          date: {
            label: 'Dato',
            placeholder: 'Angi Dato'
          },
          duration: {
            label: 'Varighet',
            placeholder: 'Angi Avtalens varighet'
          },
          total: {
            label: 'Total',
            placeholder: 'Angi Total'
          },
          renewalDate: {
            label: 'Fornyelsesdato',
            placeholder: 'Angi Fornyelsesdato'
          }
        },
        footer: {
          submit: '{{ action }} Faktura'
        },
        modal: {
          create: {
            alert: {
              success: 'Fakturaen ble opprettet'
            }
          }
        }
      },
      invoiceHistory: {
        title: 'Fakturahistorikk',
        dataList: {
          actions: {
            title: 'Handlinger',
            content: {
              viewPdf: 'Se PDF',
              download: 'Nedlasting'
            }
          }
        }
      },
      license: {
        title: 'Tillatelse',
        dataList: {
          role: {
            title: 'Rolle',
            content: {
              placeholder: 'Angi Rolle'
            }
          },
          managerName: {
            title: 'Ledernavn',
            content: {
              placeholder: 'Angi Ledernavn'
            }
          },
          managerFeideId: {
            title: 'Feide ID',
            content: {
              placeholder: 'Feide ID'
            }
          },
          schoolName: {
            title: 'Skole navn',
            content: {
              placeholder: 'Angi Skole navn'
            }
          },
          numberOfLicenses: {
            title: 'Antall lisenser',
            content: {
              placeholder: 'Antall lisenser'
            }
          },
          actions: {
            title: 'Handlinger'
          }
        },
        addLicenseManager: 'Legg til lisensbehandling',
        footer: {
          submit: '{{ action }} Tillatelse'
        },
        modal: {
          update: {
            alert: {
              success: 'Fakturaen ble redigert'
            }
          }
        }
      }
    },
    placeholder: {
      enterName: 'Skriv inn navn',
      schoolName: 'Skolens navn',
      numberOfLicenses: 'Nr. av lisenser'
    }
  },
  support: {
    items: {
      talkToUs: {
        title: 'Slik når du oss',
        description:
          'Kundeservice er tilgjengelig alle hverdager fra klokken 08:00 – 16:00.<br/> ​Skriv til oss i chatten nede i høyre hjørne eller send en e-post til <strong>hei@numetry.no</strong>.​'
      },
      seeOurQuickGuides: {
        title: 'Se våre hurtigguider'
      },
      faq: {
        title: 'Ofte stilte spørsmål',
        description:
          'I [Numetrys hjelpesenter](https://intercom-help.eu/numetrygame/nb/) finner du en rekke artikler med svar på ofte stilte spørsmål.'
      },
      userManual: {
        title: 'Brukermanual',
        description:
          'Gå til/last ned <strong>brukermanualen</strong> for å lære om alle Numetrys funksjoner og tjenester. Den kan også skrives ut.'
      },
      join: {
        title: 'Bli med i fellesskapet',
        description:
          'Med deg inn i Facebook-siden [Matematikkspillet Numetry for lærere](https://www.facebook.com/groups/1039314306594631/) for å snakke med andre lærere som bruker<br/> Numetry Skole, få tilgang til siste nytt og bli med på å påvirke utviklingen.'
      },
      privacyStatement: {
        title: '[Personvernerklæring](https://www.numetrygame.com/personvern/)'
      },
      termsOfUse: {
        title:
          '[Vilkår for bruk](https://www.numetrygame.com/vilkar-og-betingelser/)'
      }
    },
    articles: {
      howToUseReports: {
        title: 'Hvordan ta i bruk rapporter'
      },
      howToCreateAnAssignment: {
        title: 'Hvordan lage en oppgave'
      },
      numetryInTeaching: {
        title: 'Klasseaktiviteter med Numetry'
      },
      tipsForLessons: {
        title: 'Hvordan lage en gruppe'
      }
    }
  }
}

export default pagesLocaleNn
