import React, { useState } from 'react'
import { KeyboardArrowDownRounded } from '@mui/icons-material'
import {
  Avatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from '@mui/material'

import { Button } from 'common/components'

import { useLanguage } from 'context'
import { LANGUAGES_DATA, LanguageTypes } from 'i18n'

import { LanguageSelectorPropTypes } from './LanguageSelector.types'

export const LanguageSelectorComponent: React.FC<LanguageSelectorPropTypes> = ({
  ...rest
}) => {
  const { language, changeLanguage } = useLanguage()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (lang: LanguageTypes) => {
    changeLanguage(lang.iso639)
    localStorage.setItem('i18nextLng', lang.iso639)
    handleClose()
  }

  const renderImgIcon = (lang: LanguageTypes) => (
    <Avatar
      srcSet={lang.flag}
      alt={`${lang.withAccent} flag`}
      imgProps={{
        sx: {
          width: '2.4rem',
          height: '2.4rem'
        }
      }}
    />
  )

  return (
    <>
      <Button
        {...rest}
        color='grey'
        endIcon={<KeyboardArrowDownRounded />}
        startIcon={renderImgIcon(language)}
        value={language.withAccent}
        onClick={handleButtonClick}
      />
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock
        MenuListProps={{
          role: 'listbox'
        }}
      >
        {LANGUAGES_DATA.filter(
          (lang) => lang.iso639 !== language.iso639 && !lang.disabled
        ).map((lang) => (
          <MenuItem
            key={lang.iso639}
            selected={lang.id === language.id}
            onClick={() => handleMenuItemClick(lang)}
          >
            <ListItemIcon>{renderImgIcon(lang)}</ListItemIcon>
            <ListItemText>{lang.withAccent}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
