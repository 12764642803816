import styled from 'styled-components'
import { Button } from 'common/components'

export const LicenseStyles = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease;
  border: 0.1rem solid ${({ theme }) => theme.palette.grey.A700};
  background: ${({ theme }) => theme.palette.common.white};
`

export const TableContainerStyles = styled.div`
  height: 100%;
`

export const CellButtonStyles = styled(Button)`
  text-align: left;
  justify-content: flex-start;
  word-wrap: break-word;
  overflow: hidden;
`

export const ActionContainerStyles = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
  width: 100%;
  justify-content: flex-end;
`
