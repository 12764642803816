import { Typography } from '@mui/material'
import React from 'react'
import { getStringInitials } from 'utils'
import { AvatarComponent, CheckboxComponent } from 'components'
import { UserTableRowPropTypes } from './UserTable.types'
import { UserRowStyles, BoxStyles } from './UserTableRow.styles'

export const UserTableRowComponent: React.FC<UserTableRowPropTypes> = ({
  user,
  checkbox,
  onClick
}) => {
  const { secondLetter } = getStringInitials(user.name)

  return (
    <UserRowStyles onClick={onClick} isSelected={checkbox?.checked}>
      {checkbox && (
        <CheckboxComponent {...checkbox} color={checkbox.color || 'primary'} />
      )}
      <AvatarComponent name={user.name} />
      <BoxStyles>
        <Typography
          component='span'
          textOverflow='ellipsis'
          noWrap
          whiteSpace='nowrap'
        >
          {user.renderName
            ? user.renderName
            : `${user.name.split(' ')[0]} ${secondLetter}.`}
        </Typography>
      </BoxStyles>
    </UserRowStyles>
  )
}
