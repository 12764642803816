import React from 'react'
import { Breadcrumbs, Link, Typography } from '@mui/material'
import { Trans } from 'react-i18next'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { capitalizeFirstLetter, replaceDashWithSpaces } from 'utils'

import { LayoutHeaderStyles } from './LayoutHeader.styles'
import { LayoutHeaderPropTypes } from './LayoutHeader.types'

export const LayoutHeaderComponent: React.FC<LayoutHeaderPropTypes> = ({
  title,
  subtitle,
  hideSubtitle,
  hideTitle,
  breadCrumbs,
  hideBreadcrumbs = false // ! REMOVE THIS PROP AFTER CHANGE THE ALL PAGES
}) => {
  const location = useLocation()
  const pathnames = breadCrumbs || location.pathname.split('/').filter((x) => x)

  const lastPathNameIndex = pathnames.length - 1
  const hasOnlyOneChild = pathnames.length === 1

  const pathName = (text: string) => {
    if (breadCrumbs) return capitalizeFirstLetter(text)
    return capitalizeFirstLetter(replaceDashWithSpaces(text))
  }

  const renderCrumb = (path: string, index: number, paths: string[]) => {
    const isLast = index === lastPathNameIndex
    const to = `/${paths.slice(0, index + 1).join('/')}`

    if (hasOnlyOneChild) return null

    if (isLast || breadCrumbs) {
      return (
        <Typography
          key={`item-${lastPathNameIndex.toString()}`}
          color={isLast ? 'primary' : 'inherit'}
          component='span'
        >
          {isLast ? pathName(paths[lastPathNameIndex]) : pathName(path)}
        </Typography>
      )
    }

    return (
      <Link
        key={`breadcrumb-${path}-${index.toString()}`}
        color='inherit'
        component={RouterLink}
        underline='hover'
        to={to}
      >
        {pathName(path)}
      </Link>
    )
  }

  return (
    <LayoutHeaderStyles>
      {!hasOnlyOneChild && !hideBreadcrumbs && (
        <Breadcrumbs aria-label='breadcrumb' separator='&rsaquo;'>
          {pathnames.map((path, index, arr) => renderCrumb(path, index, arr))}
        </Breadcrumbs>
      )}

      {!hideTitle && (
        <Typography
          variant='h1'
          fontWeight={(theme) => theme.typography.fontWeightBold}
        >
          {title}
        </Typography>
      )}
      {!hideSubtitle && (
        <Trans>
          {subtitle && <Typography variant='subtitle1'>{subtitle}</Typography>}
        </Trans>
      )}
    </LayoutHeaderStyles>
  )
}

export * from './LayoutHeader.types'
