import { ApiResponseTypes } from 'services/api/api.types'
import { api } from '../../api'
import { FeedbackPayloadTypes, FeedbackTypes } from './Feedbacks.types'

const postFeedback = async (payload: FeedbackPayloadTypes) => {
  return api
    .post<ApiResponseTypes<FeedbackTypes>>('/feedbacks', payload)
    .then((response) => response.data.data)
}

export default {
  post: postFeedback
}
