import { DialogContentProps } from '@mui/material'
import React from 'react'
import { DialogBodyStyles } from './DialogBody.styles'

export const DialogBody: React.FC<DialogContentProps> = ({
  children,
  ref,
  ...rest
}) => {
  return (
    <DialogBodyStyles ref={ref} {...rest}>
      {children}
    </DialogBodyStyles>
  )
}
