import { AxiosError } from 'axios'
import { useFeedback } from 'context'
import { ApiResponseTypes, ReportsServices } from 'services'
import { useInfiniteQuery } from 'react-query'
import { getNextPageParam } from 'utils/getNextParam'

import { useParamsSelector } from 'hooks'

import { useParams as useRouterParams } from 'react-router-dom'
import { UseQueryOptionsTypes } from '../../queries.types'

export const useInfiniteStudentsByAssignmentQuery = (
  options: UseQueryOptionsTypes | undefined = {}
) => {
  const { useParams, enabled } = options

  const params = useParamsSelector()

  const queryParams = useParams ? params.objParams : {}

  const { assignmentId } = useRouterParams()

  const { alert } = useFeedback()

  const { data, ...infiniteQuery } = useInfiniteQuery(
    ['v2/reports/assignments/assignmentId/students', queryParams, assignmentId],
    (paginatedParams) =>
      ReportsServices.assignments.id.students.paginated({
        assignmentId: Number(assignmentId),
        params: {
          assignmentId: queryParams.assignmentId || assignmentId,
          userGroupId: queryParams.userGroupId,
          orderBy: queryParams.orderBy,
          orderType: queryParams.orderType,
          search: queryParams.search,
          page: paginatedParams.pageParam
        }
      }),
    {
      keepPreviousData: true,
      getNextPageParam,
      cacheTime: 0,
      onError: (error: AxiosError<ApiResponseTypes<unknown>>) => {
        alert.showError(error.response?.data.message || error.message)
      },
      enabled
    }
  )

  return {
    data: data?.pages.flatMap((page) => page.data) || [],
    dataCount:
      data?.pages.flatMap((page) => page.pagination?.dataCount)[0] || 0,
    ...infiniteQuery
  }
}
