import styled from 'styled-components'
import { CustomInputPropTypes } from './CustomInput.types'

export const CustomInputStyles = styled.div<CustomInputPropTypes>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;

  height: 100%;
  padding: 1rem;

  border: 0.2rem solid transparent;

  transition: all 0.2s;

  :not([disabled]):hover {
    background-color: ${({ theme }) => theme.palette.grey.A200};
    cursor: text;
  }

  :focus-within {
    border-color: ${({ theme }) => theme.palette.secondary.main};
  }

  input {
    height: 100%;
    padding: 0;
    margin: 0;
    appearance: none;
    border: none;
    background-color: transparent;
    font-weight: bold;
    font-size: ${({ theme }) => theme.typography.h2.fontSize};
  }
`
