import styled from 'styled-components'

export const AsideFeedbackContainerStyles = styled.form`
  display: flex;
  flex-direction: column;
  width: inherit;
`

export const AsideFeedbackInitialContentStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 2.4rem;
`

export const AsideFeedbackHeaderStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.4rem;
`

export const AsideFeedbackMoodStyles = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
`

export const AsideFeedbackMessageStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2.4rem;

  .MuiInputBase-root {
    border: none;
    border-radius: 0;
    padding: 0;
    .MuiInputBase-input {
      overflow: overlay;
      padding: 2.4rem 2.4rem 0;
    }
    :hover {
      background-color: transparent;
    }
  }
`
