import { makeQueryParams } from 'utils'
import { ApiPaginatedParamTypes, PaginatedResponse, api } from '../../api'
import { TeacherTypes } from './Teachers.types'

const getAllTeachers = async ({
  params: {
    page = 1,
    pageSize = 20,
    search = null,
    withProgress,
    withCustomGroups
  }
}: ApiPaginatedParamTypes) => {
  const response = await api.get<PaginatedResponse<TeacherTypes>>(
    `/teachers${makeQueryParams({
      selectedPage: page,
      pageSize,
      search,
      withProgress,
      withCustomGroups
    })}`
  )

  return response.data
}

export default {
  get: getAllTeachers
}
