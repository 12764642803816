import { topicsList, userGroupsList } from 'atoms'
import { StatusTypes } from 'components'
import { useContentQuery } from 'hooks'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { SelectOptionDataTypes } from './selectOptions.types'

export const getSelectOptionsData = () => {
  const { t } = useTranslation()

  const userGroups = useRecoilValue(userGroupsList)
  const topics = useRecoilValue(topicsList)

  const { topicMutation } = useContentQuery()

  const TOPICS_SELECT_DATA: SelectOptionDataTypes = {
    name: 'topic',
    placeholder: t('general.filters.topics.title'),
    options: topics.map(({ name, id }) => ({
      label: name,
      value: String(id)
    }))
  }

  const STATUS_SELECT_DATA: SelectOptionDataTypes = {
    name: 'status',
    placeholder: t('general.filters.status.item.allAssignments'),
    options: [
      {
        label: t('general.filters.status.item.progress'),
        value: StatusTypes.ACTIVE
      },
      {
        label: t('general.filters.status.item.upcoming'),
        value: StatusTypes.PLANNED
      },
      {
        label: t('general.filters.status.item.finished'),
        value: StatusTypes.COMPLETED
      }
    ],
    mode: 'status'
  }

  const GROUPS_SELECT_DATA: SelectOptionDataTypes = {
    name: 'group',
    placeholder: t('general.filters.groups.title'),
    options: userGroups.map(({ name, id }) => ({
      label: name,
      value: String(id)
    }))
  }

  // TODO: Should generate an enum for this const values
  const SUBJECTS_SELECT_DATA: SelectOptionDataTypes = {
    name: 'subject',
    placeholder: t('general.filters.subjects.title'),
    options: [
      {
        label: t('general.filters.subjects.item.brokFilterCode'),
        value: 'brokAlgebraEtc'
      },
      {
        label: t('general.filters.subjects.item.algebraFilterCode'),
        value: 'brokAlgebraEtc'
      },
      {
        label: t('general.filters.subjects.item.klokkaFilterCode'),
        value: 'brokAlgebraEtc'
      },
      {
        label: t('general.filters.subjects.item.regnearterFilterCode'),
        value: 'brokAlgebraEtc'
      },
      {
        label: t('general.filters.subjects.item.sannsynlighetFilterCode'),
        value: 'brokAlgebraEtc'
      },
      {
        label: t('general.filters.subjects.item.additionAndSubtraction'),
        value: 'additionAndSubtraction'
      },
      {
        label: t('general.filters.subjects.item.probability'),
        value: 'probability'
      },
      {
        label: t('general.filters.subjects.item.multiplication'),
        value: 'multiplication'
      },
      {
        label: t('general.filters.subjects.item.division'),
        value: 'division'
      },
      {
        label: t('general.filters.subjects.item.negativeNumbers'),
        value: 'negativeNumbers'
      }
    ]
  }

  const SUBJECTS_ACTIVITYHUB_DATA: SelectOptionDataTypes = {
    name: 'subject',
    placeholder: t('general.filters.subjects.title'),
    options: [
      {
        label: t('general.filters.subjects.item.mathematicalConcepts'),
        value: 'mathematicalConcepts'
      },
      {
        label: t('general.filters.subjects.item.observationSkillsFilter'),
        value: 'observationSkills'
      },
      {
        label: t('general.filters.subjects.item.problemsolvingFilter'),
        value: 'observationSkills'
      },
      {
        label: t('general.filters.subjects.item.fourArithmeticOperations'),
        value: 'fourArithmeticOperations,logicalThinking'
      },
      {
        label: t('general.filters.subjects.item.logicalThinkingFilter'),
        value: 'logicalThinking'
      },
      {
        label: t('general.filters.subjects.item.multiplicationTable'),
        value: 'multiplicationTable'
      },
      {
        label: t('general.filters.subjects.item.algebra'),
        value: 'algebra'
      },
      {
        label: t('general.filters.subjects.item.creativeWriting'),
        value: 'creativeWriting'
      },
      {
        label: t('general.filters.subjects.item.reading'),
        value: 'reading'
      },
      {
        label: t('general.filters.subjects.item.coloring'),
        value: 'coloring'
      }
    ]
  }

  const PRODUCTS_SELECT_DATA: SelectOptionDataTypes = {
    name: 'product',
    placeholder: t('general.actions.select'),
    options: [
      {
        label: 'Product',
        value: 'product'
      }
    ]
  }

  useEffect(() => {
    topicMutation.mutate()
  }, [])

  return {
    TOPICS_SELECT_DATA,
    STATUS_SELECT_DATA,
    GROUPS_SELECT_DATA,
    SUBJECTS_SELECT_DATA,
    PRODUCTS_SELECT_DATA,
    SUBJECTS_ACTIVITYHUB_DATA
  }
}

export * from './selectOptions.types'
