import { ApiResponseTypes } from 'services/api/api.types'
import {
  CreateOrUpdateLicense,
  licenseApi,
  OrganizationCustomer,
  OrganizationDelete
} from 'services/license/license.api'

const customerPost = async (payload: Partial<OrganizationCustomer>) => {
  const { data: response } = await licenseApi.post<
    ApiResponseTypes<CreateOrUpdateLicense>
  >(`/organization-licensing`, payload)

  return response.data
}

const customerGet = async (id: OrganizationCustomer['id']) => {
  const { data: response } = await licenseApi.get<
    ApiResponseTypes<OrganizationCustomer>
  >(`/organization-licensing/${id}`)

  return response.data
}

const customerDelete = async (payload: OrganizationDelete) => {
  return licenseApi.delete<OrganizationDelete>(`/organization-licensing`, {
    data: payload
  })
}

const customerPatch = async (
  id: OrganizationCustomer['id'],
  payload: Partial<OrganizationCustomer>
) => {
  const { data: response } = await licenseApi.patch<
    ApiResponseTypes<CreateOrUpdateLicense>
  >(`/organization-licensing`, payload)

  return response.data
}

export default {
  get: customerGet,
  post: customerPost,
  delete: customerDelete,
  patch: customerPatch
}
