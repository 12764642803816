import styled, { css } from 'styled-components'
import { inputState } from 'styles'
import { Box, TextField } from '@mui/material'
import { GeneralActionTypes } from './DatePicker.types'

export const DatePickerContainerStyles = styled(Box)<{ fullWidth?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .MuiInputBase-root {
    width: 100%;
  }

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      .MuiFormControl-root {
        width: 100%;
      }
    `}
`

export const TextFieldStyles = styled(TextField)<GeneralActionTypes>`
  .MuiInputBase-root {
    min-height: 3.2rem;

    border-radius: 0.5rem;
    padding: 0 1rem 0 0;

    transition: all 0.2s;

    border: 0.1rem solid ${({ theme }) => theme.palette.grey[100]};

    .MuiInputBase-input {
      padding: 0.5rem 0.5rem 0.5rem 1rem;

      :disabled {
        cursor: not-allowed;
      }
    }

    ::before,
    ::after {
      content: none;
    }

    ${({ error }) => inputState.error(error)}
    ${({ success }) => inputState.success(success)}
    ${({ focused }) => inputState.focused(focused)}
    ${({ hovered }) => inputState.hovered(hovered)}
  }
`
