import { makeQueryParams } from 'utils/makeQueryParams'
import { api } from '../../api'
import { PaginatedStudentResponseTypes } from './Students.types'
import { ApiPaginatedParamTypes } from '../../api.types'

const paginated = async ({
  params: {
    page = 1,
    pageSize = 20,
    search = null,
    withProgress,
    withCustomGroups
  }
}: ApiPaginatedParamTypes) => {
  return api
    .get<PaginatedStudentResponseTypes>(
      `/students${makeQueryParams({
        selectedPage: page,
        pageSize,
        search,
        withProgress,
        withCustomGroups
      })}`
    )
    .then((response) => {
      return response.data
    })
}

export default {
  paginated
}
