import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from '@react-pdf/renderer'

import { NumetryLogo } from '../../resources'

import { header } from './Header.styles'
import { PDFHeaderPropTypes } from './Header.types'

export const PDFHeaderComponent: React.FC<PDFHeaderPropTypes> = ({
  variant = 'default',
  title
}) => {
  const { t } = useTranslation()

  return (
    <View style={header.default}>
      <View style={header.dataContent}>
        {variant === 'default' && (
          <View style={header.leftContent}>
            <Text style={[header.dataText, { width: 250 }]}>
              {t('components.pdfs.worksheet.name')}
            </Text>
            <Text style={[header.dataText, { width: 100 }]}>
              {t('components.pdfs.worksheet.class')}
            </Text>
          </View>
        )}
        {variant === 'answerSheet' && (
          <View style={header.leftContent}>
            <Text style={header.answerSheetText}>
              {t('components.pdfs.worksheet.answerSheet.title')}
            </Text>
          </View>
        )}
        <NumetryLogo width={70} height={30} />
      </View>
      <Text style={header.titleText}>{title}</Text>
    </View>
  )
}
