import { api } from '../../api'
import { TeacherTypes } from './Teachers.types'

const assignLicense = async (
  teacherIdOrIds: TeacherTypes['id'] | TeacherTypes['id'][]
) => {
  const teacherIds = Array.isArray(teacherIdOrIds)
    ? teacherIdOrIds
    : [teacherIdOrIds]

  const teacherIdsAsString = teacherIds.flatMap((teacherId) =>
    String(teacherId)
  )

  const response = await api.put<TeacherTypes>(
    `/teachers/assignLicenses`,
    teacherIdsAsString
  )

  return response.data
}

export default assignLicense
