import { Popover } from '@mui/material'
import styled from 'styled-components'

export const AsideFeedbackPopoverStyles = styled(Popover)`
  .MuiPopover-paper {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    border: 0.1rem solid ${({ theme }) => theme.palette.divider};
    box-shadow: 0.2rem 0 2rem ${({ theme }) => theme.palette.common.black}20;
    background: ${({ theme }) => theme.palette.common.white};
  }
`
