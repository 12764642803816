export default {
  login: 'Login to the Teacher Portal',
  logout: 'Logout',
  signup: 'Sign up',
  signout: 'Sign out',
  withFeide: 'Login using Feide',
  email: 'E-mail',
  emailPlaceholder: 'example@email.com',
  password: 'Password'
}
