import React from 'react'
import ReactPDF, { Svg, G, Path } from '@react-pdf/renderer'
import { useTheme } from '@mui/material'

export const NumetryLogo: React.FC<ReactPDF.SVGProps> = ({
  width,
  height,
  ...rest
}) => {
  const theme = useTheme()

  return (
    <Svg
      {...rest}
      width={width || 130}
      height={height || 53}
      viewBox='0 0 129.992 53.248'
    >
      <Path
        d='M117.715,0h-9.234V5.055l-1.225-1.1H92.026l-16.417.026v.026L63.526,3.985h-9.1l-.8.387c-1.844.9-3.572,1.754-5.236,2.566C46.8,6.164,45.1,5.313,43.227,4.385l-.8-.4-4.72-.064H21.343V0H0V39.655l11.142-1.522,2.05-.232,3.585-.413,4.578-.529V34.935c.322.271.645.542.941.787l1.212,1.006,1.573-.142c1.264-.116,3.056-.258,4.733-.4q1.064,8.569,2.1,17.061a271.757,271.757,0,0,1,66.169,0q1.025-8.453,2.089-17.01l1.057.09h0l3.456.31c.155.013.258.026.387.039l.206.026c1.56.142,3.92.387,3.946.387l4.2.426V35.877c.245.271.516.555.8.864l.954,1.019,1.38.168c2.992.361,9.027,1.186,9.092,1.186l4.333.593V0Z'
        fill={theme.palette.primary.main}
      />
      <Path
        d='M29.6,255.7'
        transform='translate(-25.783 -222.725)'
        fill={theme.palette.primary.main}
      />
      <G transform='translate(3.817 3.817)'>
        <Path
          d='M38.73,47.551,34.075,29.6H29.6V61.053l4.565-.619V41.877l4.565,17.99,4.578-.529V29.6H38.73Zm16.4,6.512-4.578.335V33.533H45.978v21.24s2.708,2.3,4.527,3.8c3.056-.284,9.156-.748,9.156-.748v-24.3H55.134ZM74.182,36.989q-3.443-1.683-6.874-3.392H62.524v24.09l4.6-.271V38.073l4.565,2.27V57.2s2.76-.116,4.6-.181c.052-5.545,0-16.571,0-16.571l4.6-2.308V56.9l4.6-.077V33.585h-4.41C78.772,34.72,76.477,35.842,74.182,36.989Zm19,15.965v-5.61h6.886V43.283H93.177v-5.79q4.6,0,9.208.026c0-1.56,0-2.347-.013-3.907H88.561V56.823h.413c1.586-.013,2.5,0,4.2,0,3.082.013,6.151.064,9.234.155,0-1.56,0-2.334-.013-3.895C99.329,53.006,96.247,52.968,93.177,52.955Zm12.032-15.449,4.591.026.219,19.705,4.591.219V37.57l4.359.052V33.585h-13.76ZM121.6,33.559s-.09,16.2,0,24.3c1.831.129,4.591.348,4.591.348V49.4l2.192-1.032,2.386,1.393s-.039,5.881,0,8.847c.245.026.374.039.619.052l3.972.387V47.616s-1.5-.9-2.412-1.431c.9-.451,2.412-1.212,2.412-1.212V36.925l-3.779-3.366Zm9.182,8.821-4.591,2.244V37.673l4.591.064ZM147.315,29.6V45.849l-4.668-2.876V29.6h-4.552V45.282l9.234,5.661v5.042s-2.721-.3-4.539-.477c-.026-1.857-.026-2.8-.052-4.655-1.831-.142-2.747-.219-4.578-.348.013,1.831.026,2.734.052,4.565,1.844,1.883,2.76,2.85,4.591,4.81,3.056.374,9.143,1.2,9.143,1.2V29.6Z'
          transform='translate(-29.6 -29.6)'
          fill={theme.palette.common.white}
        />
        <G transform='translate(30.501 30.834)'>
          <Path
            d='M267.814,290.674a.545.545,0,0,1-.7-.555c-.077-.684-.155-1.354-.219-2.038-.052-.438.129-.645.567-.7,1.586-.168,3.172-.322,4.759-.477a.458.458,0,0,0,.413-.542c-.039-.4-.206-.542-.516-.555l-2.708-.116c-1.689-.077-2.863-.645-3.069-2.592-.064-.606-.129-1.212-.206-1.831-.219-1.986,1.07-3.224,3.443-3.456,1.7-.168,3.417-.31,5.12-.451.464-.039.671.142.709.58.052.7.1,1.38.155,2.076.039.438-.155.645-.606.683-1.406.116-2.811.232-4.217.361a.473.473,0,0,0-.438.567.531.531,0,0,0,.542.516c.9.039,1.805.064,2.708.116,1.715.1,2.876.722,3.018,2.669.039.606.09,1.225.129,1.831.142,1.986-1.148,3.172-3.392,3.366C271.489,290.3,269.645,290.481,267.814,290.674Z'
            transform='translate(-266.113 -276.244)'
            fill={theme.palette.common.white}
          />
          <Path
            d='M360.3,284.821c-.438.026-.645-.155-.671-.593-.245-3.766-.5-7.544-.748-11.31-.026-.438.155-.645.619-.671.941-.064,1.9-.116,2.837-.168.464-.026.671.155.7.593.064,1.341.142,2.682.206,4.024l.722-.039c.555-1.393,1.109-2.773,1.689-4.153a.87.87,0,0,1,.89-.619c.941-.039,1.883-.064,2.824-.09.426-.013.58.193.426.567-.58,1.38-1.161,2.747-1.728,4.127a2.208,2.208,0,0,1,2.283,2.308c.039,1.638.09,3.288.129,4.926.013.438-.181.632-.619.645-.9.026-1.805.052-2.7.09-.438.013-.645-.168-.658-.606-.052-1.135-.09-2.27-.142-3.4a.518.518,0,0,0-.593-.567l-2.347.116c.064,1.328.142,2.669.206,4,.026.438-.155.645-.593.658C362.108,284.705,361.205,284.77,360.3,284.821Z'
            transform='translate(-346.918 -271.396)'
            fill={theme.palette.common.white}
          />
          <Path
            d='M469.129,281.828c-4.372,0-5.468-1.38-5.52-3.521-.039-1.986-.077-3.972-.116-5.971-.039-2.128,1.045-3.637,5.648-3.637s5.674,1.522,5.623,3.65c-.039,1.986-.09,3.972-.129,5.971C474.584,280.474,473.5,281.854,469.129,281.828Zm0-3.366c1.29,0,1.5-.348,1.5-.812.013-1.573.026-3.134.039-4.707,0-.451-.193-.812-1.522-.812-1.3,0-1.522.348-1.522.812,0,1.573.013,3.134.013,4.707C467.633,278.114,467.852,278.462,469.129,278.462Z'
            transform='translate(-438.038 -268.7)'
            fill={theme.palette.common.white}
          />
          <Path
            d='M567.391,284.582c-.438-.013-.632-.206-.606-.645q.174-5.668.335-11.336c.013-.438.219-.619.684-.606.954.026,1.9.064,2.85.1.464.026.658.219.632.658-.129,2.889-.258,5.764-.387,8.653l2.424.116.155-2.824c.026-.438.232-.619.683-.593l2.6.155c.451.026.632.232.606.671-.129,1.831-.245,3.662-.374,5.494-.026.438-.232.619-.671.58C573.336,284.827,570.37,284.672,567.391,284.582Z'
            transform='translate(-528.008 -271.57)'
            fill={theme.palette.common.white}
          />
          <Path
            d='M661.946,289.926c-.438-.039-.619-.245-.58-.671q.426-5.648.851-11.31c.039-.438.245-.619.709-.58q4.256.329,8.486.787c.464.052.632.258.593.7-.077.684-.155,1.354-.219,2.038-.052.438-.271.606-.722.555-1.664-.181-3.327-.348-4.991-.49-.039.451-.077.89-.116,1.341,1.174.1,2.334.219,3.508.335.451.052.632.258.58.7l-.193,1.857c-.052.438-.258.606-.709.555-1.161-.116-2.308-.232-3.469-.335-.039.5-.09.993-.129,1.5,1.625.142,3.263.309,4.888.477.438.052.619.258.567.7-.077.683-.155,1.354-.219,2.038-.052.438-.258.606-.7.555Q666,290.236,661.946,289.926Z'
            transform='translate(-610.389 -276.244)'
            fill={theme.palette.common.white}
          />
        </G>
      </G>
    </Svg>
  )
}
