import React, { lazy } from 'react'
import { Route } from 'react-router-dom'

import { AssignmentContextWrapper, GroupContextWrapper } from 'context'
import { ContextRoute, ROUTES } from 'router'

const Assignment = lazy(() => import('views/assignment/Assignment'))
const EditAssignment = lazy(
  () => import('views/assignment/subViews/id/EditAssignment')
)

export const renderAssignmentRoutes = () => {
  const { ASSIGNMENT, ID } = ROUTES

  return (
    <Route
      path={ASSIGNMENT}
      element={
        <ContextRoute
          wrapper={[AssignmentContextWrapper, GroupContextWrapper]}
        />
      }
    >
      <Route index element={<Assignment />} />
      <Route path={ID} element={<EditAssignment />} />
    </Route>
  )
}
