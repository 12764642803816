import React, { useRef } from 'react'

import { DatePicker } from '@mui/x-date-pickers'
import { useTranslation } from 'react-i18next'
import { useActionState } from 'hooks'
import { DatePickerPropTypes } from './DatePicker.types'
import { DatePickerContainerStyles, TextFieldStyles } from './DatePicker.styles'
import { HelperTextComponent } from '../input/Input'

export const DatePickerComponent: React.FC<DatePickerPropTypes> = ({
  name,
  fullWidth,
  inputFormat = 'dd/MM/yyyy',
  orientation = 'portrait',
  helperText,
  error = false,
  success = false,
  hovered = false,
  focused = false,
  flex,
  ...rest
}) => {
  const { t } = useTranslation()

  const inputElementRef = useRef<HTMLInputElement>(null)
  const hoverElementRef = useRef<HTMLDivElement>(null)

  const { hasError, hasFocus, hasHover, hasSuccess } = useActionState(
    { error, focused, success, hovered },
    inputElementRef,
    hoverElementRef
  )

  return (
    <DatePickerContainerStyles
      fullWidth={fullWidth}
      tabIndex={-1}
      sx={{ flex }}
      ref={hoverElementRef}
    >
      <DatePicker
        {...rest}
        InputProps={{
          ...rest.InputProps,
          name,
          margin: 'none',
          inputRef: inputElementRef
        }}
        inputFormat={inputFormat}
        orientation={orientation}
        renderInput={(props) => (
          <TextFieldStyles
            {...props}
            variant='standard'
            ref={props.inputRef}
            error={hasError}
            success={hasSuccess}
            focused={hasFocus}
            hovered={hasHover}
          />
        )}
      />
      {helperText && (
        <HelperTextComponent id={name} error={hasError} success={hasSuccess}>
          {helperText || t('general.status.error.somethingWentWrong')}
        </HelperTextComponent>
      )}
    </DatePickerContainerStyles>
  )
}

export * from './DatePicker.types'
