import React, { useState } from 'react'
import {
  LogoutOutlined,
  AccountCircleOutlined,
  KeyboardArrowDownRounded
} from '@mui/icons-material'
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Button } from 'common/components'
import { useAuth } from 'context'
import { ROUTES } from 'router'
import { UserSettingsPropTypes } from './UserSettings.types'

export const UserSettingsComponent: React.FC<UserSettingsPropTypes> = ({
  onlyIcon,
  ...rest
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useAuth()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  return (
    <div className='UserSettings-root'>
      <Button
        {...rest}
        color='grey'
        startIcon={<AccountCircleOutlined />}
        endIcon={<KeyboardArrowDownRounded />}
        value={onlyIcon ? undefined : user?.name || t('general.teacher')}
        onClick={handleClick}
        sx={{
          '.MuiButton-startIcon > svg': {
            fontSize: 30
          },
          textAlign: 'left',
          minWidth: 'max-content'
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
      >
        {[
          {
            id: 0,
            label: t('auth.logout'),
            value: 'logout',
            icon: <LogoutOutlined />,
            to: `/${ROUTES.AUTH}/${ROUTES.LOGOUT}`
          }
        ].map((item) => (
          <MenuItem
            key={item.id}
            LinkComponent={NavLink}
            href={item.to}
            onClick={() => navigate(item.to)}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
