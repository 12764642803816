import { makeQueryParams } from 'utils'
import {
  ApiResponseTypes,
  PaginatedApiParamsTypes,
  PaginatedResponse,
  api
} from '../../api'
import {
  AssignmentFromListTypes,
  AssignmentPayloadTypes,
  AssignmentTypes
} from './Assignments.types'

const getAllAssignments = async (params: PaginatedApiParamsTypes = {}) => {
  const response = await api.get<PaginatedResponse<AssignmentFromListTypes>>(
    `/assignments${makeQueryParams(params)}`
  )

  return response.data
}

const postAssignment = async (payload: AssignmentPayloadTypes) => {
  const { data: response } = await api.post<ApiResponseTypes<AssignmentTypes>>(
    '/assignments',
    payload
  )

  return response.data
}

export default {
  get: getAllAssignments,
  post: postAssignment
}
