import { useParams } from 'react-router-dom'
import { useInfiniteQuery } from 'react-query'
import { AxiosError } from 'axios'

import { useFeedback } from 'context'
import { useParamsSelector } from 'hooks'
import { ApiResponseTypes, ReportsServices } from 'services'

import { getNextPageParam } from 'utils/getNextParam'
import { UseQueryOptionsTypes } from '../../queries.types'

export const useInfiniteAssignmentsByStudentQuery = (
  options: UseQueryOptionsTypes | undefined = {}
) => {
  const params = useParamsSelector()

  const { studentId, userGroupId } = useParams()

  const queryParams = options.useParams ? params.objParams : {}

  const { alert } = useFeedback()

  const { data, ...infiniteQuery } = useInfiniteQuery(
    ['v2/reports/students/studentId/assignments', queryParams, studentId],
    (paginatedParams) =>
      ReportsServices.students.id.assignments.paginated({
        studentId: Number(studentId),
        params: {
          page: paginatedParams.pageParam || 1,
          pageSize: 20,
          studentId,
          search: queryParams.search,
          userGroupId
        }
      }),
    {
      keepPreviousData: true,
      getNextPageParam,
      onError: (error: AxiosError<ApiResponseTypes<unknown>>) => {
        alert.showError(error.response?.data.message || error.message)
      },
      enabled: options.enabled
    }
  )

  return {
    data: data?.pages.flatMap((page) => page.data) || [],
    dataCount:
      data?.pages.flatMap((page) => page.pagination?.dataCount)[0] || 0,
    ...infiniteQuery
  }
}
