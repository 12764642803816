import { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import { useFeedback } from 'context'
import { ApiResponseTypes, LicenseServices } from 'services'
import { UseQueryOptionsTypes } from 'hooks/queries/queries.types'

export const useTeachersCountBySchoolQuery = (
  options: UseQueryOptionsTypes | undefined = {}
) => {
  const { alert } = useFeedback()

  const teachersCountBySchoolQuery = useQuery(
    ['/licenseManagement/schools/schoolId/teachersCount'],
    () =>
      LicenseServices.schools.id.get.teachersCountBySchool({
        schoolId: Number(options.schoolId)
      }),
    {
      onError: (error: AxiosError<ApiResponseTypes<unknown>>) => {
        alert.showError(error.response?.data.message || error.message)
      }
    }
  )

  return teachersCountBySchoolQuery
}
