import styled from 'styled-components'
import { Pagination } from '@mui/material'

export const PaginationStyles = styled(Pagination)`
  .MuiPagination-ul {
    flex-wrap: nowrap;

    .Mui-selected {
      color: ${({ theme }) => theme.palette.common.white};
    }

    li {
      border: 0.1rem solid ${({ theme }) => theme.palette.secondary[100]};
    }
  }
`
