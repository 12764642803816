import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@mui/material'
import { Close, Search } from '@mui/icons-material'

import { useActionState } from 'hooks'
import { SearchStyles, SearchInputStyles } from './Search.styles'

import { SearchPropTypes } from './Search.types'

export const SearchComponent: React.FC<SearchPropTypes> = ({
  value,
  onClearClick,
  onSearchClick,
  fullWidth,
  error,
  focused,
  success,
  hovered,
  name,
  ...rest
}) => {
  const { t } = useTranslation()

  const inputRef = useRef<HTMLInputElement>(null)
  const inputHoverRef = useRef<HTMLDivElement>(null)

  const { hasError, hasFocus, hasHover, hasSuccess } = useActionState(
    { error, focused, success, hovered },
    inputRef
  )

  return (
    <SearchStyles
      fullWidth={fullWidth}
      ref={inputHoverRef}
      error={hasError}
      success={hasSuccess}
      focused={hasFocus}
      hovered={hasHover}
    >
      <SearchInputStyles
        name={name || 'search'}
        error={hasError}
        success={hasSuccess}
        ref={inputRef}
        focused={hasFocus}
        hovered={hasHover}
        value={value ?? undefined}
        placeholder={
          rest.placeholder || t('components.search.placeholder.default')
        }
        autoComplete='off'
        {...rest}
      />
      <IconButton
        disableRipple
        size='small'
        tabIndex={0}
        onClick={value ? onClearClick : onSearchClick}
      >
        {onClearClick && value ? <Close /> : <Search />}
      </IconButton>
    </SearchStyles>
  )
}

export * from './Search.styles'
export * from './Search.types'
