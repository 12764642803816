import styled from 'styled-components'
import { Colors } from 'styles'

export const AsideStyles = styled.aside`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.speedDial};

  height: -webkit-fill-available;
  background-color: ${Colors.white};

  overflow: auto;

  transition: all 0.2s ease-in-out;
`

export const AsideMainContentStyles = styled.div`
  overflow: auto;
`

export const AsideBottomContentStyles = styled.div``
