import { AuthLocaleTypes } from '../locales.types'

const authLocaleNo: AuthLocaleTypes = {
  login: 'Logg inn i Lærerportalen',
  logout: 'Logg ut',
  signup: 'Registrer deg',
  signout: 'Logg ut',
  withFeide: 'Logg inn med Feide',
  email: 'E-post',
  emailPlaceholder: 'døme@email.com',
  password: 'Passord'
}

export default authLocaleNo
