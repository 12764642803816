import React from 'react'
import { SvgIcon } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { AsideMoodItemPropTypes } from './AsideMoodItem.types'
import { AsideMoodItemStyles } from './AsideMoodItem.styles'

export const AsideMoodItemComponent: React.FC<AsideMoodItemPropTypes> = ({
  mood,
  isActive,
  isUnselected,
  ...rest
}) => {
  const { t } = useTranslation()
  return (
    <AsideMoodItemStyles
      aria-label={t(mood.label) as string}
      size='small'
      isActive={isActive}
      isUnselected={isUnselected}
      {...rest}
    >
      <SvgIcon component={mood.icon} />
    </AsideMoodItemStyles>
  )
}

export * from './AsideMoodItem.types'
