import { api, ApiResponseTypes } from 'services'
import { GameTypes } from './Games.types'

const getGame = async (id: number) => {
  const { data: response } = await api.get<ApiResponseTypes<GameTypes>>(
    `/games/${id}`
  )

  return response.data
}

const deleteGame = async (id: number) => {
  const { data: response } = await api.delete<ApiResponseTypes<GameTypes>>(
    `/games/${id}`
  )

  return response.data
}

export default {
  get: getGame,
  delete: deleteGame
}
