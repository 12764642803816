import { Box, Divider, Skeleton } from '@mui/material'
import React from 'react'
import {
  GameCardContentStyles,
  GameCardDescriptionStyles,
  GameCardImageStyles,
  GameCardStyles
} from './GameCard.styles'

export const GameCardSkeleton: React.FC = ({ children }) => {
  return (
    <GameCardStyles>
      <GameCardImageStyles>
        <Skeleton component='img' variant='rectangular' />
      </GameCardImageStyles>

      <GameCardContentStyles>
        <Box display='flex' flexDirection='column' gap={0.5}>
          <Skeleton
            variant='text'
            sx={{ fontSize: ({ typography }) => typography.body2.fontSize }}
          />
          <Skeleton
            variant='text'
            sx={{ fontSize: ({ typography }) => typography.caption.fontSize }}
          />
        </Box>

        <GameCardDescriptionStyles>
          <Skeleton
            variant='text'
            sx={{ fontSize: ({ typography }) => typography.body2.fontSize }}
          />
        </GameCardDescriptionStyles>

        <Box display='flex' flexDirection='row' gap={2}>
          {[...Array(3)].map((_, i) => (
            <Skeleton
              key={`skeleton-${i.toString()}`}
              animation='wave'
              width={50}
              sx={{ borderRadius: '10rem !important' }}
            />
          ))}
        </Box>
      </GameCardContentStyles>

      {!!children && (
        <>
          <Divider />
          {children}
        </>
      )}
    </GameCardStyles>
  )
}
