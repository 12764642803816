import { ApiResponseTypes } from 'services/api/api.types'
import { api } from '../../api'
import { StudentTypes } from './Students.types'

const getAllStudents = async () => {
  return (await api.get<ApiResponseTypes<StudentTypes[]>>('/students')).data
    .data
}

export default {
  get: getAllStudents
}
