import React from 'react'
import { Fade, Typography, SvgIcon, Box } from '@mui/material'

import {
  PopperBodyStyles,
  PopperContainerStyles,
  PopperFooterStyles,
  PopperHeaderStyles,
  PopperStyles
} from './Popper.styles'

import { PopperPropTypes } from './Popper.types'

export const PopperComponent: React.FC<PopperPropTypes> = ({
  header,
  content = 'Missing content',
  footer,
  children
}) => {
  const [open, setOpen] = React.useState(false)
  const [childNode, setChildNode] = React.useState<HTMLElement | null>(null)

  return (
    <Box display='flex' justifyContent='center' alignItems='center'>
      {React.cloneElement(children, {
        ...children.props,
        ref: setChildNode,
        onMouseEnter: () => setOpen(true),
        onMouseLeave: () => setOpen(false)
      })}

      <PopperStyles
        open={open}
        anchorEl={childNode}
        transition
        placement='right'
        modifiers={[
          {
            name: 'flip',
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: 'document',
              padding: 8
            }
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: 'document',
              padding: 8
            }
          }
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <PopperContainerStyles elevation={0}>
              <PopperHeaderStyles>
                {header.icon && <SvgIcon>{header.icon}</SvgIcon>}
                <Typography>{header.title}</Typography>
              </PopperHeaderStyles>
              {content === 'string' ? (
                <PopperBodyStyles>
                  <Typography>{content}</Typography>
                </PopperBodyStyles>
              ) : (
                content
              )}
              {typeof footer === 'string' ? (
                <PopperFooterStyles>
                  <Typography>{footer}</Typography>
                </PopperFooterStyles>
              ) : (
                footer
              )}
            </PopperContainerStyles>
          </Fade>
        )}
      </PopperStyles>
    </Box>
  )
}

export * from './Popper.types'
