import styled from 'styled-components'

export const PopUpStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  height: 100%;

  strong {
    font-size: inherit;
    word-break: break-all;
  }
`
export const ImageContainerStyles = styled.div`
  display: flex;
  flex-direction: column;

  img {
    height: 16rem;
    width: auto;
    align-self: center;
  }
`

export const FooterStyles = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`
