import styled from 'styled-components'

export const StudentItemStyles = styled.div<{ isChecked?: boolean }>`
  * {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  min-height: 5.3rem;

  padding: 0.8rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.8rem;

  background: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 0 2rem ${({ theme }) => theme.palette.common.black}10;
  border: 0.1rem solid ${({ theme }) => theme.palette.grey.A700};
  border-radius: 0.4rem;
`
