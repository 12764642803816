import { t } from 'i18next'
import { randomInRange } from 'utils'
import { SYMBOL } from '../symbol/symbol'
import { OPERATION_KEY, OperationTypes } from './operator.types'
import { isFloat, getNumberDivisibleBy } from './utils'

export const OPERATIONS: OperationTypes[] = [
  {
    key: OPERATION_KEY.MULTIPLICATION,
    symbol: SYMBOL[OPERATION_KEY.MULTIPLICATION],
    getName: () => t('pages.pdfGenerator.generators.multiplication.title'),
    getOperationValues: ({ first, second }) => {
      let n1 = randomInRange(first.from, first.to)
      let n2 = randomInRange(second.from, second.to)

      n1 = getNumberDivisibleBy(n1, {
        divisibleBy: first.multiplyBy
      })
      n2 = getNumberDivisibleBy(n2, {
        divisibleBy: second.multiplyBy
      })

      const result = n1 * n2

      return { n1, n2, result }
    }
  },
  {
    key: OPERATION_KEY.DIVISION,
    symbol: SYMBOL[OPERATION_KEY.DIVISION],
    getName: () => t('pages.pdfGenerator.generators.division.title'),
    getOperationValues: ({ first, second }) => {
      let n1 = randomInRange(first.from, first.to)
      const n2 = randomInRange(second.from, second.to)

      if (isFloat(n1 / n2)) {
        n1 = Math.ceil(n1 / n2) * n2
      }

      const result = n1 / n2

      return { n1, n2, result }
    }
  },
  {
    key: OPERATION_KEY.FRACTION,
    symbol: SYMBOL[OPERATION_KEY.FRACTION],
    getName: () => t('pages.pdfGenerator.generators.fraction.title'),
    getOperationValues: ({ first, second }) => {
      const n1 = randomInRange(first.from, first.to)
      const n2 = randomInRange(second.from, second.to)

      const result = n1 / n2

      return { n1, n2, result }
    }
  },
  {
    key: OPERATION_KEY.ADDITION,
    symbol: SYMBOL[OPERATION_KEY.ADDITION],
    getName: () =>
      t('pages.pdfGenerator.generators.additionAndSubtraction.addition.title'),
    getOperationValues: ({ first, second }) => {
      let n1 = randomInRange(first.from, first.to)
      let n2 = randomInRange(second.from, second.to)
      const isHigher = n2 > n1

      if (isHigher) {
        const temp = n1
        n1 = n2
        n2 = temp
      }

      const result = n1 + n2

      return { n1, n2, result }
    }
  },
  {
    key: OPERATION_KEY.SUBTRACTION,
    symbol: SYMBOL[OPERATION_KEY.SUBTRACTION],
    getName: () =>
      t(
        'pages.pdfGenerator.generators.additionAndSubtraction.subtraction.title'
      ),
    getOperationValues: ({ first, second }) => {
      let n1 = randomInRange(first.from, first.to)
      let n2 = randomInRange(second.from, second.to)
      const isNegative = n1 - n2 < 0

      if (isNegative) {
        const temp = n1
        n1 = n2
        n2 = temp
      }

      const result = n1 - n2

      return { n1, n2, result }
    }
  }
]
