import { ApiResponseTypes } from 'services/api/api.types'
import { api } from '../../api'
import { SkillTypes } from './Skills.types'

const getAllSkills = async () => {
  return (await api.get<ApiResponseTypes<SkillTypes[]>>('/skills')).data.data
}

export default {
  get: getAllSkills
}
