import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import { ROUTES, ProtectedRoute } from 'router'
import { ROLE } from 'services'

const Login = lazy(() => import('views/auth/login/Login'))
const Logout = lazy(() => import('views/auth/logout/Logout'))

export const renderLogoutRoutes = () => {
  const { LOGOUT, ID } = ROUTES

  return (
    <Route path={LOGOUT}>
      <Route
        index
        element={
          <ProtectedRoute roles={[ROLE.SALES_TEAM, ROLE.TEACHER]}>
            <Logout />
          </ProtectedRoute>
        }
      />
      <Route path={ID} element={<Login />} />
    </Route>
  )
}
