import { api } from 'services/api/api'
import { UserTypes } from './User.types'

const getUserFusionAuthInfo = async (token: string) => {
  return api
    .get<UserTypes>(`/api/user`, {
      baseURL: process.env.REACT_APP_FUSIONAUTH_DEV_API_URL,
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((response) => response.data)
}

export default {
  userFusionAuthInfo: getUserFusionAuthInfo
}
