import { api } from 'services'
import { TeacherTypes } from './Teachers.types'

const getTeacher = async (teacherId: TeacherTypes['id']) => {
  const response = await api.get<TeacherTypes>(
    `/assignments/assignment/${teacherId}`
  )

  return response.data
}

export default {
  get: getTeacher
}
