import { StyleSheet } from '@react-pdf/renderer'
import { palette } from 'common/theme'

export const verticalItem = StyleSheet.create({
  default: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  expression: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    borderBottom: `1px solid ${palette.common.black}`,
    padding: '5px'
  },
  operation: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginRight: '5px'
  },
  numbers: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  result: {
    padding: '5px',
    marginTop: '5px'
  },
  hideResult: {
    color: palette.common.white
  }
})
