import { from } from 'common/theme'
import styled, { css } from 'styled-components'

export const GridContainerStyles = styled.div<{ isFeideGroup?: boolean }>`
  width: 100%;

  display: flex;
  flex-direction: column;
  position: relative;

  ${from.md} {
    display: grid;
    grid-template-columns: 30rem 0.1rem 3fr;
    height: 100%;

    ${({ isFeideGroup }) =>
      isFeideGroup &&
      css`
        display: flex;
      `}
  }
`

export const StackFooterStyles = styled.div`
  position: sticky;
  bottom: 0;
  min-height: 5.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
