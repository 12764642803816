import { api, EntityHasLicente } from 'services'
import { RevokeAndAssignClassParamTypes } from './UserGroups.types'

const assignClass = async ({
  schoolId,
  groupId
}: RevokeAndAssignClassParamTypes) => {
  const response = await api.put<EntityHasLicente>(
    `/licenseManagement/schools/${schoolId}/user-groups/${groupId}/assignClass`
  )

  return response.data
}

const revokeClass = async ({
  schoolId,
  groupId
}: RevokeAndAssignClassParamTypes) => {
  const response = await api.put<EntityHasLicente>(
    `/licenseManagement/schools/${schoolId}/user-groups/${groupId}/revokeClass`
  )

  return response.data
}

export default {
  put: {
    assignClass,
    revokeClass
  }
}
