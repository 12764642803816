import muliFont from 'assets/fonts/muli/Muli-Regular.woff'
import rajdhaniFont from 'assets/fonts/rajdhani/Rajdhani-Regular.ttf'
import rajdhaniSemiBoldFont from 'assets/fonts/rajdhani/Rajdhani-SemiBold.ttf'
import rajdhaniBoldFont from 'assets/fonts/rajdhani/Rajdhani-Bold.ttf'
import veneraFont from 'assets/fonts/venera/Venera-900.otf'

export enum FontSizes {
  smallest = '0.8rem',
  smaller = '1.2rem',
  small = '1.4rem',
  normal = '1.6rem',
  normalPlus = '1.8rem',
  h6 = '2.1rem',
  h5 = '2.8rem',
  h4 = '3.7rem',
  h3 = '5.0rem',
  h2 = '6.7rem'
}

export enum FontWeights {
  normal = 400,
  bold = 600,
  extraBold = 800
}

export enum FontFamilies {
  muli = 'Muli',
  rajdhani = 'Rajdhani',
  rajdhaniSemiBold = 'Rajdhani SemiBold',
  rajdhaniBold = 'Rajdhani Bold',
  venera = 'Venera'
}

export const PDFFontFamilies = {
  rajdhani: {
    regular: {
      name: FontFamilies.rajdhani,
      source: rajdhaniFont
    },
    semiBold: {
      name: FontFamilies.rajdhaniSemiBold,
      source: rajdhaniSemiBoldFont
    },
    bold: {
      name: FontFamilies.rajdhaniBold,
      source: rajdhaniBoldFont
    }
  },
  venera: {
    regular: {
      name: FontFamilies.venera,
      source: veneraFont
    }
  }
}

export const genericTypography = (): string => `
  @font-face {
    font-family: 'Muli';
    font-style: normal;
    font-weight: normal;
    src: local('Muli'), url(${muliFont}) format('woff');
  }

  @font-face {
    font-family: 'Rajdhani';
    font-style: normal;
    font-weight: normal;
    src: local('Rajdhani'), url(${rajdhaniFont}) format('truetype');
  }

  @font-face {
    font-family: 'Rajdhani SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('Rajdhani SemiBold'), url(${rajdhaniSemiBoldFont}) format('truetype');
  }

  @font-face {
    font-family: 'Rajdhani Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Rajdhani Bold'), url(${rajdhaniBoldFont}) format('truetype');
  }

  @font-face {
    font-family: 'Venera';
    font-style: normal;
    font-weight: normal;
    src: local('Venera'), url(${veneraFont}) format('opentype');
  }
`
