import { AxiosError } from 'axios'
import { useFeedback } from 'context'
import { useParamsSelector } from 'hooks'
import { useInfiniteQuery } from 'react-query'
import { ApiResponseTypes, StudentServices } from 'services'
import { getNextPageParam } from 'utils/getNextParam'
import { UseQueryOptionsTypes } from '../queries.types'

export const useInfiniteStudentQuery = (
  options: UseQueryOptionsTypes | undefined = {}
) => {
  const { enabled, useParams, withProgress } = options

  const { alert } = useFeedback()

  const params = useParamsSelector()

  const queryParams = useParams ? params.objParams : {}

  const { data, ...infiniteQuery } = useInfiniteQuery(
    ['/students', queryParams, withProgress],
    (paginatedParams) =>
      StudentServices.paginated({
        params: {
          page: paginatedParams?.pageParam,
          search: queryParams?.searchStudent,
          withCustomGroups: queryParams?.withCustomGroups,
          withProgress
        }
      }),
    {
      keepPreviousData: true,
      getNextPageParam,
      onError: (error: AxiosError<ApiResponseTypes<unknown>>) => {
        alert.showError(error.response?.data.message || error.message)
      },
      enabled
    }
  )

  return {
    data: data?.pages.flatMap((page) => page.data) || [],
    dataCount: data?.pages.flatMap((page) => page.pagination.dataCount)[0],
    ...infiniteQuery
  }
}

export * from './useStudentQuery.types'
