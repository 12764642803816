/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { Box, SvgIcon, Typography } from '@mui/material'
import {
  Button,
  DisplayHeaderComponent,
  TableComponent
} from 'common/components'
import { useInfiniteGroupsBySchoolQuery } from 'hooks'
import { Cancel, CheckCircle } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { StudentHasLicenseTypes } from 'services'
import { TableContainerStyles } from 'views/license/License.styles'
import { createColumnHelper } from '@tanstack/react-table'
import { useLicense } from 'context'

export const ClassView = () => {
  const { t } = useTranslation()
  const { classId: groupIdParam, schoolId: schoolIdParam } = useParams()

  const groupId = Number(groupIdParam)
  const schoolId = Number(schoolIdParam)

  const { studentActions, userGroupActions } = useLicense()

  const groupsBySchoolQuery = useInfiniteGroupsBySchoolQuery({
    useParams: true
  })

  const columnHelper = createColumnHelper<StudentHasLicenseTypes>()

  const revokeAllLicensesFromClassQuery =
    userGroupActions.revokeAllLicensesFromClass()

  const assignAllLicensesToWholeClassQuery =
    userGroupActions.assignLicensesToWholeClass()

  const onSuccess = () => groupsBySchoolQuery.refetch()

  const mutateOptions = {
    groupId,
    schoolId
  }

  const handleRevokeAllLicenses = () => {
    revokeAllLicensesFromClassQuery.mutate(mutateOptions, { onSuccess })
  }

  const handleAssignAllLicensesToWholeClass = () => {
    assignAllLicensesToWholeClassQuery.mutate(mutateOptions, {
      onSuccess
    })
  }

  const columns = [
    columnHelper.accessor('name', {
      header: () => (
        <DisplayHeaderComponent
          title={t('pages.license.studentListTable.header.studentName')}
        />
      ),
      cell: (params) => <Typography>{params.getValue()}</Typography>
    }),
    columnHelper.accessor('hasLicense', {
      header: () => (
        <DisplayHeaderComponent
          title={t('pages.license.studentListTable.header.accessActivated')}
        />
      ),
      cell: (params) => {
        const hasLicense = params.getValue()
        return (
          <Box display='flex' flexDirection='row' alignItems='center'>
            <SvgIcon
              {...(hasLicense
                ? { component: CheckCircle, color: 'success' }
                : { component: Cancel, color: 'error' })}
            />
            <Typography ml={1}>
              {t(hasLicense ? 'general.yes' : 'general.no')}
            </Typography>
          </Box>
        )
      }
    }),
    columnHelper.display({
      id: 'class',
      maxSize: 60,
      header: (params) => {
        const areAllStudentsAssigned = (params?.table?.getAllColumns() || [])
          .flatMap((column) =>
            column
              .getFacetedRowModel()
              .flatRows.flatMap((row) => row.original.hasLicense)
          )
          .every((hasLicense) => hasLicense)

        return (
          <Box width='100%' display='flex' justifyContent='center'>
            <Button
              disabled={areAllStudentsAssigned}
              color='secondary'
              variant='contained'
              loading={assignAllLicensesToWholeClassQuery.isLoading}
              onClick={handleAssignAllLicensesToWholeClass}
            >
              {t('pages.license.studentListTable.header.assignClass')}
            </Button>
          </Box>
        )
      },
      cell: (params) => {
        const student = params.row.original

        const assignLicensesToStudent = studentActions.assignLicensesToStudent()

        return (
          <Box width='100%' display='flex' justifyContent='center'>
            <Button
              disabled={student.hasLicense}
              color='secondary'
              variant='contained'
              loading={assignLicensesToStudent.isLoading}
              onClick={() => {
                assignLicensesToStudent.mutate(student.id, {
                  onSuccess: () => groupsBySchoolQuery.refetch()
                })
              }}
            >
              {t('pages.license.studentListTable.assignLicense')}
            </Button>
          </Box>
        )
      }
    }),
    columnHelper.display({
      id: 'license',
      maxSize: 60,
      header: (params) => {
        const areAllStudentsRevoked = (params?.table?.getAllColumns() || [])
          .flatMap((column) =>
            column
              .getFacetedRowModel()
              .flatRows.flatMap((row) => row.original.hasLicense)
          )
          .every((hasLicense) => !hasLicense)

        return (
          <Box width='100%' display='flex' justifyContent='center'>
            <Button
              disabled={areAllStudentsRevoked}
              color='secondary'
              variant='text'
              loading={revokeAllLicensesFromClassQuery.isLoading}
              onClick={handleRevokeAllLicenses}
            >
              {t('pages.license.studentListTable.header.revokeClass')}
            </Button>
          </Box>
        )
      },
      cell: (params) => {
        const student = params.row.original

        const revokeLicensesFromStudent =
          studentActions.revokeLicensesFromStudent()

        return (
          <Box width='100%' display='flex' justifyContent='center'>
            <Button
              disabled={!student.hasLicense}
              loading={revokeLicensesFromStudent.isLoading}
              color='secondary'
              variant='text'
              onClick={() => {
                revokeLicensesFromStudent.mutate(student.id, {
                  onSuccess: () => groupsBySchoolQuery.refetch()
                })
              }}
            >
              {t('pages.license.studentListTable.revokeLicense')}
            </Button>
          </Box>
        )
      }
    })
  ]

  return (
    <TableContainerStyles>
      <TableComponent
        columns={columns}
        data={groupsBySchoolQuery.data}
        footer={{
          totalDataCount: groupsBySchoolQuery.dataCount
        }}
        isLoading={groupsBySchoolQuery.isLoading}
        infiniteScroll={{
          fetchNextPage: groupsBySchoolQuery.fetchNextPage,
          hasNextPage: groupsBySchoolQuery.hasNextPage,
          isFetchingNextPage: groupsBySchoolQuery.isFetchingNextPage
        }}
      />
    </TableContainerStyles>
  )
}
