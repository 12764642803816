import React, { createContext, useContext, useMemo } from 'react'
import { I18nextProvider, useTranslation } from 'react-i18next'
import { LANGUAGES_DATA, i18n as i18nProviderProps } from 'i18n'
import { LanguageContextTypes } from './Language.types'

export const LanguageContext = createContext<LanguageContextTypes>(
  {} as LanguageContextTypes
)

export const useLanguage = () => useContext(LanguageContext)

export const LanguageContextWrapper: React.FC = ({ children }) => {
  const { i18n } = useTranslation()

  const langIndex = LANGUAGES_DATA.findIndex(
    (lang) => lang.iso639 === i18n.language
  )

  const language = LANGUAGES_DATA[langIndex] || LANGUAGES_DATA[2]

  const withoutAccent = language.withAccent
    ?.normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

  const value = useMemo(
    () => ({
      changeLanguage: i18n.changeLanguage,
      language: {
        ...language,
        withoutAccent
      }
    }),
    [i18n.language, language]
  )

  return (
    <I18nextProvider i18n={i18nProviderProps}>
      <LanguageContext.Provider value={value}>
        {children}
      </LanguageContext.Provider>
    </I18nextProvider>
  )
}
