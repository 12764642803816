/* eslint-disable react/no-unstable-nested-components */
import { Box, Typography } from '@mui/material'
import {
  Button,
  DisplayHeaderComponent,
  TableComponent
} from 'common/components'
import { AvatarComponent } from 'components/avatar/Avatar'
import { useOrientation } from 'hooks'
import React from 'react'
import { TFuncKey, useTranslation } from 'react-i18next'
import { StudentTypes } from 'services'
import friendsSrc from 'assets/images/pages/groups/friends.png'
import { createColumnHelper } from '@tanstack/react-table'
import { useGroupDialogContext } from '../../../../context'
import { GroupTablePropTypes } from './GroupTable.types'

export const SelectedStudentsTableComponent: React.FC<GroupTablePropTypes> = ({
  data,
  isLoading
}) => {
  const { t } = useTranslation()
  const {
    isCreation,
    isEdition,
    setSelectedStudentsToCreateUserGroup,
    setSelectedStudentsToUpdateUserGroup
  } = useGroupDialogContext()

  const columnHelper = createColumnHelper<StudentTypes>()
  const { isLandscape } = useOrientation()

  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <DisplayHeaderComponent title={t('pages.group.modal.table.name')} />
      ),
      cell: ({ row }) => {
        const student = row.original
        return (
          <Box display='flex' gap={1} alignItems='center' p={1}>
            <AvatarComponent name={student.name} />
            <Typography variant='body2' sx={{ wordBreak: 'break-word' }}>
              {student.name}
            </Typography>
          </Box>
        )
      }
    }),
    columnHelper.display({
      id: 'actions',
      header: () => null,
      maxSize: 50,
      cell: ({ row }) => {
        return (
          <Button
            variant='text'
            color='secondary'
            type='button'
            value={t('general.actions.remove')}
            onClick={() => {
              const rowId = row.original?.id
              if (rowId) {
                if (isCreation) {
                  setSelectedStudentsToCreateUserGroup((cur) =>
                    cur.filter((student) => student.id !== rowId)
                  )
                }

                if (isEdition) {
                  setSelectedStudentsToUpdateUserGroup((cur) =>
                    cur.filter((student) => student.id !== rowId)
                  )
                }
              }
            }}
          />
        )
      }
    })
  ]

  const emptyBodyTitle: TFuncKey = `pages.group.table.emptyContent.${
    isLandscape ? 'landscape' : 'portrait'
  }.title`

  return (
    <TableComponent
      data={data}
      columns={columns}
      isLoading={isLoading}
      emptyContent={{
        imgSrc: friendsSrc,
        title: t(emptyBodyTitle),
        description: t(
          `pages.group.table.emptyContent.${
            isLandscape ? 'landscape' : 'portrait'
          }.subtitle`
        )
      }}
    />
  )
}
