import React from 'react'
import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { PeopleAlt } from '@mui/icons-material'
import { Trans, useTranslation } from 'react-i18next'

import { DialogBody } from 'common/components'

import { useInfiniteStudentQuery } from 'hooks'
import { useGroupDialogContext } from '../../context'

import { GridContainerStyles } from './GroupDialogContent.styles'
import {
  GroupDialogFormComponent,
  SelectedStudentsTableComponent
} from './components'

export const groupTypographyHeader = (count: number) => {
  const { t } = useTranslation()
  return (
    <Box display='flex' flexDirection='column' gap={1}>
      <Typography fontWeight={(theme) => theme.typography.fontWeightBold}>
        {t('components.modals.editGroup.studentsInThisGroup')}
      </Typography>
      <Typography
        component='label'
        variant='body2'
        display='flex'
        alignItems='center'
        gap={1}
      >
        <PeopleAlt />
        <Trans>
          {t('components.modals.editGroup.studentCount', {
            count
          })}
        </Trans>
      </Typography>
    </Box>
  )
}

export const GroupDialogContentComponent: React.FC = () => {
  const isMobileScreen = useMediaQuery(useTheme().breakpoints.down('md'))
  const {
    selectedStudentsToCreateUserGroup,
    updatedUserGroup,
    createdUserGroup,
    isCustomGroup,
    isEdition,
    isCreation,
    studentQuery
  } = useGroupDialogContext()

  const { data: studentList, isLoading } = useInfiniteStudentQuery({
    enabled: true,
    useParams: false
  })

  const studentEditionList = isEdition ? updatedUserGroup.students : []
  const studentCreationList = isCreation ? createdUserGroup.students : []

  const studentCreateList = studentQuery.data?.map((student) => ({
    ...student,
    isAdded: studentCreationList.includes(student.id)
  }))

  const studentEditList = studentQuery.data?.map((student) => ({
    ...student,
    isAdded: studentEditionList.includes(student.id)
  }))

  const studentData = (isCreation ? studentCreateList : studentEditList) || []

  const dataTable = isCreation
    ? selectedStudentsToCreateUserGroup
    : studentList?.filter((student) =>
        studentEditionList.includes(student.id)
      ) || []

  return (
    <DialogBody dividers>
      <GridContainerStyles isFeideGroup={!isCustomGroup}>
        <GroupDialogFormComponent studentData={studentData} />
        {isCustomGroup && (
          <>
            <Divider orientation={isMobileScreen ? 'horizontal' : 'vertical'} />
            <Box
              display='flex'
              flexDirection='column'
              px={3}
              gap={3}
              overflow='auto'
            >
              <Box pt={2}>{groupTypographyHeader(dataTable.length)}</Box>
              <Box position='sticky' top={0}>
                <SelectedStudentsTableComponent
                  isLoading={isLoading}
                  data={dataTable}
                />
              </Box>
            </Box>
          </>
        )}
      </GridContainerStyles>
    </DialogBody>
  )
}
