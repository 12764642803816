import React from 'react'
import { CircularProgress, Typography } from '@mui/material'

import {
  ProgressLabelStyles,
  ProgressStyles,
  ProgressWrapperStyles
} from './Progress.styles'
import { ProgressPropTypes } from './Progress.types'

export const ProgressComponent: React.FC<ProgressPropTypes> = ({
  value = 0,
  loading,
  fullWidth
}) => {
  const parsedValue = Number(value)
  return (
    <ProgressWrapperStyles
      className='ProgressComponentWrapper'
      fullWidth={fullWidth}
    >
      <ProgressStyles
        variant={loading ? 'indeterminate' : 'determinate'}
        value={parsedValue}
      />
      <ProgressLabelStyles>
        <Typography
          fontWeight={(theme) => theme.typography.fontWeightBold}
          color={parsedValue === 100 ? 'secondary.main' : undefined}
        >
          {loading ? (
            <CircularProgress size={12} color='inherit' thickness={8} />
          ) : (
            `${Math.round(parsedValue)}%`
          )}
        </Typography>
      </ProgressLabelStyles>
    </ProgressWrapperStyles>
  )
}
