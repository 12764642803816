import {
  PaginatedApiParamsTypes,
  PaginatedResponse
} from 'services/api/api.types'
import { makeQueryParams } from 'utils'
import { GameModeTypes, api } from '../../api'
import { GameTypes } from './Games.types'

type _GameMinimalTypes = Pick<
  GameTypes,
  'id' | 'name' | 'image' | 'shortDescription' | 'planet' | 'tags' | 'isActive'
>

type _GameModeMinimalTypes = Pick<
  GameModeTypes,
  'playTime' | 'mode' | 'boards' | 'isPremium'
>

interface _GameMinimalWithGameModeTypes extends _GameMinimalTypes {
  gameModes: _GameModeMinimalTypes[]
}

const getAllGames = async (params: PaginatedApiParamsTypes = {}) => {
  const response = await api.get<PaginatedResponse<_GameMinimalTypes>>(
    `games${makeQueryParams(params)}`
  )

  return response.data
}

const getAllGamesWithGameModes = async (params: PaginatedApiParamsTypes) => {
  const queryParams = { ...params, withModes: true }

  const response = await api.get<
    PaginatedResponse<_GameMinimalWithGameModeTypes>
  >(`games${makeQueryParams(queryParams)}`)

  return response.data
}

export default {
  get: getAllGames,
  withModes: {
    get: getAllGamesWithGameModes
  }
}
