import React from 'react'
import { useTranslation } from 'react-i18next'
import { Page, View, Document } from '@react-pdf/renderer'

import { ElementType } from 'utils/generator/calc/calc'

import { MuiThemeWrapper } from 'common/theme'
import { WorksheetPropTypes } from './Worksheet.types'
import { page, body } from './Worksheet.styles'
import {
  PDFFooterComponent,
  PDFHeaderComponent,
  PDFItemComponent
} from '../components'

export const WorksheetPDF: React.FC<WorksheetPropTypes> = ({ output }) => {
  if (!output) return null

  const { i18n } = useTranslation()

  const { document: PDFDocument, options } = output
  const { page: PDFPage, title, ...PDFDocumentProps } = PDFDocument
  const {
    activities: listOfActivities,
    answers: listOfAnswers,
    ...PDFPageProps
  } = PDFPage

  const shuffleArray = (arr: Array<unknown>) => {
    return arr.sort(() => Math.random() - 0.5)
  }

  const numberArray = Array.from(Array(89).keys()).map((x) => x + 10)
  const shuffledNumberArray = shuffleArray(numberArray)

  const letterCharCodes = Array.from(Array(26)).map((_, i) => i + 65)
  const alphabetArray = letterCharCodes.map((x) => String.fromCharCode(x))
  const shuffledAlphabetArray = shuffleArray(alphabetArray)

  const getRandomizedCode = (index: number) => {
    return `#${shuffledAlphabetArray[index]}${shuffledNumberArray[index]}`
  }

  const renderPDF = ({
    pages,
    activityIndex,
    isAnswerSheet = false
  }: {
    pages: ElementType[][][]
    activityIndex: number
    isAnswerSheet?: boolean
  }) => {
    return pages.map((exercises, pageIndex) => (
      <Page
        {...PDFPageProps}
        key={`page-${activityIndex.toString()}-${pageIndex.toString()}`}
        style={page.default}
      >
        <PDFHeaderComponent
          title={title}
          variant={isAnswerSheet ? 'answerSheet' : 'default'}
        />
        <View style={body.default}>
          {exercises.map((exercise, exerciseIndex) => (
            <PDFItemComponent
              exercise={exercise}
              index={exerciseIndex}
              options={options}
              isAnswerSheet={isAnswerSheet}
            />
          ))}
        </View>
        <PDFFooterComponent
          index={pageIndex}
          answerSheetCode={getRandomizedCode(activityIndex)}
        />
      </Page>
    ))
  }

  return (
    <MuiThemeWrapper>
      <Document {...PDFDocumentProps} title={title} language={i18n.language}>
        {listOfActivities.map((pages, activityIndex) =>
          renderPDF({ pages, activityIndex })
        )}
        {listOfAnswers.map((pages, activityIndex) =>
          renderPDF({ pages, activityIndex, isAnswerSheet: true })
        )}
      </Document>
    </MuiThemeWrapper>
  )
}
