import teacherIdMethods from './Teachers.id'
import teacherMethods from './Teachers'
import teacherBalanceMethods from './Teachers.me.balance'
import teacherRevokeLicenses from './Teachers.revokeLicenses'
import teacherAssignLicenses from './Teachers.assignLicenses'

export const TeacherServices = {
  ...teacherMethods,
  id: teacherIdMethods,
  me: {
    balance: teacherBalanceMethods
  },
  revokeLicenses: teacherRevokeLicenses,
  assignLicenses: teacherAssignLicenses
}

export * from './Teachers.types'
