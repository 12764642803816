import React from 'react'

import { TabsStyles } from './Tabs.styles'
import { TabsPropTypes } from './Tabs.types'

export const TabsComponent: React.FC<TabsPropTypes> = ({
  children,
  value,
  ...rest
}) => {
  return (
    <TabsStyles
      value={value}
      indicatorColor='primary'
      textColor='primary'
      variant={rest?.variant || 'scrollable'}
      scrollButtons='auto'
      allowScrollButtonsMobile
      {...rest}
    >
      {children}
    </TabsStyles>
  )
}

export * from './Tabs.types'
