import React from 'react'
import { useNavigate } from 'react-router-dom'

import { AccessDeniedPropTypes } from './AccessDenied.types'

export const AccessDeniedComponent: React.FC<AccessDeniedPropTypes> = ({
  redirectPath
}) => {
  const navigate = useNavigate()
  return (
    <div>
      <p>!#Access denied</p>
      <button type='button' onClick={() => navigate(redirectPath)}>
        !#Go to login
      </button>
    </div>
  )
}
