import styled from 'styled-components'
import { Colors } from 'styles'
import { StatusTypes } from 'components'
import { StatusIndicatorPropTypes } from './StatusIndicator.types'

export const StatusIndicatorStyles = styled.div<StatusIndicatorPropTypes>`
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  box-sizing: content-box;
  white-space: nowrap;

  ${({ status }) => {
    if (status === StatusTypes.ACTIVE) {
      return `
        background: ${Colors.progressGreen};
        border: 0.1rem solid ${Colors.successDark};
      `
    }
    if (status === StatusTypes.PLANNED) {
      return `
        background: ${Colors.warningLight};
        border: 0.1rem solid ${Colors.warningDark};
      `
    }
    if (status === StatusTypes.COMPLETED) {
      return `
        background: ${Colors.dangerLight};
        border: 0.1rem solid ${Colors.dangerDark};
      `
    }
    return ''
  }}
`
