import { AxiosError } from 'axios'
import { useFeedback } from 'context'
import { ApiResponseTypes, ReportsServices } from 'services'
import { useInfiniteQuery } from 'react-query'

import { useParamsSelector } from 'hooks'
import { getNextPageParam } from 'utils/getNextParam'

import { useParams } from 'react-router-dom'
import { UseQueryOptionsTypes } from '../../queries.types'

export const useInfiniteGameModesByAssignmentQuery = (
  options: UseQueryOptionsTypes | undefined = {}
) => {
  const params = useParamsSelector()

  const queryParams = options.useParams ? params.objParams : {}

  const { assignmentId } = useParams()

  const { alert } = useFeedback()

  const { data, ...infiniteQuery } = useInfiniteQuery(
    ['v2/reports/assignments/assignmentId/gameModes', queryParams],
    (paginatedParams) =>
      ReportsServices.assignments.id.gameModes.paginated({
        params: {
          page: paginatedParams.pageParam,
          assignmentId,
          userGroupId: queryParams.userGroupId,
          search: queryParams.search
        }
      }),
    {
      keepPreviousData: true,
      getNextPageParam,
      onError: (error: AxiosError<ApiResponseTypes<unknown>>) => {
        alert.showError(error.response?.data.message || error.message)
      },
      cacheTime: 0,
      enabled: options.enabled
    }
  )

  return {
    data: data?.pages.flatMap((page) => page.data) || [],
    dataCount: data?.pages.flatMap((page) => page.pagination.dataCount)[0] || 0,
    ...infiniteQuery
  }
}
