import React, { lazy } from 'react'
import { Route } from 'react-router-dom'

import { ROUTES } from 'router'

const NewAssignment = lazy(
  () => import('views/assignment/subViews/new/NewAssignment')
)

export const renderCreateAssignmentRoutes = () => {
  const { CREATE_ASSIGNMENT } = ROUTES

  return (
    <Route path={CREATE_ASSIGNMENT}>
      <Route index element={<NewAssignment />} />
    </Route>
  )
}
