import { ApiResponseTypes } from 'services/api/api.types'
import { api } from '../../api'
import { TopicTypes } from './Topics.types'

const getAllTopics = async () => {
  return (await api.get<ApiResponseTypes<TopicTypes[]>>('/topics')).data.data
}

export default {
  get: getAllTopics
}
