import { makeQueryParams } from 'utils/makeQueryParams'
import { api } from '../../api'
import {
  GameLogIdPaginatedPropTypes,
  PaginatedGameLogResponseTypes
} from './GameLog.types'

const paginated = async ({
  params: { page = 1, ...restOfParams },
  assignmentId
}: GameLogIdPaginatedPropTypes) => {
  return api
    .get<PaginatedGameLogResponseTypes>(
      `game-logs/report/assignment/${assignmentId}${makeQueryParams({
        selectedPage: page,
        ...restOfParams
      })}`
    )
    .then((response) => response.data)
}

export default { paginated }
