import { api, ApiResponseTypes } from 'services'
import { AssignmentPayloadTypes, AssignmentTypes } from './Assignments.types'

const getAssignment = async (id: number) => {
  const { data: response } = await api.get<ApiResponseTypes<AssignmentTypes>>(
    `/assignments/assignment/${id}`
  )

  return response.data
}

const putAssignment = async (
  id: number,
  payload: Partial<AssignmentPayloadTypes>
) => {
  const { data: response } = await api.put<ApiResponseTypes<AssignmentTypes>>(
    `/assignments/${id}`,
    payload
  )

  return response.data
}

const deleteAssignment = async (id: number) => {
  const { data: response } = await api.delete<
    ApiResponseTypes<AssignmentTypes>
  >(`/assignments/${id}`)

  return response.data
}

export default {
  get: getAssignment,
  put: putAssignment,
  delete: deleteAssignment
}
