import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useActionState, useDisclosure } from 'hooks'
import {
  TimePickerComponent,
  DatePickerComponent,
  HelperTextComponent
} from '..'

import { PickerPropTypes } from './Picker.types'
import { AdornmentButtonStyles, PickerContainerStyles } from './Picker.styles'

export const PickerComponent: React.FC<PickerPropTypes> = ({
  name,
  pickerType,
  helperText,
  error,
  success,
  focused,
  hovered,
  ...rest
}) => {
  const { t } = useTranslation()

  const inputRef = useRef<HTMLInputElement | null>(null)
  const { isOpen, onToggle, ...dialogHandlers } = useDisclosure()

  const { hasError, hasFocus, hasHover, hasSuccess } = useActionState(
    { error, focused, hovered, success },
    inputRef
  )

  const Picker = {
    DatePicker: DatePickerComponent,
    TimePicker: TimePickerComponent
  }[pickerType]

  const label = {
    start: t('components.picker.from'),
    end: t('components.picker.to')
  }

  return (
    <>
      <PickerContainerStyles
        error={hasError}
        success={hasSuccess}
        focused={hasFocus}
        hovered={hasHover}
        tabIndex={-1}
      >
        <AdornmentButtonStyles
          error={hasError}
          success={hasSuccess}
          focused={hasFocus}
          hovered={hasHover}
          onClick={onToggle}
          tabIndex={0}
          type='button'
        >
          {name.includes('start') ? label.start : label.end}
        </AdornmentButtonStyles>
        <Picker
          {...rest}
          {...dialogHandlers}
          name={name}
          inputRef={inputRef}
          disableOpenPicker
          open={isOpen}
        />
      </PickerContainerStyles>
      {helperText && (
        <HelperTextComponent id={name} error={hasError} success={hasSuccess}>
          {helperText || t('general.status.error.somethingWentWrong')}
        </HelperTextComponent>
      )}
    </>
  )
}

export * from './Picker.types'
