import { TableCell, Typography, Box, TableRow } from '@mui/material'
import { LazyImageComponent } from 'components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import notFoundImage from 'assets/images/empty-content/table-not-found.svg'
import { TableRowEmptyContentPropTypes } from './TableRowEmptyContent.types'

export const TableRowEmptyContentComponent: React.FC<
  TableRowEmptyContentPropTypes
> = ({ tableCellProps, description, imgSrc = notFoundImage, title }) => {
  const { t } = useTranslation()

  return (
    <TableRow>
      <TableCell {...tableCellProps}>
        <Box
          display='flex'
          flexDirection='column'
          gap={2}
          justifyContent='center'
          alignItems='center'
          p={3}
        >
          <LazyImageComponent
            src={imgSrc}
            alt={t('components.table.notFoundImageAlt')}
            style={{ maxWidth: '22rem' }}
          />
          <Box
            display='flex'
            flexDirection='column'
            gap={1}
            justifyContent='center'
            alignItems='center'
          >
            <Typography
              variant='h6'
              fontWeight={(theme) => theme.typography.fontWeightBold}
            >
              {title || t('components.table.noResultsFound')}
            </Typography>
            <Typography variant='body2'>
              {description || t('components.table.noResultsFoundDescription')}
            </Typography>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  )
}

export * from './TableRowEmptyContent.types'
