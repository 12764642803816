import React from 'react'
import { View, Text } from '@react-pdf/renderer'

import { footer } from './Footer.styles'
import { PDFFooterPropTypes } from './Footer.types'

export const PDFFooterComponent: React.FC<PDFFooterPropTypes> = ({
  answerSheetCode,
  index
}) => {
  return (
    <View style={footer.default}>
      <Text style={footer.paginationText}>{`00${index + 1}`.slice(-2)}</Text>
      <Text style={footer.answerSheetCode}>{answerSheetCode}</Text>
    </View>
  )
}
