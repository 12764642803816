import { StyleSheet } from '@react-pdf/renderer'
import { palette } from 'common/theme'

export const item = StyleSheet.create({
  default: {
    padding: 20,
    margin: '0.5px',
    backgroundColor: palette.common.white,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexGrow: 1,
    flexShrink: 0
  },
  sixItems: {
    height: `${99.5 / 3}%`,
    flexBasis: '35%'
  },
  twentyFiveItems: {
    height: `${99.3 / 5}%`,
    flexBasis: '19%'
  },
  fiftyItems: {
    height: `${88.7 / 9}%`,
    flexBasis: '19%'
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 0,
    top: 0,
    left: 0,
    width: 20,
    height: 20,
    backgroundColor: palette.primary[100],
    borderBottomRightRadius: 8,
    border: `1px solid ${palette.common.black}`,
    transform: 'translate(-1px, -1px)'
  },
  paginationText: {
    position: 'relative',
    zIndex: 1,
    fontSize: 12,
    color: palette.common.black,
    fontWeight: 'bold'
  },
  exercise: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  exerciseItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  exerciseText: {
    fontWeight: 'normal',
    color: palette.common.black,
    marginRight: '2px'
  },
  nullElement: {
    height: 25,
    width: 40,
    borderBottom: `1px solid ${palette.common.black}`,
    marginRight: '2px'
  },
  answerElement: {
    fontWeight: 'normal',
    color: 'red',
    marginRight: '2px'
  }
})
