import React from 'react'
import { View, Text } from '@react-pdf/renderer'

import { ElementType } from 'utils'

import { VerticalItemPropTypes } from './VerticalItem.types'
import { verticalItem } from './VerticalItem.styles'

export const VerticalItem: React.FC<VerticalItemPropTypes> = ({
  exercise,
  hideResult
}) => {
  const getElement = (elementType: ElementType['type']) => {
    const indexOfValue = exercise.findIndex((el) => el.type === elementType)
    return exercise[indexOfValue]
  }

  const operation = getElement('operation')
  const n1 = getElement('n1')
  const n2 = getElement('n2')
  const result = getElement('result')

  return (
    <View style={verticalItem.default}>
      <View style={verticalItem.expression}>
        <View style={verticalItem.operation}>
          <Text>{operation.value}</Text>
        </View>
        <View style={verticalItem.numbers}>
          <Text>{n1.value}</Text>
          <Text>{n2.value}</Text>
        </View>
      </View>
      {!hideResult && <Text style={verticalItem.result}>{result.value}</Text>}
    </View>
  )
}
