import styled from 'styled-components'

export const WarningContainerStyles = styled.div`
  background: ${({ theme }) => theme.palette.common.white};
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const WarningStyles = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  padding: 2rem;
  border-radius: 0.5rem;
  gap: 1.6rem;

  background: ${({ theme }) => theme.palette.secondary[50]};
`
