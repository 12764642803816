import { useEffect } from 'react'
import { useLocation } from 'react-router'

export interface UsePageTitlePropTypes {
  value?: string
}

export interface UsePageTitleOptions {
  preventMetaTagsUpdate?: boolean
}

export const usePageTitle = (
  props: UsePageTitlePropTypes,
  options?: UsePageTitleOptions
) => {
  const { preventMetaTagsUpdate } = options || {}
  const { value = 'Teacher panel' } = props

  const location = useLocation()

  useEffect(() => {
    if (!preventMetaTagsUpdate) {
      document.title = value
    }
  }, [location, preventMetaTagsUpdate, value])
}
