import React, { lazy } from 'react'
import { Navigate, Route } from 'react-router-dom'
import { ROUTES } from 'router'

const Download = lazy(
  () => import('views/pdfGenerator/subViews/download/Download')
)

const Multiplication = lazy(
  () =>
    import(
      'views/pdfGenerator/subViews/generate/operations/multiplication/Multiplication'
    )
)

const Division = lazy(
  () =>
    import('views/pdfGenerator/subViews/generate/operations/division/Division')
)

const AdditionAndSubtraction = lazy(
  () =>
    import(
      'views/pdfGenerator/subViews/generate/operations/additionAndSubtraction/AdditionAndSubtraction'
    )
)

const PdfGenerator = lazy(() => import('views/pdfGenerator/PdfGenerator'))

export const renderPdfGeneratorRoutes = () => {
  const {
    ID,
    TYPE,
    MULTIPLICATION,
    DIVISION,
    FRACTION,
    ADDITION_AND_SUBTRACTION,
    PDF_GENERATOR
  } = ROUTES

  return (
    <>
      <Route path={PDF_GENERATOR} element={<PdfGenerator />}>
        <Route index element={<Navigate to={ADDITION_AND_SUBTRACTION} />} />
        <Route
          path={ADDITION_AND_SUBTRACTION}
          element={<AdditionAndSubtraction />}
        />
        <Route path={DIVISION} element={<Division />} />
        <Route path={FRACTION} element={null} />
        <Route path={MULTIPLICATION} element={<Multiplication />} />
      </Route>
      <Route path={`${PDF_GENERATOR}/${TYPE}/${ID}`} element={<Download />} />
    </>
  )
}
