import styled, { css } from 'styled-components'
import { DialogActions } from '@mui/material'
import { ModalFooterPropTypes } from 'components'

export const ModalFooterStyles = styled(DialogActions)<ModalFooterPropTypes>`
  padding: 2rem 3rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  & > :only-child {
    margin: 0 auto;
  }

  ${({ sticky, backgroundColor }) =>
    sticky === 'bottom' &&
    css`
      background-color: ${backgroundColor};
      position: sticky;
      bottom: 0;
    `}
`

export const ModalFooterActionButtonsStyles = styled.div`
  display: flex;
  gap: 2rem;
`
