import {
  Alert,
  AlertTitle,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  FormItemContentStyles,
  FormItemHeaderStyles,
  FormItemStyles,
  FormItemBodyStyles
} from './FormItem.styles'
import { FormItemPropTypes } from './FormItem.types'

export const FormItemComponent: React.FC<FormItemPropTypes> = ({
  icon,
  title,
  disableInvisibleGrid = false,
  children,
  errors,
  errorBoxTitle
}) => {
  const { t } = useTranslation()
  const isMobileScreen = useMediaQuery(useTheme().breakpoints.down('md'))

  const errorList = errors?.filter((error) => error !== undefined)

  return (
    <FormItemStyles>
      <FormItemContentStyles>
        {!disableInvisibleGrid && (
          <FormItemHeaderStyles disableInvisibleGrid={isMobileScreen}>
            {icon && (
              <SvgIcon
                component={icon}
                color='primary'
                fontSize={isMobileScreen ? 'small' : 'medium'}
              />
            )}
            {title && <Typography>{title}</Typography>}
          </FormItemHeaderStyles>
        )}
        <FormItemBodyStyles>{children}</FormItemBodyStyles>
      </FormItemContentStyles>

      {Boolean(errorList?.length) && (
        <Alert severity='error'>
          <AlertTitle>
            <strong>
              {t(errorBoxTitle || 'general.errors.shouldNotBeEmpty')}
            </strong>
          </AlertTitle>
          {errorList?.map((error, i) => (
            <Typography key={`form-error-${error}-${i.toString()}`}>
              {error}
            </Typography>
          ))}
        </Alert>
      )}
    </FormItemStyles>
  )
}
