import { makeQueryParams } from 'utils/makeQueryParams'
import { api } from '../../../api'
import {
  PaginatedReportsResponseTypes,
  ReportSearchPropTypes
} from '../Reports.types'
import { StudentReportPropTypes } from './students.types'

const allStudents = async ({
  params: { page = 1, ...rest }
}: ReportSearchPropTypes) => {
  return api
    .get<PaginatedReportsResponseTypes<StudentReportPropTypes>>(
      `v2/reports/students${makeQueryParams({
        selectedPage: page,
        ...rest
      })}`
    )
    .then((response) => response.data)
}

export default allStudents
