import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { PopUpComponent } from 'components'
import { useFeedback } from 'context'

import { useCallbackPrompt, usePageTitle, useParamsSelector } from 'hooks'
import { LayoutHeaderComponent } from './components'

import { LayoutStyles } from './Layout.styles'
import { LayoutPropTypes } from './Layout.types'
import { LayoutSkeleton } from './Layout.skeleton'

export const LayoutComponent: React.FC<LayoutPropTypes> = ({
  children,
  header,
  preventEasyLeave = false,
  preventMetaTagsUpdate = false,
  hideSubtitle,
  hideTitle,
  hideHeader,
  loading
}) => {
  const { t } = useTranslation()

  const params = useParamsSelector()
  const { dialog } = useFeedback()

  const searchValue = params.get('search')

  const searchMetaTitle = searchValue
    ? `${t('general.state.searchResultsFor', {
        value: searchValue
      })} - `
    : ''

  const { hideBreadcrumbs = false } = header
  const metaTitle = `${header.title} - ${header.subtitle} - ${searchMetaTitle}Teacher Panel`

  usePageTitle({ value: metaTitle }, { preventMetaTagsUpdate })

  const { cancelNavigation, confirmNavigation, showPrompt } =
    useCallbackPrompt(preventEasyLeave)

  const renderAlert = () => {
    dialog.show({
      content: {
        align: 'flex-start',
        title: t('general.state.quitEditing'),
        subtitle: t('general.errors.notSaved'),
        variant: 'light',
        transColor: 'red',
        footer: {
          align: 'flex-end',
          visible: true,
          buttons: [
            {
              value: t('general.actions.keepEditing'),
              onClick: cancelNavigation,
              variant: 'outlined',
              color: 'secondary'
            },
            {
              value: t('general.actions.quit'),
              onClick: confirmNavigation,
              variant: 'contained',
              color: 'secondary'
            }
          ]
        }
      }
    })
  }

  useEffect(() => {
    if (showPrompt) {
      renderAlert()
    } else {
      dialog.handlers.onClose()
    }
  }, [showPrompt])

  if (loading)
    return (
      <LayoutSkeleton hideBreadcrumbs={hideBreadcrumbs}>
        {children}
      </LayoutSkeleton>
    )

  return (
    <LayoutStyles>
      {!hideHeader && (
        <LayoutHeaderComponent
          {...header}
          hideBreadcrumbs={hideBreadcrumbs}
          hideSubtitle={hideSubtitle}
          hideTitle={hideTitle}
        />
      )}
      {children}
      {dialog.handlers.isOpen && (
        <PopUpComponent
          {...dialog.content}
          open
          onClose={dialog.handlers.onClose}
          zIndexPriority='high'
          title={dialog.content.title ? String(dialog.content.title) : ''}
        />
      )}
    </LayoutStyles>
  )
}

export * from './Layout.types'
