import assignmentMethods from './assignments'
import paginatedGameModes from './gameModes/gameModes.paginated'

export const StudentIdAssignmentServices = {
  id: {
    gameModes: {
      paginated: paginatedGameModes
    }
  },
  ...assignmentMethods
}
