import { AxiosError } from 'axios'
import { useFeedback } from 'context'
import { useQuery } from 'react-query'
import { ApiResponseTypes, UserGroupServices } from 'services'

export const useUserGroupQuery = () => {
  const { alert } = useFeedback()

  const { data = [], ...rest } = useQuery(
    ['/user-groups'],
    () => UserGroupServices.get(),
    {
      onError: (error: AxiosError<ApiResponseTypes<unknown>>) => {
        alert.showError(error.response?.data.message || error.message)
      }
    }
  )

  return { data, ...rest }
}

export * from './useUserGroupQuery.types'
