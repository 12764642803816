import styled, { css } from 'styled-components'

export const ModeCardStyles = styled.div<{
  isSelected?: boolean
  isDisabled?: boolean
}>`
  display: flex;
  flex-direction: column;

  border-radius: 0.8rem;

  background: ${({ theme }) => theme.palette.common.white};

  border: 0.1rem solid ${({ theme }) => theme.palette.grey[100]};
  outline: 0.1rem solid transparent;

  transition: all 0.3s ease-in-out;

  :hover {
    box-shadow: 0 0.3rem 2rem ${({ theme }) => theme.palette.grey[50]};
  }

  ${({ isDisabled, theme }) => {
    if (isDisabled) {
      return css`
        background: ${theme.palette.grey[100]};
        border-color: ${theme.palette.grey[200]};
        cursor: not-allowed;

        .MuiButtonBase-root {
          pointer-events: none;
        }
        h4,
        svg {
          color: ${theme.palette.grey[400]};
        }
        .MuiChip-root {
          background: ${theme.palette.grey[200]};
        }
      `
    }
    return ''
  }}
  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 0.1rem solid ${({ theme }) => theme.palette.secondary.main};
      outline: 0.1rem solid ${({ theme }) => theme.palette.secondary.main};
    `}
`
