import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import { GroupContextWrapper } from 'context'
import { ContextRoute, ROUTES } from 'router'

const Group = lazy(() => import('views/group/Group'))

export const renderGroupRoutes = () => {
  const { GROUPS } = ROUTES

  return (
    <Route
      path={GROUPS}
      element={<ContextRoute wrapper={GroupContextWrapper} />}
    >
      <Route index element={<Group />} />
    </Route>
  )
}
