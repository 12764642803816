import React, { useEffect, useState } from 'react'
import { Box, Divider, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { SearchComponent, SelectComponent } from 'components'
import { getSelectOptionsData } from 'utils'
import { StudentServices } from 'services'
import { useParamsSelector } from 'hooks'

import { InfiniteButtonComponent } from 'common/components'
import { groupTypographyHeader } from '../../GroupDialogContent'

import { StudentItemComponent } from '../../..'
import { useGroupDialogContext } from '../../../../context'
import { StackStyles } from '../../../../GroupDialog.styles'

import { FormContainerStyles } from './GroupDialogForm.styles'
import { GroupDialogFormPropTypes } from './GroupDialogForm.types'

const RenderStudentCount: React.FC<{ count: number }> = ({ count }) => {
  const { t } = useTranslation()
  return (
    <Typography variant='body2' color='grey.300' textAlign='center'>
      {t('components.modals.editGroup.showingNStudents', {
        count
      })}
    </Typography>
  )
}

export const GroupDialogFormComponent: React.FC<GroupDialogFormPropTypes> = ({
  studentData
}) => {
  const { t } = useTranslation()

  const [searchedField, setSearchedField] = useState<string | null>(null)
  const [filteredGroupId, setFilteredGroupId] = useState<string | null>(null)
  const params = useParamsSelector()

  const {
    isCustomGroup,
    isFeideGroup,
    isCreation,
    isEdition,
    setSelectedStudentsToCreateUserGroup,
    setSelectedStudentsToUpdateUserGroup,
    updatedUserGroup,
    studentQuery
  } = useGroupDialogContext()

  const { GROUPS_SELECT_DATA } = getSelectOptionsData()

  const feideStudentsData = isEdition
    ? studentData?.filter((student) =>
        updatedUserGroup.students.includes(student.id)
      )
    : []

  const filteredStudentData = studentData.filter((student) => {
    const userGroupStudentList = student.userGroups.flatMap((userGroup) =>
      String(userGroup.id)
    )
    if (filteredGroupId) return userGroupStudentList.includes(filteredGroupId)
    return student
  })

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchedField) {
        params.add([{ key: 'searchStudent', value: searchedField }])
      } else {
        params.delete('searchStudent')
      }
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [searchedField])

  return (
    <FormContainerStyles>
      <Box display='flex' flexDirection='column' gap={2} p={3}>
        {isCustomGroup && (
          <Box width='100%' display='flex' flexDirection='column' gap={2}>
            <Typography
              component='label'
              variant='subtitle1'
              fontWeight={(theme) => theme.typography.fontWeightBold}
            >
              {t('components.modals.editGroup.findStudentsToAdd')}
            </Typography>
            <SelectComponent
              {...GROUPS_SELECT_DATA}
              fullWidth
              placeholder={t(
                'components.modals.editGroup.groupSelectPlaceholder'
              )}
              onChange={(item) => {
                const id = item.value
                setFilteredGroupId(id)
              }}
              onClearClick={() => {
                setFilteredGroupId('')
              }}
            />
          </Box>
        )}
        {isFeideGroup && groupTypographyHeader(feideStudentsData.length)}
        <SearchComponent
          name='modal-group-search'
          fullWidth
          placeholder={t('components.search.placeholder.default')}
          value={searchedField}
          onChange={(e) => setSearchedField(e.currentTarget.value)}
          onClearClick={() => {
            setSearchedField('')
            params.delete('searchStudent')
          }}
        />
        <Divider />
        <StackStyles isFeideGroup={isFeideGroup}>
          {isCustomGroup &&
            filteredStudentData.map((student) => (
              <StudentItemComponent
                key={student.id}
                student={student}
                isAdded={student.isAdded}
                onAddClick={async () => {
                  if (!student.isAdded) {
                    const studentResp = await StudentServices.id.get(student.id)

                    if (isCreation) {
                      setSelectedStudentsToCreateUserGroup((cur) => [
                        ...cur,
                        studentResp
                      ])
                    }

                    if (isEdition) {
                      setSelectedStudentsToUpdateUserGroup((cur) => [
                        ...cur,
                        {
                          ...studentResp,
                          feideId: null
                        }
                      ])
                    }
                  }
                }}
              />
            ))}
          {isFeideGroup &&
            feideStudentsData.map((student) => (
              <StudentItemComponent
                key={student.id}
                student={student}
                hideActionButtons
              />
            ))}

          <InfiniteButtonComponent
            isFetchingNextPage={studentQuery.isFetchingNextPage}
            hasNextPage={studentQuery.hasNextPage}
            fetchNextPage={studentQuery.fetchNextPage}
          />
          {!Number(studentQuery.data?.length) && (
            <RenderStudentCount count={0} />
          )}
        </StackStyles>
      </Box>
      {searchedField &&
        params.get('searchStudent') === searchedField &&
        Boolean(Number(studentQuery.data?.length)) && (
          <Box
            position='sticky'
            bottom={0}
            py={2}
            px={3}
            display='flex'
            justifyContent='center'
            alignItems='center'
            bgcolor='common.white'
            borderTop={({ palette }) => `0.1rem solid ${palette.divider}`}
          >
            <RenderStudentCount
              count={
                isCustomGroup
                  ? Number(studentQuery.data?.length)
                  : feideStudentsData.length
              }
            />
          </Box>
        )}
    </FormContainerStyles>
  )
}
