import React from 'react'
import { FiltersBarDesktopComponent } from './templates'

import { FiltersBarPropTypes } from './FiltersBar.types'

export const FiltersBarComponent: React.FC<FiltersBarPropTypes> = ({
  desktopProps,
  ...rest
}) => {
  return <FiltersBarDesktopComponent {...rest} {...desktopProps} />
}

export * from './FiltersBar.types'
export * from './templates'
