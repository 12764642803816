import { AxiosError } from 'axios'
import { useFeedback } from 'context'
import { ApiResponseTypes, GameModeServices, ModeTypes } from 'services'
import { useQuery } from 'react-query'

export const useGameModeByIdQuery = (gameModeId: ModeTypes['id']) => {
  const { alert } = useFeedback()

  const assignmentByIdQuery = useQuery({
    queryKey: ['/gameModes', gameModeId],
    queryFn: () => GameModeServices.id.get(gameModeId),
    enabled: !!gameModeId,
    onError: (error: AxiosError<ApiResponseTypes<unknown>>) => {
      alert.showError(error.response?.data.message || error.message)
    }
  })

  return assignmentByIdQuery
}
