import { makeQueryParams } from 'utils/makeQueryParams'
import {
  AssignmentsStudentsReportTypes,
  PaginatedReportsResponseTypes,
  ReportSearchPropTypes,
  StudentTypes,
  api
} from 'services'

const allAssignments = async ({
  studentId,
  params: { page = 1, ...rest }
}: { studentId: StudentTypes['id'] } & ReportSearchPropTypes) => {
  const response = await api.get<
    PaginatedReportsResponseTypes<AssignmentsStudentsReportTypes>
  >(
    `v2/reports/students/${studentId}/assignments${makeQueryParams({
      selectedPage: page,
      ...rest
    })}`
  )

  return response.data
}

export default allAssignments
