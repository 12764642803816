import { makeQueryParams } from 'utils/makeQueryParams'
import { api } from '../../api'
import { ApiPaginatedParamTypes } from '../../api.types'
import { PaginatedUserGroupResponse } from './UserGroups.types'

const paginated = async ({
  params: {
    page = 1,
    pageSize = 20,
    orderBy = 'created_at',
    orderType = 'DESC',
    search,
    ...rest
  }
}: ApiPaginatedParamTypes) => {
  return api
    .get<PaginatedUserGroupResponse>(
      `/user-groups/paginated${makeQueryParams({
        selectedPage: page,
        pageSize,
        search,
        orderBy,
        orderType,
        ...rest
      })}`
    )
    .then((response) => response.data)
}

export default {
  paginated
}
