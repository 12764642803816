/* eslint-disable react/no-unstable-nested-components */
import { Box, Link, Typography } from '@mui/material'
import {
  Button,
  DisplayHeaderComponent,
  TableComponent
} from 'common/components'
import { format } from 'date-fns'
import { useInfiniteSchoolsQuery } from 'hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { SchoolTypes } from 'services'
import { createColumnHelper } from '@tanstack/react-table'

export const SchoolsView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const infiniteSchoolsQuery = useInfiniteSchoolsQuery({ useParams: true })
  const columnHelper = createColumnHelper<SchoolTypes>()

  const columns = [
    columnHelper.accessor('name', {
      header: () => (
        <DisplayHeaderComponent
          title={t('pages.license.schoolList.dataList.header.schoolName')}
        />
      ),
      cell: (params) => {
        const school = params.row.original

        return (
          <Link
            component={RouterLink}
            to={String(school.id)}
            underline='none'
            noWrap
            fontWeight='bold'
          >
            {params.getValue()}
          </Link>
        )
      }
    }),
    columnHelper.accessor('assignedLicenses', {
      header: () => (
        <DisplayHeaderComponent
          title={t('pages.license.schoolList.dataList.header.assignedLicenses')}
        />
      ),
      cell: (params) => <Typography>{params.getValue()}</Typography>
    }),
    columnHelper.accessor('expiryDate', {
      header: () => (
        <DisplayHeaderComponent
          title={t('pages.license.schoolList.dataList.header.expiryDate')}
        />
      ),
      cell: (params) => (
        <Typography>
          {params.getValue()
            ? format(new Date(params.getValue()), 'dd/MM/yyyy')
            : '------'}
        </Typography>
      )
    }),
    columnHelper.display({
      id: 'action',
      size: 100,
      header: () => '',
      cell: (params) => {
        const school = params.row.original

        return (
          <Box display='flex' width='100%' justifyContent='flex-end'>
            <Button
              color='secondary'
              variant='contained'
              size='small'
              onClick={() => {
                navigate(String(school.id))
              }}
            >
              {t('pages.license.schoolList.dataList.manageSchool')}
            </Button>
          </Box>
        )
      }
    })
  ]

  return (
    <TableComponent
      data={infiniteSchoolsQuery.data}
      columns={columns}
      footer={{
        totalDataCount: infiniteSchoolsQuery.dataCount
      }}
      isLoading={infiniteSchoolsQuery.isLoading}
      infiniteScroll={{
        fetchNextPage: infiniteSchoolsQuery.fetchNextPage,
        hasNextPage: infiniteSchoolsQuery.hasNextPage,
        isFetchingNextPage: infiniteSchoolsQuery.isFetchingNextPage
      }}
    />
  )
}
