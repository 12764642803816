import { AxiosError } from 'axios'
import { useFeedback } from 'context'
import { ApiResponseTypes, GameServices } from 'services'
import { useInfiniteQuery } from 'react-query'

import { getNextPageParam } from 'utils/getNextParam'
import { useParamsSelector } from 'hooks'
import { UseQueryOptionsTypes } from '../queries.types'

export const useInfiniteGameWithModesQuery = ({
  useParams
}: UseQueryOptionsTypes = {}) => {
  const params = useParamsSelector()

  const queryParams = useParams ? params.objParams : {}

  const { alert } = useFeedback()

  const { data, ...infiniteQuery } = useInfiniteQuery(
    ['/games', queryParams],
    (paginatedParams) =>
      GameServices.withModes.get({
        selectedPage: paginatedParams.pageParam || 1,
        pageSize: 20,
        topicId: queryParams.topic,
        search: queryParams.search
      }),
    {
      keepPreviousData: true,
      getNextPageParam,
      onError: (error: AxiosError<ApiResponseTypes<unknown>>) => {
        alert.showError(error.response?.data.message || error.message)
      }
    }
  )

  return {
    data: data?.pages.flatMap((page) => page.data) || [],
    dataCount: data?.pages.flatMap((page) => page.pagination.dataCount)[0],
    ...infiniteQuery
  }
}
