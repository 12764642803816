import studentIdMethods from './id/students.id'
import studentsPaginated from './students.paginated'
import { StudentIdAssignmentServices } from './id/assignments'
import assignmentsPaginated from './id/assignments/assignments.paginated'

export const StudentsOfReportServices = {
  id: {
    ...studentIdMethods,
    assignments: {
      ...StudentIdAssignmentServices,
      paginated: assignmentsPaginated
    }
  },
  paginated: studentsPaginated
}

export * from './students.types'
