import { AxiosError } from 'axios'
import { useFeedback } from 'context'
import { useInfiniteUserGroupQuery } from 'hooks'
import React, { createContext, useContext, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import {
  PostUserGroupTypes,
  PutUserGroupTypes,
  UserGroupServices,
  UserGroupTypes
} from 'services'
import { GroupContextTypes } from './Group.context.types'

export const GroupContext = createContext<GroupContextTypes>(
  {} as GroupContextTypes
)

export const useGroup = () => useContext(GroupContext)

export const GroupContextWrapper: React.FC = ({ children }) => {
  const [selectedGroup, setSelectedGroup] = useState<UserGroupTypes | null>(
    null
  )

  const { alert } = useFeedback()

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    dataCount,
    refetch
  } = useInfiniteUserGroupQuery({ useParams: true })

  const removeGroupMutation = () =>
    useMutation((groupId: number) => UserGroupServices.id.delete(groupId), {
      onSuccess: () => {
        refetch()
      },
      onError: (error: AxiosError) => {
        alert.showError(error.message)
      }
    })

  const { mutateAsync: updateUserGroupAsync } = useMutation(
    ({
      userGroupId,
      payload
    }: {
      userGroupId: number
      payload: PutUserGroupTypes
    }) => UserGroupServices.id.put(userGroupId, payload),
    {
      mutationKey: 'update-user-group',
      onSuccess: () => {
        refetch()
      }
    }
  )

  const { mutateAsync: createUserGroupAsync } = useMutation(
    (userGroup: PostUserGroupTypes) => UserGroupServices.post(userGroup),
    {
      mutationKey: 'create-user-group',
      onSuccess: () => {
        refetch()
      }
    }
  )

  const userGroupQuery = {
    data,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isLoading,
    dataCount,
    refetch
  }

  const content: GroupContextTypes = useMemo(
    () => ({
      removeGroupMutation,
      selectedGroup,
      setSelectedGroup,
      userGroupQuery,
      updateUserGroupAsync,
      createUserGroupAsync
    }),
    [removeGroupMutation, selectedGroup, userGroupQuery]
  )

  return (
    <GroupContext.Provider value={content}>{children}</GroupContext.Provider>
  )
}

export * from './Group.context.types'
