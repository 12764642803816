import {
  api,
  PaginatedReportsResponseTypes,
  ReportSearchPropTypes
} from 'services/api/api'
import { makeQueryParams } from 'utils/makeQueryParams'
import { AssignmentsByStudentReportTypes } from '../students.types'

const assignmentsByStudent = async ({
  params: { studentId, page, ...rest }
}: ReportSearchPropTypes) => {
  return api
    .get<PaginatedReportsResponseTypes<AssignmentsByStudentReportTypes>>(
      `v2/reports/students/${studentId}/assignments${makeQueryParams({
        selectedPage: page,
        ...rest
      })}`
    )
    .then((response) => response.data)
}

export default {
  assignmentsByStudent
}
