import { Transition } from 'history'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useBlocker } from './useBlocker'

export const useCallbackPrompt = (when: boolean) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [showPrompt, setShowPrompt] = useState(false)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const [lastLocation, setLastLocation] = useState<Transition | null>(null)

  // ! handle blocking when user click on another route prompt will be shown
  const blocker = useCallback((nextLocation: Transition): void => {
    // ! in if condition we are checking next location and current location are equals or not
    if (
      !confirmedNavigation &&
      nextLocation.location.pathname !== location.pathname
    ) {
      setShowPrompt(true)
      setLastLocation(nextLocation)
    }

    nextLocation.retry()
  }, [])

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false)
  }, [])

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false)
    setConfirmedNavigation(true)
  }, [])

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname)

      // ! Clean-up state on confirmed navigation
      setConfirmedNavigation(false)
    }
  }, [confirmedNavigation, lastLocation])

  useBlocker(blocker, when)

  return {
    showPrompt,
    confirmNavigation,
    cancelNavigation
  }
}
