import React, { createContext, useContext, useMemo } from 'react'

import { AssignmentContextTypes } from './Assignment.context.types'

export const AssignmentContext = createContext<AssignmentContextTypes>(
  {} as AssignmentContextTypes
)

export const useAssignment = () => useContext(AssignmentContext)

export const AssignmentContextWrapper: React.FC = ({ children }) => {
  const value = useMemo(() => ({}), [])

  return (
    <AssignmentContext.Provider value={value}>
      {children}
    </AssignmentContext.Provider>
  )
}

export * from './Assignment.context.types'
