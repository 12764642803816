import { useActionState } from 'hooks'
import React, { useRef } from 'react'
import { CheckboxStyles } from './Checkbox.styles'
import { CheckboxPropTypes } from './Checkbox.types'

export const CheckboxComponent: React.FC<CheckboxPropTypes> = ({
  error,
  success,
  hovered,
  focused,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { hasError, hasFocus, hasHover, hasSuccess } = useActionState(
    { error, success, hovered, focused },
    inputRef
  )

  return (
    <CheckboxStyles
      inputRef={inputRef}
      error={hasError}
      focused={hasFocus}
      hovered={hasHover}
      success={hasSuccess}
      size={rest.size || 'small'}
      data-testid='checkboxComponent'
      {...rest}
    />
  )
}

export * from './components'
export * from './Checkbox.types'
