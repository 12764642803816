import React, { createContext, useContext, useMemo } from 'react'
import { useMutation, useQuery } from 'react-query'
import { LicenseServices, StudentServices, TeacherServices } from 'services'
import { useParams } from 'react-router-dom'
import { LicenseContextTypes } from './License.context.types'

const LicenseContext = createContext({} as LicenseContextTypes)

export const useLicense = () => useContext(LicenseContext)

export const LicenseContextWrapper: React.FC = ({ children }) => {
  const { schoolId, groupId } = useParams()

  const balanceQuery = useQuery('/licenseManagement/teacher/me/balance', () =>
    TeacherServices.me.balance.get()
  )

  const assignLicensesToWholeClass = () =>
    useMutation(
      [
        '/licenseManagement/schools/schoolId/user-groups/groupId/assignClass',
        schoolId,
        groupId
      ],
      LicenseServices.schools.id.userGroups.id.put.assignClass,
      { onSuccess: () => balanceQuery.refetch() }
    )

  const revokeAllLicensesFromClass = () =>
    useMutation(
      [
        '/licenseManagement/schools/schoolId/user-groups/groupId/revokeClass',
        schoolId,
        groupId
      ],
      LicenseServices.schools.id.userGroups.id.put.revokeClass,
      { onSuccess: () => balanceQuery.refetch() }
    )

  const revokeLicensesFromTeacher = () =>
    useMutation(
      ['/teachers/revokeLicenses', { method: 'PUT' }],
      TeacherServices.revokeLicenses,
      { onSuccess: () => balanceQuery.refetch() }
    )

  const assignLicensesToTeacher = () =>
    useMutation(
      ['/teachers/assignLicenses', { method: 'PUT' }],
      TeacherServices.assignLicenses,
      { onSuccess: () => balanceQuery.refetch() }
    )

  const assignLicensesToStudent = () =>
    useMutation(
      ['/students/assignLicenses', { method: 'PUT' }],
      StudentServices.assignLicenses,
      { onSuccess: () => balanceQuery.refetch() }
    )

  const revokeLicensesFromStudent = () =>
    useMutation(
      ['/students/revokeLicenses', { method: 'PUT' }],
      StudentServices.revokeLicenses,
      { onSuccess: () => balanceQuery.refetch() }
    )

  const value = useMemo(
    () => ({
      balanceQuery,
      userGroupActions: {
        assignLicensesToWholeClass,
        revokeAllLicensesFromClass
      },
      teacherActions: {
        revokeLicensesFromTeacher,
        assignLicensesToTeacher
      },
      studentActions: {
        assignLicensesToStudent,
        revokeLicensesFromStudent
      }
    }),
    [balanceQuery]
  )

  return (
    <LicenseContext.Provider value={value}>{children}</LicenseContext.Provider>
  )
}
