import gameLogsIdPaginated from './GameLog.id.paginated'
import gameLogIdResume from './GameLog.id.resume'

export const GameLogServices = {
  id: {
    ...gameLogsIdPaginated
  },
  reports: {
    paginated: {
      ...gameLogIdResume
    }
  }
}

export * from './GameLog.types'
