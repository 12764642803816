import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import { ROUTES } from 'router'

const ActivityBank = lazy(() => import('views/activityBank/ActivityBank'))

export const renderActivityBankRoutes = () => {
  const { ACTIVITY_BANK } = ROUTES

  return (
    <Route path={ACTIVITY_BANK}>
      <Route index element={<ActivityBank />} />
    </Route>
  )
}
