import React from 'react'

import { BottomNavigationItemComponent } from './components'

import { BottomNavigationPropTypes } from './BottomNavigation.types'
import { BottomNavigationStyles } from './BottomNavigation.styles'

export const BottomNavigationComponent: React.FC<BottomNavigationPropTypes> = ({
  items
}) => {
  const filteredItems = items.filter(
    (item) => !item.hideElement && item.showInBottomNavigation
  )

  return (
    <BottomNavigationStyles>
      {filteredItems.map((item) => (
        <BottomNavigationItemComponent key={item.id} item={item} />
      ))}
    </BottomNavigationStyles>
  )
}
