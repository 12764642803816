import { Chip } from '@mui/material'
import styled from 'styled-components'

export const GameCardChipListStyles = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  gap: 0.8rem;
  width: auto;
`

export const GameCardChipStyles = styled(Chip)`
  background-color: ${({ theme }) => theme.palette.secondary[50]};
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 0.4rem 0.8rem;

  .MuiChip-label {
    padding: 0;
    font-size: ${({ theme }) => theme.typography.caption.fontSize};
  }
`
