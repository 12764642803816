import { Dialog, DialogContent, IconButton } from '@mui/material'
import styled, { css } from 'styled-components'
import { Colors } from 'styles'
import { from } from 'common/theme'
import { zIndexPriorityTypes } from './Modal.types'

export const ModalStyles = styled(Dialog)<{
  zIndexPriority?: zIndexPriorityTypes
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .MuiDialog-container,
  .MuiDialog-paper {
    width: 100%;
  }
  .MuiDialog-paper {
    overflow: visible;
  }

  ${({ zIndexPriority = 'high' }) =>
    zIndexPriority === 'high' &&
    css`
      z-index: 9999;
    `}

  ${({ zIndexPriority }) =>
    zIndexPriority === 'low' &&
    css`
      z-index: 5;
    `}
  ${({ zIndexPriority }) =>
    zIndexPriority === 'medium' &&
    css`
      z-index: 10;
    `}
`

export const CloseButtonStyles = styled(IconButton)`
  display: none;
  position: absolute;
  right: 0;
  background-color: ${Colors.info};
  padding: 1rem;
  margin-right: -2rem;
  margin-top: -2rem;
  box-shadow: 0 0 0.2rem ${Colors.infoDark}29;
  max-height: 4.4rem;
  transition: all 0.25s ease;
  z-index: 10;

  svg {
    color: ${Colors.white};
  }

  ${from.md} {
    display: initial;
  }

  :hover {
    background-color: ${Colors.infoDark};
    transition: all 0.25s ease;
  }
`

export const ModalContentStyles = styled(DialogContent)`
  padding: 0;
`
