import { t } from 'i18next'
import { OPERATION_KEY } from '../operation/operator.types'
import { SymbolTypes, SYMBOL_KEY } from './symbol.types'

const EQUALS: SymbolTypes = {
  key: SYMBOL_KEY.EQUALS,
  getSymbol: () => t('components.pdfs.symbols.equals.symbol'),
  getName: () => t('components.pdfs.symbols.equals.name')
}

const MULTIPLICATION: SymbolTypes = {
  key: OPERATION_KEY.MULTIPLICATION,
  getSymbol: () => t('components.pdfs.symbols.multiplication.symbol'),
  getName: () => t('components.pdfs.symbols.multiplication.name')
}

const DIVISION: SymbolTypes = {
  key: OPERATION_KEY.DIVISION,
  getSymbol: () => t('components.pdfs.symbols.division.symbol'),
  getName: () => t('components.pdfs.symbols.division.name')
}

const FRACTION: SymbolTypes = {
  key: OPERATION_KEY.FRACTION,
  getSymbol: () => t('components.pdfs.symbols.fraction.symbol'),
  getName: () => t('components.pdfs.symbols.fraction.name')
}

const ADDITION: SymbolTypes = {
  key: OPERATION_KEY.ADDITION,
  getSymbol: () => t('components.pdfs.symbols.addition.symbol'),
  getName: () => t('components.pdfs.symbols.addition.name')
}

const SUBTRACTION: SymbolTypes = {
  key: OPERATION_KEY.SUBTRACTION,
  getSymbol: () => t('components.pdfs.symbols.subtraction.symbol'),
  getName: () => t('components.pdfs.symbols.subtraction.name')
}

export const SYMBOL = {
  [SYMBOL_KEY.EQUALS]: EQUALS,
  [OPERATION_KEY.MULTIPLICATION]: MULTIPLICATION,
  [OPERATION_KEY.DIVISION]: DIVISION,
  [OPERATION_KEY.FRACTION]: FRACTION,
  [OPERATION_KEY.ADDITION]: ADDITION,
  [OPERATION_KEY.SUBTRACTION]: SUBTRACTION
}
