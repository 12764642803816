import styled, { css } from 'styled-components'
import { Box, Tab, Tabs } from '@mui/material'
import { Colors, FontSizes, FontWeights } from 'styles'
import { from } from 'common/theme'
import { TabsTypes } from './TabBar.types'

export const TabBarStyles = styled(Box)<{ disableMobileMode?: boolean }>`
  width: 100%;
  height: auto;
`

export const TabBarHeaderStyles = styled(Tabs)<TabsTypes>`
  display: flex;
  align-items: center;
  background-color: ${Colors.white};
  border-bottom: 0.1rem solid ${Colors.lightGrey};
  cursor: pointer;

  ${({ isStickedOnTop }) =>
    isStickedOnTop &&
    css`
      position: sticky;
      top: 0;
      z-index: 4;
    `}

  ${({ allBorders }) =>
    allBorders &&
    css`
      border: 0.1rem solid ${Colors.lightGrey};
    `}

  ${({ variant }) =>
    variant === 'fullWidth' &&
    css`
      .MuiTabs-flexContainer {
        justify-content: space-evenly;
      }

      .MuiButtonBase-root {
        flex: 1;
      }
    `}
`

export const TabStyles = styled(Tab)`
  font-size: ${FontSizes.normal};

  padding: 1.6rem 4rem;

  font-weight: ${FontWeights.bold};
  text-decoration: none;
  text-transform: none;

  transition: all 0.2s;
`

export const TabRenderElementStyles = styled(Box)`
  height: calc(100% - 8.5rem);

  ${from.md} {
    height: calc(100% - 5.8rem);
  }
`
