import { FormControlLabel, Checkbox as CheckboxUI } from '@mui/material'
import React from 'react'
import { CheckboxPropTypes } from './Checkbox.types'

export const Checkbox: React.FC<CheckboxPropTypes> = ({
  formControlLabelProps,
  ...checkboxProps
}) => {
  const {
    label = '' || formControlLabelProps?.label,
    sx: labelSx,
    ...formLabelProps
  } = formControlLabelProps || {}

  return (
    <FormControlLabel
      label={label}
      componentsProps={{ typography: { variant: 'body2' } }}
      color={checkboxProps.color}
      disabled={checkboxProps.disabled}
      control={<CheckboxUI {...checkboxProps} />}
      sx={{ marginLeft: 0, marginRight: 0, ...labelSx }}
      {...formLabelProps}
    />
  )
}

export * from './Checkbox.types'
