import { Box } from '@mui/material'
import styled, { css } from 'styled-components'
import { FontWeights } from 'styles'

export const BoxStyles = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  min-height: 3rem;
`

export const UserRowStyles = styled(Box)<{
  isSelected?: boolean
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.4rem;
  overflow: hidden;

  padding: 1rem;
  box-shadow: 0 0.8rem 2.4rem ${({ theme }) => theme.palette.grey[300]}20;

  outline: 0.2rem solid transparent;
  border: 0.1rem solid ${({ theme }) => theme.palette.grey[50]};

  transition: all 0.3s ease-in-out;

  .MuiAvatar-root {
    width: 4.2rem;
    height: 4.2rem;

    font-weight: ${FontWeights.bold};
    font-size: 1.4rem; // TODO: Design System
  }

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      outline: 0.2rem solid ${({ theme }) => theme.palette.secondary.main};
      border: 0.1rem solid transparent;
    `}
`
