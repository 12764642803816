import React from 'react'
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { LeftNavigationPropTypes } from './LeftNavigationItem.types'

export const LeftNavigationItem: React.FC<LeftNavigationPropTypes> = ({
  item,
  onClose
}) => {
  const { t } = useTranslation()

  return (
    <NavLink
      to={item.to || '#'}
      onClick={(e) => {
        if (!item.to) {
          e.preventDefault()
        }
      }}
    >
      {({ isActive: active }) => {
        const isActive = active && item.to
        return (
          <ListItemButton
            color='primary'
            onClick={() => {
              if (!item.to && item.onClick) item.onClick()
              onClose()
            }}
          >
            <ListItemIcon color='primary'>
              <SvgIcon
                component={
                  item.filledIcon && isActive ? item.filledIcon : item.icon
                }
                color={isActive ? 'primary' : 'inherit'}
                fontSize='medium'
              />
            </ListItemIcon>
            <ListItemText
              primary={t(item.title.mobile || item.title.main)}
              primaryTypographyProps={{
                color: isActive ? 'primary' : 'inherit',
                fontWeight: isActive ? 'bold' : 'initial',
                minWidth: 'max-content'
              }}
            />
          </ListItemButton>
        )
      }}
    </NavLink>
  )
}
