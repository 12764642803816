import auth from './auth'
import general from './general'
import navigation from './navigation'
import components from './components'
import pages from './pages'

export const translationNb = {
  auth,
  components,
  general,
  navigation,
  pages
}
