import React, { forwardRef } from 'react'
import { TransitionProps } from '@mui/material/transitions'
import { Slide } from '@mui/material'
import { CloseOutlined } from '@mui/icons-material'

import { ModalPropTypes } from './Modal.types'
import {
  ModalStyles,
  CloseButtonStyles,
  ModalContentStyles
} from './Modal.styles'

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

export const ModalComponent: React.FC<ModalPropTypes> = ({
  children,
  zIndexPriority = 'low',
  ...modalProps
}) => {
  return (
    <ModalStyles
      {...modalProps}
      zIndexPriority={zIndexPriority}
      open={modalProps.open}
      TransitionComponent={Transition}
      closeAfterTransition
      keepMounted
    >
      {modalProps.onClose && (
        <CloseButtonStyles
          onClick={(e) =>
            modalProps?.onClose && modalProps?.onClose(e, 'backdropClick')
          }
        >
          <CloseOutlined />
        </CloseButtonStyles>
      )}
      <ModalContentStyles>{children}</ModalContentStyles>
    </ModalStyles>
  )
}

export * from './Modal.types'
export * from './components'
