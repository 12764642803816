import enFlag from 'assets/images/flags/en.png'
import noFlag from 'assets/images/flags/no.png'

export type LanguageISO639Types = 'en' | 'nb' | 'nn' | 'es'
export type LanguageIdTypes = -1 | 0 | 1 | 2 | 3

export type LanguageTypes = {
  iso639: LanguageISO639Types
  id: LanguageIdTypes
  withAccent: string
  flag?: string
  disabled?: boolean
}

export const LANGUAGES_DATA: LanguageTypes[] = [
  {
    id: 0,
    iso639: 'en',
    withAccent: 'English',
    flag: enFlag
  },
  {
    id: 1,
    iso639: 'nn',
    withAccent: 'Nynorsk',
    flag: noFlag
  },
  {
    id: 2,
    iso639: 'nb',
    withAccent: 'Bokmål',
    flag: noFlag
  },
  {
    id: 3,
    iso639: 'es',
    withAccent: 'Spanish',
    disabled: true
  }
]

export const langTags = {
  nynorsk: 'nn',
  english: 'en',
  bokmal: 'nb'
} as const
