import { HelpOutline } from '@mui/icons-material'
import { Box, Chip, Divider, Typography } from '@mui/material'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { LazyImageComponent } from 'components'
import { useAuth } from 'context'
import { TEACHER_TYPES } from 'services'
import { GameCardSkeleton } from './GameCard.skeleton'
import {
  GameCardStyles,
  CheckCircleTwoToneStyles,
  GameCardDescriptionStyles,
  GameCardImageStyles,
  GameCardContentStyles
} from './GameCard.styles'

import { GameCardPropTypes } from './GameCard.types'

import { Button } from '..'
import { GameCardChipListComponent } from './components'

export const GameCard: React.FC<GameCardPropTypes> = ({
  loading = false,
  game,
  isSelected,
  onMoreInfoClick,
  footer,
  isPremium
}) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const containerRef = useRef<HTMLDivElement>(null)

  if (loading) return <GameCardSkeleton />

  const shouldDisplayPremiumBanner =
    isPremium && user?.teacherType === TEACHER_TYPES.GUEST

  return (
    <GameCardStyles isSelected={isSelected}>
      <CheckCircleTwoToneStyles
        color='secondary'
        opacity={isSelected ? 1 : 0}
      />
      <GameCardImageStyles>
        {shouldDisplayPremiumBanner && (
          <Chip
            variant='filled'
            label={t('components.gameCard.needsSchoolLicense')}
          />
        )}
        <LazyImageComponent src={game.image} alt={game.name} />
      </GameCardImageStyles>

      <GameCardContentStyles ref={containerRef}>
        <Box display='flex' flexDirection='column' gap={0.5}>
          <Box
            display='flex'
            flexDirection='row'
            gap={2}
            alignItems='center'
            justifyContent='space-between'
          >
            <Typography
              variant='body2'
              fontWeight={({ typography }) => typography.fontWeightBold}
            >
              {game.name}
            </Typography>
            <Button
              color='secondary'
              size='small'
              variant='text'
              onClick={onMoreInfoClick}
              endIcon={<HelpOutline />}
              value={t('components.button.text.moreInfo')}
              sx={{
                p: 0,
                minHeight: 'auto',
                ':hover': {
                  background: 'transparent'
                }
              }}
            />
          </Box>
          <Typography variant='caption'>
            {t('components.gameCard.planet', {
              planetName: game.planet.name
            })}
          </Typography>
        </Box>
        <GameCardDescriptionStyles>
          <Typography variant='body2' textOverflow='ellipsis'>
            {game.shortDescription}
          </Typography>
        </GameCardDescriptionStyles>
        {!!game.tags && (
          <GameCardChipListComponent
            containerRef={containerRef}
            chips={game.tags.flatMap((tag) => tag.name)}
          />
        )}
      </GameCardContentStyles>
      {!!footer && (
        <>
          <Divider />
          {footer}
        </>
      )}
    </GameCardStyles>
  )
}

export * from './GameCard.types'
export * from './GameCard.styles'
