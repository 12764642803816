export const shouldExecuteRandomly = (shouldExecute: boolean) => {
  return shouldExecute ? Boolean(Math.round(Math.random())) : false
}

export const createDimensionalArray = (
  dimensions: number[],
  initializedFunctionValue: unknown
) => {
  if (dimensions.length > 0) {
    const [dim, ...rest] = dimensions

    const newArray = new Array<unknown>()

    for (let i = 0; i < dim; i += 1) {
      newArray[i] = createDimensionalArray(rest, initializedFunctionValue)
    }

    return newArray
  }

  const isFunction = typeof initializedFunctionValue === 'function'

  return isFunction ? initializedFunctionValue() : initializedFunctionValue
}

export const swapArrayElements = <T>(
  arr: T[],
  indexA: number,
  indexB: number
): T[] => {
  const copy = [...arr]
  const temp = copy[indexA]

  copy[indexA] = copy[indexB]
  copy[indexB] = temp

  return copy
}
