import { TabPropTypes, TabsPropTypes } from 'common/components'
import { PathPattern, To, matchPath, useLocation } from 'react-router-dom'
import { useMemo } from 'react'

type TabConditionalProps =
  | { end: true }
  | { end?: false | undefined; to?: TabPropTypes['to'] }

type TabPathPatternBaseTypes<T extends string = string> = Omit<
  PathPattern<T>,
  'end' | 'to'
>

interface TabBaseTypes<T extends string = string>
  extends TabPathPatternBaseTypes<T> {
  label: TabPropTypes['label']
}

type TabTypes<T extends string = string> = TabBaseTypes<T> & TabConditionalProps

type OutputTabTypes = Required<Pick<TabPropTypes, 'id' | 'value'>> &
  TabPropTypes

const getMatchedRoute = <T extends string = string>(
  patterns: readonly TabTypes<T>[]
) => {
  const location = useLocation()

  const match = patterns.find((pattern) => {
    if (pattern.end === false && pattern.to) {
      const matched = matchPath(pattern.to.toString(), location.pathname)
      if (matched && matched.pathname === location.pathname) return pattern
    }
    return null
  })

  if (match) return match

  return null
}

export const useTabs = <T extends string = string>(
  inputTabs: TabTypes<T>[]
) => {
  const treatedTabs = inputTabs.flatMap<TabTypes<T>, TabTypes<T>>((tab) => {
    if (tab.path.startsWith('/')) {
      throw new Error('PATH prop can not start with /')
    }

    if (!tab.end && tab.to && tab.to.toString().startsWith('/')) {
      throw new Error('TO prop can not start with /')
    }

    return tab
  })

  const matchedRoute = getMatchedRoute(treatedTabs)

  const outputTabs = treatedTabs.flatMap<OutputTabTypes, TabTypes<T>>((tab) => {
    const to = useMemo<To | undefined>(() => {
      if (tab.end || !tab.to) return `/${tab.path}`
      if (tab.to) return `/${tab.to}`
      return undefined
    }, [tab])

    return {
      label: tab.label,
      id: tab.path,
      value: tab.path,
      to
    }
  })

  const parentProps: TabsPropTypes = {
    value: matchedRoute?.path
  }

  return {
    children: outputTabs,
    parent: {
      props: parentProps
    }
  }
}
