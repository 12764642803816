import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@mui/material'
import { useSnackbar } from 'notistack'
import { CloseRounded } from '@mui/icons-material'

import { useDisclosure } from 'hooks'

import {
  FeedbackContentTypes,
  FeedbackContextTypes,
  ShowFeedbackTypes
} from './Feedback.context.types'

export const FeedbackContext = createContext<FeedbackContextTypes>(
  {} as FeedbackContextTypes
)

export const useFeedback = () => useContext(FeedbackContext)

export const FeedbackContextWrapper: React.FC = ({ children }) => {
  const { t } = useTranslation()
  const feedbackHandlers = useDisclosure()
  const alert = useSnackbar()

  const SUCCESS_FEEDBACK_TEMPLATE: Partial<FeedbackContentTypes> = {
    title: t('components.modals.confirmation.assignment.create.subtitle')
  }

  const ERROR_FEEDBACK_TEMPLATE: Partial<FeedbackContentTypes> = {
    title: t('components.modals.error.assignment.create.title')
  }

  const DEFAULT_FEEDBACK_CONFIG: FeedbackContentTypes = {
    content: '',
    footer: {
      visible: true,
      align: 'center'
    },
    title: '',
    subtitle: '',
    template: 'response'
  }

  const [feedbackContent, setFeedbackContent] = useState<FeedbackContentTypes>(
    DEFAULT_FEEDBACK_CONFIG
  )

  const showFeedback = useCallback(
    (props?: ShowFeedbackTypes) => {
      let feedback = { ...DEFAULT_FEEDBACK_CONFIG } as FeedbackContentTypes

      if (props?.content) {
        feedback = { ...feedback, ...props.content }
      }
      if (props?.status === 'error') {
        feedback = { ...feedback, ...ERROR_FEEDBACK_TEMPLATE }
      }
      if (props?.status === 'success') {
        feedback = { ...feedback, ...SUCCESS_FEEDBACK_TEMPLATE }
      }

      setFeedbackContent(feedback)

      return feedbackHandlers.onOpen()
    },
    [
      ERROR_FEEDBACK_TEMPLATE,
      SUCCESS_FEEDBACK_TEMPLATE,
      feedbackContent,
      feedbackHandlers
    ]
  )

  const restore = () => setFeedbackContent(DEFAULT_FEEDBACK_CONFIG)

  const action = (snackbarId: string) => (
    <IconButton onClick={() => alert.closeSnackbar(snackbarId)}>
      <CloseRounded sx={{ color: ({ palette }) => palette.common.white }} />
    </IconButton>
  )

  const showAlertError = (error: string) => {
    return alert.enqueueSnackbar(error, {
      variant: 'error',
      action
    })
  }

  const showAlertSuccess = (success: string) => {
    return alert.enqueueSnackbar(success, {
      variant: 'success',
      action
    })
  }

  useEffect(() => {
    if (!feedbackHandlers.isOpen) {
      restore()
    }
  }, [feedbackHandlers])

  const feedback = useMemo(
    () => ({
      dialog: {
        show: showFeedback,
        content: feedbackContent,
        handlers: feedbackHandlers
      },
      alert: {
        show: alert.enqueueSnackbar,
        showError: showAlertError,
        showSuccess: showAlertSuccess
      },
      restore
    }),
    [
      feedbackContent,
      showFeedback,
      feedbackHandlers,
      ERROR_FEEDBACK_TEMPLATE,
      SUCCESS_FEEDBACK_TEMPLATE
    ]
  )

  return (
    <FeedbackContext.Provider value={feedback}>
      {children}
    </FeedbackContext.Provider>
  )
}

export * from './Feedback.context.types'
