import { AxiosError } from 'axios'
import { useFeedback } from 'context'
import { ApiResponseTypes, StudentServices } from 'services'
import { useQuery } from 'react-query'

export const useStudentByIdQuery = (studentId: number) => {
  const { alert } = useFeedback()

  const query = useQuery({
    queryKey: ['/students/:id', studentId],
    queryFn: () => StudentServices.id.get(studentId),
    enabled: !!studentId,
    onError: (error: AxiosError<ApiResponseTypes<unknown>>) => {
      alert.showError(error.response?.data.message || error.message)
    }
  })

  return query
}
