import { api, ApiResponseTypes, AssignmentSelectedGameTypes } from 'services'

const getAssignmentSelectedGames = async (id: number) => {
  const { data: response } = await api.get<
    ApiResponseTypes<AssignmentSelectedGameTypes[]>
  >(`/assignments/${id}/selectedGames`)

  return response.data
}

export default {
  get: getAssignmentSelectedGames
}
