import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import { ROUTES } from 'router'

const Support = lazy(() => import('views/support/Support'))

export const renderSupportRoutes = () => {
  const { HELP_SUPPORT } = ROUTES

  return (
    <Route path={HELP_SUPPORT}>
      <Route index element={<Support />} />
    </Route>
  )
}
