import React, { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider, IconButton, SvgIcon } from '@mui/material'
import {
  ExpandLess as ArrowUp,
  ExpandMore as ArrowDown
} from '@mui/icons-material'

import { useActionState } from 'hooks'

import { HelperTextComponent } from 'common/components'
import { NumberInputPropTypes } from './NumberInput.types'
import {
  NumberInputButtonContainerStyles,
  NumberInputContainerStyles,
  NumberInputStyles
} from './NumberInput.styles'

export const NumberInputComponent: React.FC<NumberInputPropTypes> = ({
  error,
  success,
  hovered,
  focused,
  helperText,
  inputMode = 'numeric',
  type = 'number',
  label,
  step = 1,
  min = 0,
  max = Number.MAX_SAFE_INTEGER,
  defaultValue = 0,
  disableArrows = false,
  maxLength = 9,
  ...props
}) => {
  const { t } = useTranslation()

  const inputRef = useRef<HTMLInputElement>(null)

  const { hasError, hasFocus, hasHover, hasSuccess } = useActionState(
    { error, focused, hovered, success },
    inputRef
  )

  const increment = useCallback(() => {
    const target = inputRef.current
    const event = new Event('change')

    if (!target) return

    let result = target.valueAsNumber + step

    if (result >= max) result = max

    Object.defineProperty(event, 'target', {
      writable: false,
      value: {
        value: result,
        name: props.name
      }
    })

    if (props.onChange) props.onChange(event)
  }, [props.value, inputRef.current, min, max])

  const decrement = useCallback(() => {
    const target = inputRef.current
    const event = new Event('change')

    if (!target) return

    let result = target.valueAsNumber - step

    if (result <= min) result = min

    Object.defineProperty(event, 'target', {
      writable: false,
      value: {
        value: result,
        name: props.name
      }
    })

    if (props.onChange) props.onChange(event)
  }, [props.value, inputRef.current, min, max])

  const handleMaxNumberOfDigits = (e: React.FormEvent<HTMLInputElement>) => {
    const ref = inputRef.current

    if (!ref) return

    ref.valueAsNumber = Number(e.currentTarget.value.replace(/[^0-9]/g, ''))

    if (ref.valueAsNumber > maxLength) {
      ref.valueAsNumber = Number(ref.value.slice(0, maxLength))
    }
  }

  return (
    <NumberInputStyles>
      <NumberInputContainerStyles
        success={hasSuccess}
        error={hasError}
        hovered={hasHover}
        focused={hasFocus}
      >
        {label && <Box component='label'>{label}</Box>}
        <input
          {...props}
          type={type}
          inputMode={inputMode}
          onInput={handleMaxNumberOfDigits}
          ref={inputRef}
          min={min}
          max={max}
          step={step}
          defaultValue={min > defaultValue ? min : defaultValue}
          data-testid='inputComponent'
        />

        {!disableArrows && (
          <NumberInputButtonContainerStyles>
            <IconButton onClick={increment}>
              <SvgIcon component={ArrowUp} color='primary' fontSize='inherit' />
            </IconButton>
            <Divider style={{ width: '100%' }} />
            <IconButton onClick={decrement}>
              <SvgIcon
                component={ArrowDown}
                color='primary'
                fontSize='inherit'
              />
            </IconButton>
          </NumberInputButtonContainerStyles>
        )}
      </NumberInputContainerStyles>

      {helperText && (
        <HelperTextComponent error={hasError} success={hasSuccess}>
          {helperText || t('general.status.error.somethingWentWrong')}
        </HelperTextComponent>
      )}
    </NumberInputStyles>
  )
}

export * from './NumberInput.types'
