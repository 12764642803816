import {
  FormHelperText,
  InputAdornment,
  InputAdornmentProps,
  InputBase,
  InputLabel,
  FormControl
} from '@mui/material'
import styled, { css } from 'styled-components'
import { Colors, FontWeights, inputState } from 'styles'
import {
  InputHelperTypes,
  InputLabelTypes,
  InputPropTypes
} from './Input.types'

export const FormControlStyles = styled(FormControl)`
  gap: 0.5rem;
`

export const InputLabelStyles = styled(InputLabel)<InputLabelTypes>`
  color: ${Colors.dark};

  font-weight: ${FontWeights.bold};
  font-size: ${({ theme }) => theme.typography.h3.fontSize};
  transition: all 0.2s;

  &.Mui-error {
    color: ${Colors.danger};
  }

  &.Mui-focused {
    color: ${Colors.infoDark};
  }

  ${({ success }) =>
    success &&
    css`
      color: ${Colors.success};
    `}
`

export const InputStyles = styled(InputBase)<InputPropTypes>`
  width: 100%;
  min-height: 3.2rem;

  border-radius: 0.5rem;
  border: 0.1rem solid ${({ theme }) => theme.palette.grey[100]};
  transition: all 0.2s;

  ${({ margin }) => margin !== 'none' && `margin-top: 2rem`};

  .MuiInputBase-input {
    padding-right: 0.5rem;
    padding-left: 1rem;

    :disabled {
      cursor: not-allowed;
    }
  }

  ${({ error }) => inputState.error(error)}
  ${({ success }) => inputState.success(success)}
  ${({ focused }) => inputState.focused(focused)}
  ${({ hovered }) => inputState.hovered(hovered)}
`

export const InputAdornmentStyles = styled(InputAdornment)<InputAdornmentProps>`
  position: absolute;
  right: 0;
  margin-right: 0.5rem;
  > svg {
    font-size: 2rem;
  }
`

export const HelperTextStyles = styled(FormHelperText)<InputHelperTypes>`
  font-size: ${({ theme }) => theme.typography.body1.fontSize};

  color: ${Colors.greyishBlack};
  transition: all 0.2s;
  margin: 0;

  ${({ error }) =>
    error &&
    css`
      color: ${Colors.danger};
    `}

  ${({ success }) =>
    success &&
    css`
      color: ${Colors.success};
    `}
`
