import { ReportsServices } from 'services'
import { useQuery } from 'react-query'

import { useParams } from 'react-router-dom'
import { UseQueryOptionsTypes } from '../../queries.types'

export const useAssignmentsByIdInReportsQuery = (
  options: UseQueryOptionsTypes | undefined = {}
) => {
  const { enabled } = options

  const { assignmentId } = useParams()

  const query = useQuery(
    ['v2/reports/assignments/assignmentId', assignmentId],
    () => {
      return ReportsServices.assignments.id.get({
        assignmentId: Number(assignmentId)
      })
    },
    {
      enabled
    }
  )

  return query
}
