import { IconButton } from '@mui/material'
import { from } from 'common/theme'
import styled, { css } from 'styled-components'

export const AsideMoodItemStyles = styled(IconButton)<{
  isActive: boolean
  isUnselected: boolean
}>`
  color: ${({ theme }) => theme.palette.text.primary};

  .MuiSvgIcon-root {
    font-size: 3.2rem;
    ${from.sm} {
      font-size: 4.8rem;
    }

    ${from.md} {
      font-size: 3rem;
    }
  }

  ${({ isUnselected, theme }) =>
    !isUnselected &&
    css`
      color: ${theme.palette.grey[300]};
    `};

  ${({ isActive, theme }) =>
    isActive &&
    css`
      color: ${theme.palette.primary.main};
    `};

  transition: all 0.3s ease-in-out;
`
