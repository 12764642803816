import styled, { css } from 'styled-components'
import { inputState } from 'styles'
import { AdornmentButtonTypes, PickerContainerTypes } from './Picker.types'

export const PickerContainerStyles = styled.div<PickerContainerTypes>`
  display: flex;
  align-items: center;

  flex-direction: row;
  position: relative;

  height: 3.2rem;
  flex: 1;
  width: 100%;
  min-width: 7rem;
  min-height: 3.2rem;
  height: 100%;

  background: ${({ theme }) => theme.palette.common.white};

  border-radius: 0.4rem;
  border: 0.1rem solid ${({ theme }) => theme.palette.grey.A700};

  transition: all 0.2s;

  .MuiFormControl-root {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;

    .MuiInputBase-root {
      height: 100%;
      border: none;
      padding: 0;
      border-radius: 0;
      line-height: 0;
      padding-right: 0;

      input {
        text-align: center;
        padding: 0 !important;
      }

      fieldset {
        display: none;
      }
    }
  }

  ${({ error }) => inputState.error(error)}
  ${({ success }) => inputState.success(success)}
  ${({ focused }) => inputState.focused(focused)}
  ${({ hovered }) => inputState.hovered(hovered)}
`

export const AdornmentButtonStyles = styled.button<AdornmentButtonTypes>`
  display: flex;
  align-items: center;
  height: 3.2rem;

  padding: 0 1.5rem;
  margin: 0;
  justify-content: center;
  width: 7rem;

  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};

  background: ${({ theme }) => theme.palette.secondary.A200};

  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;

  border: none;
  border-right: 0.1rem solid ${({ theme }) => theme.palette.grey.A700};

  transition: all 0.2s;

  ${({ error }) =>
    error &&
    css`
      ${inputState.error(error)}
      background: ${({ theme }) => theme.palette.error[100]};
    `}

  ${({ success }) =>
    success &&
    css`
      ${inputState.success(success)}
      background: ${({ theme }) => theme.palette.success[50]};
    `}

  ${({ focused }) =>
    focused &&
    css`
      ${inputState.focused(focused)}
      background: ${({ theme }) => theme.palette.info?.A100};
    `}
`
