import React, { ReactChild, ReactChildren } from 'react'
import { RecoilRoot } from 'recoil'
import { AuthContextWrapper, LanguageContextWrapper } from '.'
import { LayoutContextWrapper } from './layout/Layout.context'
import { FeedbackContextWrapper } from './feedback/Feedback.context'

interface GlobalContextProps {
  children: ReactChild | ReactChildren
}

export const GlobalContextWrapper = ({ children }: GlobalContextProps) => (
  <RecoilRoot>
    <LayoutContextWrapper>
      <LanguageContextWrapper>
        <FeedbackContextWrapper>
          <AuthContextWrapper>{children}</AuthContextWrapper>
        </FeedbackContextWrapper>
      </LanguageContextWrapper>
    </LayoutContextWrapper>
  </RecoilRoot>
)
