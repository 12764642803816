/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState } from 'react'
import { useTableNav } from '@table-nav/react'
import {
  SortingState,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  getFilteredRowModel,
  flexRender,
  getExpandedRowModel,
  ExpandedState,
  ColumnFiltersState,
  getFacetedRowModel,
  SortingColumn
} from '@tanstack/react-table'
import {
  Box,
  Divider,
  SvgIcon,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  LinearProgress,
  Button
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PaginationComponent } from 'components'

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { useTheme } from 'styled-components'
import {
  InfiniteButtonComponent,
  TableRowEmptyContentComponent
} from './components'

import { TablePropTypes } from './Table.types'
import { TableStyles } from './Table.styles'
import { useSkipper } from './hooks'

type TDataObjectTypes<TData> = object & { subRows?: TData[] }

const SortingHeader = <TData extends object>({
  sortDirection,
  onClick,
  headerContent
}: {
  onClick: SortingColumn<TData>['getToggleSortingHandler']
  headerContent: React.ReactNode
  sortDirection: SortingColumn<TData>['getIsSorted']
}) => {
  const hasSortDirection = Boolean(sortDirection())
  const sortIcon =
    sortDirection() === 'asc' ? KeyboardArrowUp : KeyboardArrowDown

  return (
    <Button
      fullWidth
      color='inherit'
      sx={{
        borderRadius: 0,
        gap: 0.5,
        justifyContent: 'space-between'
      }}
      endIcon={
        <SvgIcon
          component={hasSortDirection ? sortIcon : Box}
          sx={{ fontSize: 16, marginRight: -0.5 }}
        />
      }
      onClick={onClick()}
    >
      {headerContent}
    </Button>
  )
}

export const TableComponent = <TData extends TDataObjectTypes<TData>>({
  data: defaultData,
  columns,
  isLoading = false,
  footer,
  renderSubComponent,
  infiniteScroll,
  sx,
  headerBgColor,
  onRowClick,
  emptyContent,
  editedRows,
  ...rest
}: TablePropTypes<TData>) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState<ExpandedState>({})
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const [tableData, setTableData] = useState(() => [...defaultData])

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper()
  const { listeners } = useTableNav()

  useEffect(() => {
    if (isLoading) return
    setTableData(defaultData)
  }, [defaultData, isLoading])

  useEffect(() => {
    if (editedRows) editedRows(tableData)
  }, [tableData])

  const table = useReactTable({
    data: tableData,
    columns,
    getRowCanExpand: renderSubComponent?.getRowCanExpand,
    autoResetPageIndex,
    state: {
      columnFilters,
      expanded,
      sorting
    },
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    meta: {
      updateData: (rowIndex, columnId, value) => {
        // Skip page index reset until after next rerender
        skipAutoResetPageIndex()
        setTableData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value
              }
            }
            return row
          })
        )
      }
    },
    manualPagination: true
  })

  return (
    <TableContainer {...listeners} sx={{ overflowX: 'auto', ...sx }}>
      <TableStyles
        {...rest}
        width={table.getCenterTotalSize()}
        headerBgColor={headerBgColor ? headerBgColor(theme) : null}
      >
        {rest['aria-label'] && <caption>{rest['aria-label']}</caption>}
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const headerContent = header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )

                const isSortable = header.column.getCanSort()

                return (
                  <TableCell
                    tabIndex={0}
                    key={header.id}
                    colSpan={header.colSpan}
                    width={header.getSize()}
                  >
                    {isSortable ? (
                      <SortingHeader
                        onClick={header.column.getToggleSortingHandler}
                        headerContent={headerContent}
                        sortDirection={header.column.getIsSorted}
                      />
                    ) : (
                      headerContent
                    )}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableCell
              colSpan={table.getAllColumns().length}
              sx={{
                backgroundColor: ({ palette }) => palette.background.paper
              }}
            >
              <LinearProgress />
              <Divider />
              <Box
                display='flex'
                flexDirection='column'
                py={5}
                justifyContent='center'
                alignItems='center'
                textAlign='center'
              >
                <Typography
                  variant='body1'
                  fontWeight={(theme) => theme.typography.fontWeightBold}
                >
                  {t('general.state.loading')}
                </Typography>
              </Box>
            </TableCell>
          ) : table.getRowModel().rows.length === 0 ? (
            <TableRowEmptyContentComponent
              {...emptyContent}
              tableCellProps={{
                colSpan: table.getAllColumns().length
              }}
            />
          ) : (
            table.getRowModel().rows.map((row, index) => (
              <>
                <TableRow
                  key={row.id}
                  {...(onRowClick && {
                    onClick: () => onRowClick(row)
                  })}
                >
                  {row.getVisibleCells().map((cell) => {
                    const headerContent = flexRender(
                      cell.column.columnDef.header,
                      cell.getContext()
                    )

                    const columnTitle = React.isValidElement(headerContent)
                      ? headerContent.props.column.columnDef.header()?.props
                          ?.title
                      : undefined

                    return (
                      <TableCell
                        tabIndex={0}
                        key={cell.id}
                        width={cell.column.getSize()}
                        data-cell={`${
                          cell.column.columnDef.meta
                            ? cell.column.columnDef.meta.dataCell
                            : columnTitle
                        }:`}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
                {renderSubComponent && row.getIsExpanded() && (
                  <TableRow
                    key={row.id.concat(index.toString())}
                    id='sub-component'
                  >
                    <TableCell colSpan={row.getVisibleCells().length}>
                      {renderSubComponent.subComponent({ row })}
                    </TableCell>
                  </TableRow>
                )}
              </>
            ))
          )}
          {/* this button can be refactored with infinite table scroll from @tanstack/react-table */}
          {!isLoading &&
            table.getRowModel().rows.length !== 0 &&
            infiniteScroll?.hasNextPage && (
              <TableCell
                colSpan={table.getAllColumns().length}
                padding='none'
                sx={{ border: 'none' }}
              >
                <InfiniteButtonComponent
                  {...infiniteScroll}
                  progressType='linear'
                />
              </TableCell>
            )}
        </TableBody>
      </TableStyles>
      {footer && !isLoading && table.getRowModel().rows.length > 0 && (
        <Box
          position='sticky'
          bottom={0}
          bgcolor='white'
          py={2}
          px={3}
          borderTop={({ palette }) => `0.1rem solid ${palette.divider}`}
          display='flex'
          justifyContent={footer.pagination ? 'space-between' : 'flex-end'}
          alignItems='center'
          gap={2}
          flexWrap='wrap'
        >
          {footer.pagination && (
            <PaginationComponent
              sx={{ minWidth: 'max-content' }}
              disabled={isLoading}
              count={table.getPageCount()}
              onChange={(_, page) => {
                table.setPagination({
                  pageIndex: page,
                  pageSize: 1
                })
              }}
            />
          )}
          <Typography>
            {t('general.showingOf', {
              visibleRows: table.getRowModel().rows.length,
              totalDataRows: footer.totalDataCount
            })}
          </Typography>
        </Box>
      )}
    </TableContainer>
  )
}

export * from './components'
export * from './Table.types'
