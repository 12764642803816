import assignmentIdMethods from './Assignments.id'
import assignmentIdSelectedGameMethods from './Assignments.id.selectedGames'
import assignmentMethods from './Assignments'
import { AssignmentsOfReportServices } from '../reports'

export const AssignmentServices = {
  ...assignmentMethods,
  id: {
    ...assignmentIdMethods,
    selectedGames: assignmentIdSelectedGameMethods
  },
  reports: {
    paginated: AssignmentsOfReportServices.paginated
  }
}

export * from './Assignments.types'
