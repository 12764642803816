import React, { lazy, useCallback } from 'react'
import { Navigate, Outlet, Route } from 'react-router-dom'
import { ContextRoute, ROUTES } from 'router'
import { LicenseContextWrapper } from 'context'

import { SchoolsView } from 'views/license/subViews/schools/Schools'
import { SchoolView } from 'views/license/subViews/schools/[id]/School'
import { TeachersView } from 'views/license/subViews/schools/[id]/teachers/Teachers'
import { ClassView } from 'views/license/subViews/schools/[id]/classes/[id]/Class'

const License = lazy(() => import('views/license/License'))

const curPath = window.location.pathname

export const renderLicenseRoutes = () => {
  const { LICENSE, SCHOOLS, SCHOOL_ID, TEACHERS, CLASSES, CLASS_ID } = ROUTES

  const removeElementsAfterSchoolId = useCallback(() => {
    const urlArray = curPath.split('/')
    const classIndex = urlArray.indexOf('classes')
    const newUrlArray = urlArray.slice(0, classIndex)
    return newUrlArray.join('/')
  }, [window])

  return (
    <Route
      path={LICENSE}
      element={<ContextRoute wrapper={LicenseContextWrapper} />}
    >
      <Route index element={<Navigate to={SCHOOLS} />} />

      <Route element={<License />}>
        <Route path={SCHOOLS} element={<Outlet />}>
          <Route index element={<SchoolsView />} />
          <Route path={SCHOOL_ID} element={<Outlet />}>
            <Route index element={<SchoolView />} />
            <Route path={TEACHERS} element={<TeachersView />} />
            <Route path={CLASSES} element={<Outlet />}>
              <Route
                index
                element={<Navigate to={removeElementsAfterSchoolId()} />}
              />
              <Route path={CLASS_ID} element={<ClassView />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
  )
}
