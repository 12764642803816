import styled from 'styled-components'
import { from } from 'common/theme'

export const LayoutStyles = styled.div`
  position: relative;

  padding: 2rem;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  width: 100%;
  flex: 1;

  ${from.md} {
    gap: 1.6rem;
    padding: 1.6rem 1.6rem 0;
  }

  ${from.lg} {
    gap: 2.4rem;
    padding: 2.4rem 2.4rem 0;
  }
`
