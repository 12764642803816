import OrganizationsId from './Organizations.id'
import OrganizationsPaginated from './Organizations.paginated'

export const OrganizationsServices = {
  ...OrganizationsPaginated,
  id: {
    ...OrganizationsId
  }
}

export * from './Organizations.types'
