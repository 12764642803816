import styled, { css } from 'styled-components'
import { Colors } from 'styles'

export const SkipLinkBackDropStyles = styled.div<{ open?: boolean }>`
  position: fixed;
  z-index: -1;
  opacity: 0;

  background: ${Colors.black}85;

  width: inherit;
  height: inherit;

  transition: all 0.1s;

  ${({ open }) =>
    open &&
    css`
      opacity: 1;
      z-index: 10;
    `}
`

export const LinkStyles = styled.a`
  position: absolute;
  left: -9999rem;
  z-index: ${({ theme }) => theme.zIndex.modal};

  opacity: 0;

  background-color: ${Colors.white};
  padding: 2rem;

  box-shadow: 0 0 0 0.5rem ${Colors.info}30;
  border: solid 0.3rem ${Colors.info};

  :focus {
    top: 0.8rem;
    left: 2rem;
    opacity: 1;
  }
`
