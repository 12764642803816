import { api } from 'services/api/api'
import { ApiResponseTypes } from 'services/api/api.types'
import { UserInfoTypes } from './User.types'

const getUserInfo = async () => {
  return api
    .get<ApiResponseTypes<UserInfoTypes>>(`/user/info`)
    .then((response) => response)
}

export default {
  userInfo: getUserInfo
}
