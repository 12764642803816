import {
  api,
  PaginatedResponse,
  ApiPaginatedParamTypes,
  UserGroupBySchoolTypes
} from 'services'
import { makeQueryParams } from 'utils'

const allClassesBySchool = async ({
  params: { page = 1, schoolId, ...restOfParams }
}: ApiPaginatedParamTypes) => {
  const response = await api.get<PaginatedResponse<UserGroupBySchoolTypes>>(
    `/licenseManagement/schools/${schoolId}/user-groups?selectedPage=${page}${makeQueryParams(
      restOfParams
    )}`
  )

  return response.data
}

export default {
  paginated: allClassesBySchool
}
