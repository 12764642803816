import { AxiosError } from 'axios'
import { useFeedback } from 'context'
import { ApiResponseTypes, AssignmentServices } from 'services'
import { useQuery } from 'react-query'

export const useSelectedGameByAssignmentIdQuery = (assignmentId: number) => {
  const { alert } = useFeedback()

  const { data = [], ...rest } = useQuery({
    queryKey: ['/assignments/:id/selectedGames', assignmentId],
    queryFn: () => AssignmentServices.id.selectedGames.get(assignmentId),
    enabled: !!assignmentId,
    onError: (error: AxiosError<ApiResponseTypes<unknown>>) => {
      alert.showError(error.response?.data.message || error.message)
    }
  })

  return {
    data,
    ...rest
  }
}
