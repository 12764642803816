import { useCallback, useState } from 'react'

export type UseLocalStorageTypes<T = unknown> = [T, (value: T) => void]

export const useLocalStorage = <T = unknown>(
  key: string,
  initialValue: T
): UseLocalStorageTypes<T> => {
  const [state, setState] = useState<T>(() => {
    try {
      const storedValue = localStorage.getItem(key)
      return storedValue ? JSON.parse(storedValue) : initialValue
    } catch {
      return initialValue
    }
  })

  const setValue = useCallback(
    (value: T) => {
      try {
        setState(value)
        localStorage.setItem(key, JSON.stringify(value))
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    },
    [key]
  )

  return [state, setValue]
}
