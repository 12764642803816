import { CircularProgress } from '@mui/material'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Colors } from 'styles'
import { FeideFetchStatusTypes } from 'context/auth/Auth.context.types'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'

export const FeideStatusWrapperStyles = styled.div<{
  status: FeideFetchStatusTypes
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.15rem;
  border-radius: 100rem;
  background: ${Colors.lightBlue};
  border: 0.25rem solid ${Colors.pastelBlue};
  transition: all 0.25s ease;

  :first-child > :first-child {
    width: 2rem !important;
    height: 2rem !important;
  }

  ${({ status }) => {
    if (status === 'success') {
      return `
      background-color: ${Colors.progressGreen};
      border-color: ${Colors.progressDarkGreen};
      `
    }

    if (status === 'error') {
      return `
      background-color: ${Colors.dangerLight};
      border-color: ${Colors.dangerDark};
      `
    }

    return ''
  }}

  .MuiSvgIcon-root {
    ${({ status }) => {
      if (status === 'success') {
        return `
        color: ${Colors.progressDarkGreen};
        `
      }

      if (status === 'error') {
        return `
        color: ${Colors.dangerDark};
        `
      }

      return ''
    }}
  }
`

export const FeideStatusSpinnerStyles = motion(CircularProgress)

export const MotionDoneIcon = motion(DoneIcon)

export const MotionCloseIcon = motion(CloseIcon)
