import {
  ApiResponseTypes,
  CreateOrUpdateLicense,
  licenseApi,
  OrganizationCustomer
} from 'services'

const postCustomer = async (
  payload: CreateOrUpdateLicense
): Promise<OrganizationCustomer> => {
  const { data: response } = await licenseApi.post<
    ApiResponseTypes<OrganizationCustomer>
  >('/organization-licensing', payload)

  return response.data
}

export default {
  post: postCustomer
}
