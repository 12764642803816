import { LazyImageComponent, MdReaderComponent } from 'components'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Dialog,
  Box,
  Typography,
  Link,
  IconButton,
  SvgIcon,
  Divider,
  Tab,
  Tabs,
  CircularProgress,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { useGameByIdQuery } from 'hooks'
import { ArrowBackIosNew, Close } from '@mui/icons-material'
import { useAuth } from 'context'
import { TEACHER_TYPES } from 'services'
import { GameDetailsDialogPropTypes } from './GameDetailsDialog.types'
import { GameCardChipStyles } from '../gameCard/components/chipList/GameCardChipList.styles'
import { InfoSectionComponent, ModeCardComponent } from './components'

type TabPanelTypes = 'pre-classroom' | 'level-info'

export const GameCardDialogDetails: React.FC<GameDetailsDialogPropTypes> = ({
  gameId,
  dialogProps,
  variant = 'default',
  onGameModeChange,
  showCheckBoxes,
  selectedModeIds: initialSelectedModeIds = []
}) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const isMobileScreen = useMediaQuery(useTheme().breakpoints.down('md'))

  const [selectedTab, setSelectedTab] = useState<TabPanelTypes>('level-info')

  const gameQuery = useGameByIdQuery(gameId)
  const shouldDisplayPremiumBanner =
    gameQuery.data?.gameModes.find((where) => where.isPremium === false) ===
      undefined && user?.teacherType === TEACHER_TYPES.GUEST

  const [selectedModeIds, setSelectedModeIds] = useState(initialSelectedModeIds)

  const tabs = [
    {
      id: 'level-info',
      label: t('components.modals.gameDetails.levelInfo')
    },
    {
      id: 'pre-classroom',
      label: t('components.modals.gameDetails.preClassroom')
    }
  ] as const

  return (
    <Dialog
      maxWidth='lg'
      fullWidth
      fullScreen={isMobileScreen}
      PaperProps={{
        sx: {
          borderRadius: isMobileScreen ? 0 : '1.2rem',
          height: gameQuery.isLoading ? '100%' : 'auto'
        }
      }}
      scroll={isMobileScreen ? 'body' : 'paper'}
      {...dialogProps}
    >
      <Box
        position='sticky'
        top={0}
        bgcolor='background.paper'
        zIndex={({ zIndex }) => zIndex.appBar}
      >
        <Box
          p={2}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Box display='flex' alignItems='center' gap={1}>
            {variant === 'nested' && (
              <IconButton
                sx={{ ml: -1 }}
                onClick={() => {
                  if (dialogProps.onClose) {
                    dialogProps.onClose({}, 'escapeKeyDown')
                  }
                }}
              >
                <SvgIcon
                  component={ArrowBackIosNew}
                  color='primary'
                  sx={{ fontSize: 16 }}
                />
              </IconButton>
            )}
            <Typography
              variant='h3'
              component='h1'
              fontWeight={({ typography }) => typography.fontWeightBold}
            >
              {t('components.modals.gameDetails.missionDetails')}
            </Typography>
          </Box>
          {dialogProps.onClose && (
            <IconButton
              onClick={() =>
                dialogProps.onClose && dialogProps.onClose({}, 'escapeKeyDown')
              }
              sx={{
                backgroundColor: ({ palette }) => palette.primary.main
              }}
            >
              <Close
                sx={{
                  fontSize: 16,
                  color: ({ palette }) => palette.primary.contrastText,
                  '&:hover': {
                    color: ({ palette }) => palette.primary.main
                  }
                }}
              />
            </IconButton>
          )}
        </Box>
        <Divider />
      </Box>
      {gameQuery.isLoading ? (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          flex={1}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box
            py={2}
            px={3}
            display={{ xs: 'flex', md: 'grid' }}
            flexDirection='column'
            gridTemplateColumns='26rem 1fr 1fr'
            gap={3}
          >
            <Box display='flex' flexDirection='column' gap={2}>
              <LazyImageComponent
                src={gameQuery.data?.image}
                alt={gameQuery.data?.name}
                style={{ borderRadius: 8 }}
              />
              <Typography
                component={Link}
                color='secondary'
                target='_blank'
                href={gameQuery.data?.helpArticleUrl}
                variant='body2'
                sx={{ width: 'max-content' }}
              >
                {t('components.modals.gameDetails.readTheGaneHelpArticle', {
                  gameName: gameQuery.data?.name
                })}
              </Typography>
            </Box>
            <Box display='flex' flexDirection='column' gap={2}>
              <Box display='flex' flexDirection='column' gap={0.5}>
                <Typography
                  fontWeight={({ typography }) => typography.fontWeightBold}
                  variant='subtitle1'
                >
                  {gameQuery.data?.name}
                </Typography>
                <Typography
                  variant='body2'
                  fontWeight={({ typography }) => typography.fontWeightLight}
                >
                  {t('components.modals.gameDetails.planet', {
                    planetName: gameQuery.data?.planet.name
                  })}
                </Typography>
              </Box>
              <Typography variant='body1' textOverflow='ellipsis'>
                {gameQuery.data?.longDescription}
              </Typography>
              <Box display='flex' gap={1} flexWrap='wrap'>
                {gameQuery.data?.tags.map((tag) => (
                  <GameCardChipStyles
                    color='secondary'
                    key={tag.id}
                    variant='light'
                    label={tag.name}
                  />
                ))}
              </Box>
            </Box>
            <Box display='flex' flexDirection='column' gap={2}>
              <Typography
                fontWeight={({ typography }) => typography.fontWeightBold}
                variant='subtitle1'
              >
                {t('components.modals.gameDetails.curriculumObjectives')}
              </Typography>
              <InfoSectionComponent
                description={gameQuery.data?.curriculum.title || ''}
              />
            </Box>
          </Box>
          <Divider />
          <Box
            position='sticky'
            top={65}
            bgcolor='background.paper'
            zIndex={({ zIndex }) => zIndex.appBar}
          >
            <Tabs
              value={selectedTab}
              onChange={(_, tab: TabPanelTypes) => {
                if (tab === selectedTab) return
                setSelectedTab(tab)
              }}
              variant='fullWidth'
              scrollButtons
              textColor='primary'
              indicatorColor='primary'
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.id}
                  value={tab.id}
                  label={
                    <Typography
                      variant='body2'
                      fontWeight={({ typography }) => typography.fontWeightBold}
                    >
                      {tab.label}
                    </Typography>
                  }
                />
              ))}
            </Tabs>
            <Divider />
          </Box>

          <Box bgcolor={({ palette }) => palette.grey.A400} minHeight='50rem'>
            {shouldDisplayPremiumBanner && (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                minHeight='50rem'
              >
                <Typography fontWeight='900'>
                  {t('components.gameCard.needsSchoolLicense')}
                </Typography>
              </Box>
            )}
            {selectedTab === 'level-info' && !shouldDisplayPremiumBanner && (
              <Box display='flex' flexDirection='column' gap={2} py={2} px={3}>
                {variant === 'nested' && (
                  <Typography
                    fontWeight={({ typography }) => typography.fontWeightBold}
                  >
                    {t('components.modals.gameDetails.selectModes')}
                  </Typography>
                )}
                <Box
                  key={`${gameId}-${JSON.stringify(selectedModeIds, null, 2)}`}
                  display={{ xs: 'flex', md: 'grid' }}
                  flexDirection='column'
                  gridTemplateColumns='repeat(2, 1fr)'
                  gap={2}
                >
                  {gameQuery.data?.gameModes
                    .sort((a, b) => a.mode.order - b.mode.order)
                    .map((gameMode) => (
                      <ModeCardComponent
                        key={gameMode.mode.id}
                        gameMode={gameMode}
                        showCheckbox={showCheckBoxes}
                        checkbox={{
                          checked: selectedModeIds.includes(gameMode.mode.id),
                          onChange: (_, checked) => {
                            let tempModeIds: number[] = []

                            if (checked) {
                              tempModeIds = [
                                ...selectedModeIds,
                                gameMode.mode.id
                              ]
                            } else {
                              tempModeIds = selectedModeIds.filter(
                                (selectedModeId) =>
                                  selectedModeId !== gameMode.mode.id
                              )
                            }

                            setSelectedModeIds(tempModeIds)

                            if (onGameModeChange) {
                              const selectedGameModes =
                                gameQuery.data.gameModes.filter((gameMode) =>
                                  tempModeIds.includes(gameMode.mode.id)
                                )

                              const selectedModes = selectedGameModes.flatMap(
                                (gameMode) => gameMode.mode
                              )

                              onGameModeChange(selectedModes)
                            }
                          }
                        }}
                      />
                    ))}
                </Box>
              </Box>
            )}

            {selectedTab === 'pre-classroom' && (
              <Box display='flex' flexDirection='column' gap={2} p={3}>
                <MdReaderComponent
                  information={{
                    mdText: gameQuery.data?.preClassroomDescription || ''
                  }}
                >
                  {gameQuery.data?.funFact && (
                    <Box display='flex' flexDirection='column' gap={2}>
                      <Typography
                        variant='subtitle1'
                        fontWeight={({ typography }) =>
                          typography.fontWeightBold
                        }
                      >
                        {t('components.modals.gameDetails.funFact')}
                      </Typography>
                      <InfoSectionComponent
                        description={gameQuery.data?.funFact}
                      />
                    </Box>
                  )}
                </MdReaderComponent>
              </Box>
            )}
          </Box>
        </>
      )}
    </Dialog>
  )
}
