import styled, { css } from 'styled-components'

import { from } from 'common/theme'

export const FormItemStyles = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1.6rem 2.4rem;
  flex-direction: column;
`

export const FormItemContentStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
`

export const FormItemHeaderStyles = styled.div<{
  disableInvisibleGrid?: boolean
}>`
  display: grid;
  align-items: center;
  grid-template-columns: min-content max-content;
  gap: 0.6rem;
  flex: 1;
  grid-template-areas: 'icon title';

  p {
    grid-area: title;
  }

  svg {
    grid-area: icon;
  }

  ${({ disableInvisibleGrid }) =>
    disableInvisibleGrid &&
    css`
      display: flex;
    `}
`

export const FormItemBodyStyles = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.6rem;
  flex-wrap: wrap;
  flex: 2;

  ${from.md} {
    justify-content: flex-end;
  }
`
