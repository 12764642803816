import {
  MoodBadOutlined,
  SentimentDissatisfiedOutlined,
  SentimentNeutralOutlined,
  SentimentSatisfiedAltOutlined,
  SentimentVerySatisfiedOutlined
} from '@mui/icons-material'
import { MoodTypes } from '../AsideFeedback.types'

export const MOOD_LIST: MoodTypes[] = [
  {
    id: 'bad-mood',
    value: 1,
    icon: MoodBadOutlined,
    label: 'navigation.feedback.popover.moods.bad'
  },
  {
    id: 'dissatisfied-mood',
    value: 2,
    icon: SentimentDissatisfiedOutlined,
    label: 'navigation.feedback.popover.moods.dissatisfied'
  },
  {
    id: 'neutral-mood',
    value: 3,
    icon: SentimentNeutralOutlined,
    label: 'navigation.feedback.popover.moods.neutral'
  },
  {
    id: 'satisfied-mood',
    value: 4,
    icon: SentimentSatisfiedAltOutlined,
    label: 'navigation.feedback.popover.moods.satisfied'
  },
  {
    id: 'verySatisfied-mood',
    value: 5,
    icon: SentimentVerySatisfiedOutlined,
    label: 'navigation.feedback.popover.moods.verySatisfied'
  }
]
