import { AxiosError } from 'axios'
import { useFeedback } from 'context'
import { ApiResponseTypes, AssignmentServices } from 'services'
import { useInfiniteQuery } from 'react-query'

import { getNextPageParam } from 'utils/getNextParam'
import { useParamsSelector } from 'hooks'
import { UseQueryOptionsTypes } from '../queries.types'

export const useInfiniteAssignmentQuery = (
  options: UseQueryOptionsTypes | undefined = {}
) => {
  const { useParams, enabled } = options

  const params = useParamsSelector()

  const queryParams = useParams ? params.objParams : {}

  const { alert } = useFeedback()

  const { data, ...infiniteQuery } = useInfiniteQuery(
    ['/assignments', queryParams],
    () =>
      AssignmentServices.get({
        page: 1,
        pageSize: 20,
        assignmentStatus: queryParams.status,
        search: queryParams.search,
        orderBy: queryParams.orderBy || 'startDate',
        orderType: queryParams.orderType || 'DESC'
      }),
    {
      keepPreviousData: true,
      getNextPageParam,
      onError: (error: AxiosError<ApiResponseTypes<unknown>>) => {
        alert.showError(error.response?.data.message || error.message)
      },
      enabled
    }
  )

  return {
    data: data?.pages.flatMap((page) => page.data) || [],
    dataCount: data?.pages.flatMap((page) => page.pagination.dataCount)[0] || 0,
    ...infiniteQuery
  }
}
