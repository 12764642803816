import { StyleSheet } from '@react-pdf/renderer'
import { palette } from 'common/theme'

export const header = StyleSheet.create({
  default: {
    display: 'flex',
    flexDirection: 'column'
  },
  dataContent: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  leftContent: {
    display: 'flex',
    flexDirection: 'row'
  },
  dataText: {
    paddingBottom: 16,
    fontSize: 10,
    color: palette.common.black,
    marginRight: 30,
    lineHeight: 1.5,
    borderBottom: `1px solid ${palette.common.black}`
  },
  answerSheetText: {
    fontSize: 24,
    color: palette.common.black,
    fontWeight: 'bold'
  },
  titleText: {
    textAlign: 'center',
    fontSize: 16,
    color: palette.common.black,
    fontWeight: 'bold',
    marginVertical: 20
  }
})
