import {
  api,
  AssignmentsByStudentReportTypes,
  StudentTypes,
  PaginatedReportsResponseTypes,
  ReportSearchParamsTypes
} from 'services'

const studentAssignments = async ({
  studentId
}: {
  studentId: StudentTypes['id']
  params: ReportSearchParamsTypes
}) => {
  const response = await api.get<
    PaginatedReportsResponseTypes<AssignmentsByStudentReportTypes>
  >(`v2/reports/students/${studentId}/assignments`)

  return response.data
}

export default studentAssignments
