import { Checkbox } from '@mui/material'
import styled, { css } from 'styled-components'
import { Colors } from 'styles'
import { CheckboxPropTypes } from './Checkbox.types'

export const CheckboxStyles = styled(Checkbox)<CheckboxPropTypes>`
  color: ${Colors.grey}80;
  transition: all 0.2s;

  &.Mui-checked {
    color: ${(props) => props.color || Colors.secondary};
    transition: all 0.2s;
  }

  ${({ error }) =>
    error &&
    css`
      color: ${Colors.danger}80;
      &.Mui-checked {
        color: ${Colors.danger};
      }
    `}

  ${({ success }) =>
    success &&
    css`
      color: ${Colors.success}80;
      &.Mui-checked {
        color: ${Colors.success};
      }
    `}
    
  ${({ focused }) =>
    focused &&
    css`
      color: ${Colors.infoLight};
    `}
`
