import React, { useRef } from 'react'
import { Box, Divider, useMediaQuery, useTheme } from '@mui/material'

import { ReactComponent as NumetryLogo } from 'assets/svg/numetry-logo.svg'

import { ROUTES } from 'router'
import { useOverflow } from 'hooks'

import {
  FeideStatusComponent,
  LanguageSelectorComponent,
  LeftNavigationComponent,
  UserSettingsComponent
} from './components'

import {
  HeaderButtonsStyles,
  LayoutHeaderStyles,
  LogoLinkStyles
} from './Header.styles'
import { HeaderPropTypes } from './Header.types'

export const HeaderComponent: React.FC<HeaderPropTypes> = ({
  items,
  ...rest
}) => {
  const headerRef = useRef<HTMLDivElement | null>(null)
  const isMobileScreen = useMediaQuery(useTheme().breakpoints.down('md'))

  const isHeaderOverflown = useOverflow(headerRef)

  return (
    <LayoutHeaderStyles {...rest} id='header' ref={headerRef}>
      <Box display='flex' gap={3} alignItems='center'>
        {isMobileScreen && (
          <LeftNavigationComponent
            items={items}
            isHeaderOverflow={isHeaderOverflown}
          />
        )}
        <LogoLinkStyles to={ROUTES.BASE_ROOT}>
          <NumetryLogo />
        </LogoLinkStyles>
      </Box>

      <HeaderButtonsStyles isHeaderOverflow={isHeaderOverflown}>
        <FeideStatusComponent />
        <Box
          sx={{
            position:
              isHeaderOverflown && isMobileScreen ? 'absolute' : 'inherit',
            opacity: isHeaderOverflown ? 0 : 1,
            pointerEvents: isHeaderOverflown ? 'none' : 'inherit'
          }}
        >
          <LanguageSelectorComponent />
          <Divider orientation='vertical' flexItem />
        </Box>
        <UserSettingsComponent onlyIcon={isHeaderOverflown} />
      </HeaderButtonsStyles>
    </LayoutHeaderStyles>
  )
}

export * from './Header.types'
