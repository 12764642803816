import { atom } from 'recoil'
import { ReportsListTypes } from './types'

export const reportsList = atom<ReportsListTypes>({
  key: 'reportsList',
  default: {
    allAssignmentsStudentsList: [],
    pagination: {
      dataCount: 0,
      pageNumber: 0,
      pageSize: 20,
      totalRowCount: 0
    }
  }
})
