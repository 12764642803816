import { StyleSheet } from '@react-pdf/renderer'
import { palette } from 'common/theme'

export const footer = StyleSheet.create({
  default: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16
  },
  paginationText: {
    fontSize: 12,
    color: palette.common.black,
    fontWeight: 'bold'
  },
  answerSheetCode: {
    fontSize: 12,
    color: palette.common.black,
    fontWeight: 'light',
    position: 'absolute',
    right: 0,
    width: '100%',
    textAlign: 'right'
  }
})
