import { Typography, IconButton } from '@mui/material'
import styled from 'styled-components'

export const OrderByContainerStyles = styled.div`
  display: flex;
  align-items: center;
  width: max-content;

  button {
    color: ${({ theme }) => theme.palette.common.black};
  }
  color: ${({ theme }) => theme.palette.text.primary};
`

export const IconButtonStyles = styled(IconButton)`
  padding-right: 0;
  padding-left: 0;
  width: 3rem;
  height: 3rem;

  svg {
    font-size: 2.8rem;
    color: ${({ theme }) => theme.palette.common.black};
  }
`

export const TypographyStyles = styled(Typography)`
  display: grid;
  grid-template-columns: 2rem 15rem;
  align-items: center;
  gap: 0.6rem;

  div {
    height: 2rem;
  }

  svg {
    font-size: 2rem;
    color: ${({ theme }) => theme.palette.text.primary};
  }
`
