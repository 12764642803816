import { Typography, IconButton, Divider } from '@mui/material'
import React from 'react'
import { ArrowBackIosOutlined, CancelSharp } from '@mui/icons-material'
import { Trans } from 'react-i18next'
import { useDialogContext } from '../../context'
import {
  DialogHeaderStyles,
  HeaderStyles,
  SubtitleStyles
} from './DialogHeader.styles'

export const DialogHeader: React.FC = ({ children }) => {
  const { information, onClose, onBackClick } = useDialogContext()
  return (
    <DialogHeaderStyles>
      <HeaderStyles>
        {information?.title && (
          <Typography
            variant='h3'
            fontWeight={(theme) => theme.typography.fontWeightBold}
            alignItems='center'
            display='flex'
          >
            {onBackClick && (
              <IconButton onClick={onBackClick} color='primary'>
                <ArrowBackIosOutlined />
              </IconButton>
            )}
            {information?.title}
          </Typography>
        )}
        <IconButton
          sx={{ padding: 0 }}
          onClick={() => {
            if (onClose) onClose({}, 'backdropClick')
          }}
          color='primary'
        >
          <CancelSharp color='primary' fontSize='large' />
        </IconButton>
      </HeaderStyles>
      <Divider />
      {!!information?.description && (
        <>
          <SubtitleStyles>
            <Trans>
              <Typography variant='body1'>{information.description}</Typography>
            </Trans>
          </SubtitleStyles>
          <Divider />
        </>
      )}
      {children}
    </DialogHeaderStyles>
  )
}
