import React, { useRef } from 'react'

import { PopoverActions, SwipeableDrawer } from '@mui/material'
import { AsideFeedbackPropTypes } from './AsideFeedback.types'
import { AsideFeedbackPopoverStyles } from './AsideFeedback.styles'
import { AsideFeedbackContent } from './components'

export const AsideFeedbackComponent: React.FC<AsideFeedbackPropTypes> = ({
  onClose,
  isMobile,
  onOpen,
  ...rest
}) => {
  if (isMobile) {
    return (
      <SwipeableDrawer
        anchor='bottom'
        onClose={() => onClose && onClose()}
        onOpen={onOpen}
        open={rest.open}
        PaperProps={{
          sx: {
            borderRadius: '1.5rem 1.5rem 0 0',
            transition: 'all 0.25s ease'
          }
        }}
      >
        <AsideFeedbackContent onClose={onClose} />
      </SwipeableDrawer>
    )
  }

  const popoverActions = useRef<PopoverActions>(null)

  return (
    <AsideFeedbackPopoverStyles
      action={popoverActions}
      onClose={() => onClose && onClose()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      elevation={0}
      {...rest}
    >
      <AsideFeedbackContent
        onClose={onClose}
        onMoodChange={() => popoverActions.current?.updatePosition()}
      />
    </AsideFeedbackPopoverStyles>
  )
}

export * from './AsideFeedback.types'
