import { api, ApiResponseTypes } from 'services'
import { TagTypes } from './Tags.types'

const getAllTags = async () => {
  const { data: response } = await api.get<ApiResponseTypes<TagTypes[]>>(
    '/tags'
  )

  return response.data
}

export default {
  get: getAllTags
}
