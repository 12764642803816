import React from 'react'
import { TextField } from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers'

import { TimePickerPropTypes } from './TimePicker.types'
import { TimePickerContainerStyles } from './TimePicker.styles'

export const TimePickerComponent: React.FC<TimePickerPropTypes> = ({
  name,
  fullWidth,
  orientation = 'portrait',
  ...rest
}) => {
  const alignedCenterStyles = {
    sx: {
      '.MuiTimePickerToolbar-hourMinuteLabel': {
        alignItems: 'center'
      },
      '.MuiPickersToolbar-root': {
        gap: '1.6rem',
        padding: '1.6rem'
      }
    }
  }

  return (
    <TimePickerContainerStyles fullWidth={fullWidth} tabIndex={-1}>
      <TimePicker
        {...rest}
        InputProps={{ name }}
        showToolbar
        orientation={orientation}
        DialogProps={alignedCenterStyles}
        PaperProps={alignedCenterStyles}
        renderInput={({ error: _, ...params }) => <TextField {...params} />}
      />
    </TimePickerContainerStyles>
  )
}

export * from './TimePicker.types'
