import { AxiosError } from 'axios'
import { useFeedback } from 'context'
import { ApiResponseTypes, ReportsServices } from 'services'
import { useInfiniteQuery } from 'react-query'
import { getNextPageParam } from 'utils/getNextParam'

import { useParamsSelector } from 'hooks'

import { useParams } from 'react-router-dom'
import { UseQueryOptionsTypes } from '../../queries.types'

export const useInfiniteStudentsFromGameModeQuery = (
  options: UseQueryOptionsTypes | undefined = {}
) => {
  const params = useParamsSelector()

  const queryParams = options.useParams ? params.objParams : {}

  const { assignmentId, gameId, gameModeId } = useParams()

  const { alert } = useFeedback()

  const { data, ...infiniteQuery } = useInfiniteQuery(
    [
      'v2/reports/assignments/assignmentId/games/gameId/gameModes/gameModeId/students',
      queryParams,
      assignmentId,
      gameId,
      gameModeId
    ],
    (paginatedParams) =>
      ReportsServices.assignments.id.games.id.gameModes.id.students.paginated({
        assignmentId: Number(assignmentId),
        gameId: Number(gameId),
        gameModeId: Number(gameModeId),
        params: {
          orderBy: queryParams.orderBy,
          orderType: queryParams.orderType,
          search: queryParams.search,
          page: paginatedParams.pageParam
        }
      }),
    {
      keepPreviousData: true,
      getNextPageParam,
      onError: (error: AxiosError<ApiResponseTypes<unknown>>) => {
        alert.showError(error.response?.data.message || error.message)
      },
      enabled: options.enabled,
      cacheTime: 0
    }
  )

  return {
    data: data?.pages.flatMap((page) => page.data) || [],
    dataCount:
      data?.pages.flatMap((page) => page.pagination?.dataCount)[0] || 0,
    ...infiniteQuery
  }
}
