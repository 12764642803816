export enum Colors {
  primary = '#72FAFD',
  primaryLight = '#97FBFC',
  primaryDark = '#62D7D9',
  secondary = '#6300BE',
  secondaryLight = '#7700E6',
  secondaryDark = '#420080',
  lightPurple = '#7F81C3',
  light = '#818A91',
  dark = '#00034F',
  success = '#0FBE00',
  successLight = '#B2FFAC',
  successDark = '#0C8C00',
  danger = '#FF4D6F',
  dangerLight = '#FF8CA1',
  dangerDark = '#D9415D',
  info = '#007FFF',
  infoLight = '#8CC6FF',
  infoDark = '#0060BF',
  warning = '#F7D424',
  warningLight = '#FFF1AC',
  warningDark = '#D9BA21',
  orange = '#FF7B48',
  orangeLight = '#FFD0A0',
  orangeDark = '#FF4800',
  blue = '#CCDAE8',
  pastelBlue = '#8CC4fC',
  greyishBlue = '#DEEEFF',
  darkBlue = '#040353',
  lightBlue = '#D9ECFF',
  lighterBlue = '#F4F9FF',
  white = '#FFFFFF',
  ghostWhite = '#F8F8FF',
  black = '#212121',
  blackAlt = '#1A1A1A',
  greyBg = '#F8F7F5',
  blueFeide = '#285CA9',
  lightGrey = '#DDDDDD',
  lighterGrey = '#E3E3E3',
  lightestGrey = '#F4F4F4',
  progressLightGreen = '#D2FFCE',
  progressGreen = '#B6EBB2',
  progressDarkGreen = '#57D14D',
  lightPink = '#EDD9FF',
  yellow = '#FFF500',
  red = '#FF0000',
  green = '#1DFF00',
  grey = '#9A9A9A',
  greyishBlack = '#707070',
  concreteGrey = '#EAEAEA'
}

export enum PDFColors {
  white = '#FFFFFF',
  black = '#010000',
  grey = '#727272',
  greyLight = '#B7B6B6',
  secondary = '#52388D',
  primary = '#A4D9EF'
}

export type ColorsTypes = typeof Colors
