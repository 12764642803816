import { AxiosError } from 'axios'
import { useFeedback } from 'context'
import { useMutation } from 'react-query'
import { FeedbackServices, FeedbackPayloadTypes } from 'services'

export const useFeedbackQuery = () => {
  const { alert } = useFeedback()

  const feedbackQuery = useMutation(
    ['/feedbacks'],
    (values: FeedbackPayloadTypes) => FeedbackServices.post(values),
    {
      onError: (error: AxiosError) => {
        alert.showError(error.message)
      }
    }
  )

  return {
    feedbackQuery
  }
}
