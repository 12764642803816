import { PopperComponent } from 'components'
import { useAuth } from 'context'
import { AnimatePresence } from 'framer-motion'

import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  FeideStatusSpinnerStyles,
  FeideStatusWrapperStyles,
  MotionCloseIcon,
  MotionDoneIcon
} from './FeideStatus.styles'

const animationProps = {
  initial: {
    opacity: 0
  },
  exit: {
    opacity: 0
  },
  animate: {
    opacity: 1
  },
  transition: {
    duration: 0.25
  }
}
export const FeideStatusComponent = () => {
  const { feideFetchStatus } = useAuth()
  const { t } = useTranslation()

  if (feideFetchStatus === null) return null

  const getDisplayIcon = () => {
    if (feideFetchStatus === 'fetching') {
      return (
        <FeideStatusSpinnerStyles
          key='feideStatusFetching'
          thickness={6}
          {...animationProps}
          color='primary'
        />
      )
    }
    if (feideFetchStatus === 'success') {
      return <MotionDoneIcon key='feideStatusSuccessIcon' {...animationProps} />
    }
    if (feideFetchStatus === 'error') {
      return <MotionCloseIcon key='feideStatusErrorIcon' {...animationProps} />
    }

    return null
  }
  return (
    <PopperComponent
      header={{
        title: t(`general.feideFetchStatus.title`)
      }}
      content={t(`general.feideFetchStatus.${feideFetchStatus}`)}
    >
      <FeideStatusWrapperStyles status={feideFetchStatus}>
        <AnimatePresence exitBeforeEnter>{getDisplayIcon()}</AnimatePresence>
      </FeideStatusWrapperStyles>
    </PopperComponent>
  )
}
