import { useCallback, useEffect, useState } from 'react'

const getPortraitFromMediaQuery = () =>
  window.matchMedia('(orientation: portrait)')

export const useOrientation = () => {
  const initialIsPortrait = getPortraitFromMediaQuery().matches
  const [isPortrait, setIsPortrait] = useState(initialIsPortrait)

  const updateOrientation = useCallback((e: MediaQueryListEvent) => {
    if (e.matches) {
      setIsPortrait(true)
    } else {
      setIsPortrait(false)
    }
  }, [])

  useEffect(() => {
    const portrait = getPortraitFromMediaQuery()

    portrait.addEventListener('change', updateOrientation)

    return () => portrait.removeEventListener('change', updateOrientation)
  }, [updateOrientation])

  return { isPortrait, isLandscape: !isPortrait }
}
