export enum ROLE {
  SALES_TEAM = 'sales_team',
  TEACHER = 'teacher',
  STUDENT = 'student'
}

export enum TEACHER_TYPES {
  TEACHER = 'Teacher',
  MANAGER = 'TeacherManager',
  GUEST = 'TeacherGuest'
}

interface RegistrationTypes {
  applicationId: string
  id: string
  insertInstant: number
  lastLoginInstant: number
  lastUpdateInstant: number
  roles: ROLE[]
  usernameStatus: string
  verified: boolean
}

export interface UserTypes {
  active: boolean
  connectorId: string
  id: string
  insertInstant: number
  lastLoginInstant: number
  lastUpdateInstant: number
  passwordChangeRequired: boolean
  passwordLastUpdateInstant: number
  registrations: RegistrationTypes[]
  tenantId: string
  twoFactor: Record<string, unknown>
  uniqueUsername: string
  username: string
  usernameStatus: string
  verified: boolean
}

export interface UserInfoTypes {
  id: number
  name: string
  createdAt: Date
  updatedAt: Date
  roles: ROLE[]
  feideToken: string
  teacherType: TEACHER_TYPES
  fusionauthId: string
  fusionauthAccessToken: string
}
