import React, { lazy } from 'react'
import { Navigate, Outlet, Route } from 'react-router-dom'

import { ROUTES } from './Routes.types'

const Reports = lazy(() => import('views/report/Reports'))

const Assignments = lazy(
  () => import('views/report/views/assignments/Assignments')
)
const AssignmentIdFromAssignments = lazy(
  () => import('views/report/views/assignments/id/Assignment')
)
const StudentsFromAssignmentId = lazy(
  () => import('views/report/views/assignments/id/students/Students')
)
const MissionIdFromAssignmentId = lazy(
  () =>
    import('views/report/views/assignments/id/games/id/gameModes/id/GameMode')
)

const Students = lazy(() => import('views/report/views/students/Students'))
const StudentIdFromStudents = lazy(
  () => import('views/report/views/students/id/Student')
)
const AssignmentIdFromStudentId = lazy(
  () => import('views/report/views/students/id/assignments/id/Assignment')
)

const renderReportTabs = () => {
  const { REPORTS, ASSIGNMENTS, STUDENTS } = ROUTES

  return (
    <Route path={REPORTS} element={<Reports />}>
      <Route index element={<Navigate to={ASSIGNMENTS} />} />
      <Route path={ASSIGNMENTS} element={<Assignments />} />
      <Route path={STUDENTS} element={<Students />} />
    </Route>
  )
}

export const renderReportsRoutes = () => {
  const {
    REPORTS,
    ASSIGNMENTS,
    STUDENTS,
    ASSIGNMENT_ID,
    STUDENT_ID,
    GAMES,
    GAME_ID,
    GAME_MODES,
    GAME_MODE_ID
  } = ROUTES

  return (
    <>
      {renderReportTabs()}

      <Route path={REPORTS}>
        <Route path={ASSIGNMENTS} element={<Outlet />}>
          <Route path={ASSIGNMENT_ID} element={<Outlet />}>
            <Route index element={<AssignmentIdFromAssignments />} />
            <Route path={STUDENTS} element={<StudentsFromAssignmentId />} />
            <Route path={GAMES} element={<Outlet />}>
              <Route index element={<Navigate to={REPORTS} replace />} />
              <Route path={GAME_ID} element={<Outlet />}>
                <Route index element={<Navigate to={REPORTS} replace />} />
                <Route path={GAME_MODES}>
                  <Route index element={<Navigate to={REPORTS} replace />} />
                  <Route
                    path={GAME_MODE_ID}
                    element={<MissionIdFromAssignmentId />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>

        <Route path={STUDENTS} element={<Outlet />}>
          <Route path={STUDENT_ID}>
            <Route index element={<StudentIdFromStudents />} />
            <Route path={ASSIGNMENTS} element={<Outlet />}>
              <Route path='' />
              <Route
                path={ASSIGNMENT_ID}
                element={<AssignmentIdFromStudentId />}
              />
            </Route>
          </Route>
        </Route>
      </Route>
    </>
  )
}
