import { FactorTypes } from 'utils'

export const isFloat = (n: number) => Number.isFinite(n) && !Number.isInteger(n)

export const getNumberDivisibleBy = (
  num: number,
  { divisibleBy = 1 }: { divisibleBy?: FactorTypes['multiplyBy'] }
): number => {
  return Math.ceil(num / divisibleBy) * divisibleBy
}
