import { Slider } from '@mui/material'
import styled from 'styled-components'
import { Colors, FontWeights } from 'styles'

export const SliderStyles = styled(Slider)`
  color: ${Colors.info};
  height: 0.6rem;
  .MuiSlider-markActive {
    visibility: hidden;
  }
  & .MuiSlider-track {
    border: none;
  }
  & .MuiSlider-thumb {
    height: 1.6rem;
    width: 1.6rem;
    background-color: ${Colors.white};
    border: 0.1rem solid ${Colors.infoLight};
    box-shadow: 0 0 0.6rem ${Colors.black}29;
    &:focus,
    &:hover,
    &.Mui-active,
    &.Mui-focusVisible {
      box-shadow: inherit;
    }
    &:before {
      display: none;
    }
  }
`

export const NumberInputDivStyles = styled.div`
  display: flex;
  justify-content: space-between;
  gap: auto;
  .MuiBox-root {
    display: flex;
    gap: 1rem;
    flex-direction: row;
    align-items: center;
  }
  .MuiInputBase-root {
    border-color: ${Colors.lightBlue};
    width: 7rem;

    input {
      color: ${Colors.dark};
      font-weight: ${FontWeights.extraBold};
    }
  }
  :last-child {
    justify-content: flex-end;
  }
`

export const NumberInputButtonWrapperStyles = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.lighterBlue};
  height: 100%;
  padding: 0.25rem;
  padding-top: 0;
  .MuiIconButton-root {
    svg {
      color: ${Colors.info};
    }
    padding: 0;
    border-radius: 0;
    width: 1rem;
    height: 1.5rem;
  }
`
