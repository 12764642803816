import React from 'react'

import { DialogPropTypes } from './Dialog.types'
import { DialogStyles } from './Dialog.styles'

import { DialogContextWrapper } from './context'

export const Dialog: React.FC<DialogPropTypes> = (props) => {
  const {
    children,
    maxWidth = false,
    height = 'md',
    information,
    ...modalProps
  } = props

  return (
    <DialogContextWrapper {...props}>
      <DialogStyles
        height={height}
        maxWidth={maxWidth}
        keepMounted
        aria-labelledby={information?.title}
        aria-describedby={information?.description}
        information={information}
        {...modalProps}
      >
        {children}
      </DialogStyles>
    </DialogContextWrapper>
  )
}

export * from './components'
export * from './Dialog.types'
