import { from } from 'common/theme'
import styled from 'styled-components'

export const FormContainerStyles = styled.div`
  display: flex;
  flex-direction: column;
  ${from.md} {
    overflow: overlay;
  }
`

export const ItemContainerStyles = styled.div`
  width: 100%;
  min-height: 5.3rem;

  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  gap: 1rem;

  padding: 0.6rem 1.6rem;

  background: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 0 2rem ${({ theme }) => theme.palette.common.black}0A;

  border: 0.1rem solid ${({ theme }) => theme.palette.grey.A700};
  border-radius: 0.4rem;
`
