import {
  api,
  ApiPaginatedParamTypes,
  PaginatedResponse,
  SchoolTypes
} from 'services'
import { makeQueryParams } from 'utils/makeQueryParams'

const allSchools = async ({
  params: { page = 1, ...restOfParams }
}: ApiPaginatedParamTypes) => {
  const response = await api.get<PaginatedResponse<SchoolTypes>>(
    `/licenseManagement/schools?selectedPage=${page}${makeQueryParams(
      restOfParams
    )}`
  )

  return response.data
}

export default {
  paginated: allSchools
}
