import { Menu, MenuProps, Box } from '@mui/material'
import styled from 'styled-components'
import { Colors, FontSizes, FontWeights } from 'styles'
import { PopoverModeTypes } from './Popover.types'

export const PopoverStyles = styled(Box)<{ mode: PopoverModeTypes }>`
  .MuiIconButton-root {
    border-radius: 0;
    display: flex;
    padding: 0.4rem 0.8rem;
    svg {
      font-size: 2.8rem;
    }
    ${({ mode }) =>
      mode === 'language' &&
      `
      img {
        width: 2.4rem;
      }
    `}
  }
`

export const MenuStyles = styled(Menu)<
  MenuProps & { mode: PopoverModeTypes; hideElement?: boolean }
>`
  .MuiListItemIcon-root {
    svg {
      color: ${Colors.dark};
      font-size: ${({ mode }) =>
        mode === 'default' ? FontSizes.normalPlus : '2.4rem'};
    }
  }
  .MuiListItemText-root {
    .MuiTypography-root {
      font-size: ${({ theme }) => theme.typography.body1.fontSize};
    }
  }

  ${({ mode }) =>
    mode === 'language' &&
    `
    left: 2rem;
  `}

  ${({ hideElement }) =>
    hideElement &&
    `
      display: 'none';
    `}


  ${({ mode }) =>
    (mode === 'header' || mode === 'language') &&
    `
    top: 2.6rem;  
    .MuiListItemText-root {
      padding: 0.5rem 0.8rem 0 0;
      .MuiTypography-root {
        font-weight: ${
          mode === 'header' ? FontWeights.bold : FontWeights.normal
        };
        font-size: 1.4rem;
      }
    }
    .MuiPaper-root  {
      box-shadow: 0 0.18rem 0.37rem ${Colors.black}29;
      border-radius: 1rem;

      img {
        width: 3rem;
        margin-right: 1rem;
      }
      .MuiSvgIcon-root {
        font-size: 2.8rem;

      }
    }
  `}
`
