import { api } from '../../api'
import { AuthPayloadTypes } from './Auth.types'

const postLogin = async (payload: AuthPayloadTypes) =>
  api.post(`/fusionauth/login`, payload).then((response) => response.data)

const getValidatedToken = async () =>
  api.get(`fusionauth/validateToken`).then((data) => data)

const getLogout = async () => api.get(`/logout`)

export default {
  post: postLogin,
  validateCookie: getValidatedToken,
  logout: getLogout
}
