import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Box, Divider } from '@mui/material'
import { Colors } from 'styles'
import { from } from 'common/theme'

export const LayoutHeaderStyles = styled.header`
  position: fixed;
  width: 100%;
  padding: 0 2rem;

  min-height: 8.1rem;
  height: ${({ theme }) => theme.mixins.header?.height};

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.6rem;

  z-index: ${({ theme }) => theme.zIndex.appBar};

  background-color: ${Colors.white};
  box-shadow: 0 0.3rem 0.6rem ${Colors.black}15;
`

export const LogoLinkStyles = styled(Link)`
  svg {
    width: 8rem;
    ${from.md} {
      width: 12rem;
    }
  }
`

export const FilterBoxStyles = styled(Box)`
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .MuiIconButton-root {
    padding: 0;
    border-radius: 0;
    height: 100%;
  }

  ${from.md} {
    display: none;
  }
`

export const HeaderButtonsStyles = styled(Box)<{ isHeaderOverflow?: boolean }>`
  display: flex;
  align-items: center;
  gap: 1rem;

  transition: all 0.2s ease-in-out;
`

export const HeaderDividerStyles = styled(Divider)`
  background-color: ${Colors.lightBlue};
`
