import { ApiResponseTypes } from 'services/api/api.types'
import { makeQueryParams } from 'utils/makeQueryParams'
import { PaginatedReportsResponseTypes, ReportSearchPropTypes } from '..'
import { AssignmentTypes, GameModeTypes, GameTypes, api } from '../../../api'
import {
  AssignmentReportTypes,
  AvgStudentReportTypes,
  GameModeFromGameTypes,
  GamesAssignedToStudentReportTypes,
  GamesByAssignmentsReportTypes,
  StudentsByAssignmentReportTypes
} from './assignments.types'

const getAssignment = async ({
  assignmentId
}: {
  assignmentId: AssignmentTypes['id']
}) => {
  const { data: response } = await api.get<
    ApiResponseTypes<AssignmentReportTypes>
  >(`v2/reports/assignments/${assignmentId}`)

  return response.data
}

const studentsFromAssignment = async ({
  assignmentId,
  params: { page = 1, ...rest }
}: {
  assignmentId: AssignmentTypes['id']
} & ReportSearchPropTypes) => {
  const response = await api.get<
    PaginatedReportsResponseTypes<AvgStudentReportTypes>
  >(
    `v2/reports/assignments/${assignmentId}/students${makeQueryParams({
      selectedPage: page,
      ...rest
    })}`
  )

  return response.data
}

const gamesFromStudent = async ({
  assignmentId,
  studentId
}: {
  assignmentId: string
  studentId: string
}) => {
  const response = await api.get<
    PaginatedReportsResponseTypes<GamesAssignedToStudentReportTypes>
  >(`v2/reports/assignments/${assignmentId}/students/${studentId}/games`)

  return response.data
}

const gameModesFromAssignment = async ({
  params: {
    page = 1,
    pageSize = 20,
    assignmentId,
    userGroupId,
    search,
    ...rest
  }
}: ReportSearchPropTypes) => {
  const response = await api.get<
    PaginatedReportsResponseTypes<GamesByAssignmentsReportTypes>
  >(
    `v2/reports/assignments/${assignmentId}/gameModes${makeQueryParams({
      selectedPage: page,
      pageSize,
      userGroupId,
      search,
      ...rest
    })}`
  )

  return response.data
}

const gameModesFromGame = async ({
  assignmentId,
  gameId,
  params: { page = 1, pageSize = 20, ...rest }
}: {
  assignmentId: number
  gameId: number
} & ReportSearchPropTypes) => {
  const response = await api.get<
    PaginatedReportsResponseTypes<GameModeFromGameTypes>
  >(
    `v2/reports/assignments/${assignmentId}/games/${gameId}/gameModes${makeQueryParams(
      {
        selectedPage: page,
        pageSize,
        ...rest
      }
    )}`
  )

  return response.data
}

const gamesFromAssignment = async ({
  params: { page = 1, pageSize = 20, assignmentId, studentId, ...rest }
}: ReportSearchPropTypes) => {
  return api
    .get<PaginatedReportsResponseTypes<GamesByAssignmentsReportTypes>>(
      `v2/reports/students/${studentId}/assignments/${assignmentId}/games${makeQueryParams(
        {
          selectedPage: page,
          pageSize,
          ...rest
        }
      )}`
    )
    .then((response) => response.data)
}

const studentsFromGameMode = async ({
  assignmentId,
  gameId,
  gameModeId,
  params: { page = 1, pageSize = 20, ...rest }
}: {
  assignmentId: AssignmentTypes['id']
  gameId: GameTypes['id']
  gameModeId: GameModeTypes['id']
} & ReportSearchPropTypes) => {
  const response = await api.get<
    PaginatedReportsResponseTypes<StudentsByAssignmentReportTypes>
  >(
    `v2/reports/assignments/${assignmentId}/games/${gameId}/gameModes/${gameModeId}/students${makeQueryParams(
      {
        selectedPage: page,
        pageSize,
        ...rest
      }
    )}`
  )

  return response.data
}

export default {
  get: getAssignment,
  gameModes: {
    paginated: gameModesFromAssignment
  },
  students: {
    id: {
      paginated: gamesFromStudent
    },
    paginated: studentsFromAssignment
  },
  games: {
    id: {
      gameModes: {
        id: {
          students: {
            paginated: studentsFromGameMode
          }
        },
        paginated: gameModesFromGame
      }
    },
    paginated: gamesFromAssignment
  }
}
