import axios from 'axios'
import i18next from 'i18next'
import { serviceInterceptors } from 'services/utils'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    Language: i18next.language
  }
})

serviceInterceptors(api)

export * from './lib'
export * from './api.types'
export { api }
