/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom'
import { Box, Divider, Typography, Link, CircularProgress } from '@mui/material'
import { MutateOptions } from 'react-query'
import {
  Button,
  DisplayHeaderComponent,
  TableComponent
} from 'common/components'
import {
  useInfiniteUserGroupsBySchoolQuery,
  useTeachersCountBySchoolQuery
} from 'hooks'
import { Trans, useTranslation } from 'react-i18next'
import {
  UserGroupBySchoolTypes,
  EntityHasLicente,
  RevokeAndAssignClassParamTypes
} from 'services'
import { useLicense } from 'context'
import { ROUTES } from 'router'

import { createColumnHelper } from '@tanstack/react-table'
import { SchoolViewContainerStyles } from './School.styles'

export const SchoolView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { schoolId } = useParams()
  const { userGroupActions } = useLicense()

  const userGroupsBySchoolQuery = useInfiniteUserGroupsBySchoolQuery({
    schoolId,
    useParams: true
  })

  const teachersCountBySchoolQuery = useTeachersCountBySchoolQuery({
    schoolId
  })

  const columnHelper = createColumnHelper<UserGroupBySchoolTypes>()

  const columns = [
    columnHelper.accessor('name', {
      header: () => (
        <DisplayHeaderComponent
          title={t('pages.license.schoolView.classes.header.className')}
        />
      ),
      meta: {
        dataCell: t('pages.license.schoolView.classes.header.className')
      },
      cell: (params) => {
        const group = params.row.original

        if (group.studentCount === 0) {
          return <Typography>{params.getValue()}</Typography>
        }

        return (
          <Link
            component={RouterLink}
            to={`${ROUTES.CLASSES}/${group.id}`}
            underline='none'
            noWrap
            fontWeight='bold'
          >
            {params.getValue()}
          </Link>
        )
      }
    }),
    columnHelper.accessor('studentCount', {
      minSize: 120,
      maxSize: 120,
      header: () => (
        <DisplayHeaderComponent
          title={t('pages.license.schoolView.classes.header.numberOfStudents')}
        />
      ),
      meta: {
        dataCell: t('pages.license.schoolView.classes.header.numberOfStudents')
      },
      cell: (params) => <Typography>{params.getValue()}</Typography>
    }),
    columnHelper.accessor('assignedStudentCount', {
      minSize: 120,
      maxSize: 120,
      header: () => (
        <DisplayHeaderComponent
          title={t('pages.license.schoolView.classes.header.licensesAssigned')}
        />
      ),
      meta: {
        dataCell: t('pages.license.schoolView.classes.header.licensesAssigned')
      },
      cell: (params) => <Typography>{params.getValue()}</Typography>
    }),
    columnHelper.display({
      id: 'actions',
      header: () => null,
      minSize: 300,
      maxSize: 300,
      cell: (params) => {
        const group = params.row.original

        const variables: RevokeAndAssignClassParamTypes = {
          schoolId: Number(schoolId),
          groupId: group.id
        }

        const options: MutateOptions<
          EntityHasLicente,
          unknown,
          RevokeAndAssignClassParamTypes,
          unknown
        > = {
          onSuccess: () => userGroupsBySchoolQuery.refetch()
        }

        const assignLicensesToWholeClass =
          userGroupActions.assignLicensesToWholeClass()

        const revokeAllLicensesFromClass =
          userGroupActions.revokeAllLicensesFromClass()

        return (
          <Box display='flex' gap={1} width='100%' justifyContent='flex-end'>
            <Button
              disabled={group.assignedStudentCount === group.studentCount}
              loading={assignLicensesToWholeClass.isLoading}
              color='secondary'
              variant='contained'
              size='small'
              onClick={() => {
                assignLicensesToWholeClass.mutate(variables, options)
              }}
            >
              {t('pages.license.schoolView.classes.assignClass')}
            </Button>
            <Button
              disabled={group.assignedStudentCount === 0}
              loading={revokeAllLicensesFromClass.isLoading}
              color='secondary'
              variant='text'
              size='small'
              onClick={() => {
                revokeAllLicensesFromClass.mutate(variables, options)
              }}
            >
              {t('pages.license.schoolView.classes.revokeClass')}
            </Button>
          </Box>
        )
      }
    })
  ]

  return (
    <SchoolViewContainerStyles>
      <Box display='flex' flexDirection='column' gap={2} py={2} px={3}>
        <Typography variant='h3' fontWeight='bold'>
          {t('pages.license.schoolView.teachers.title')}
        </Typography>
        {teachersCountBySchoolQuery.isLoading ? (
          <CircularProgress size={20} color='inherit' />
        ) : (
          <Box
            display='flex'
            flexDirection='column'
            gap={2}
            py={2}
            px={3}
            maxWidth={300}
            border={({ palette }) => `0.1rem solid ${palette.divider}`}
            borderRadius={1}
          >
            <Typography gutterBottom fontWeight='bold'>
              {teachersCountBySchoolQuery.data?.name}
            </Typography>
            <Box display='flex' flexDirection='column' gap={1}>
              <Typography>
                <Trans
                  i18nKey='pages.license.schoolView.teachers.card.numberOfTeachers'
                  values={{
                    count: teachersCountBySchoolQuery.data?.teacherCount
                  }}
                  components={{
                    b: <Typography fontWeight='bold' />
                  }}
                />
              </Typography>
              <Typography>
                <Trans
                  i18nKey='pages.license.schoolView.teachers.card.numberOfActivatedLicenses'
                  values={{
                    count:
                      teachersCountBySchoolQuery.data?.assignedTeachersCount
                  }}
                  components={{
                    b: <Typography fontWeight='bold' />
                  }}
                />
              </Typography>
            </Box>
            <Box display='flex' justifyContent='flex-end'>
              <Button
                size='small'
                variant='contained'
                color='secondary'
                onClick={() => {
                  navigate(ROUTES.TEACHERS)
                }}
              >
                {t('pages.license.schoolView.teachers.manageTeachers')}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <Divider />
      <Box>
        <Typography variant='h3' fontWeight='bold' py={2} px={3}>
          {t('pages.license.schoolView.classes.title')}
        </Typography>
        <Divider />
        <TableComponent
          data={userGroupsBySchoolQuery.data}
          isLoading={userGroupsBySchoolQuery.isLoading}
          footer={{
            totalDataCount: userGroupsBySchoolQuery.dataCount
          }}
          infiniteScroll={{
            hasNextPage: userGroupsBySchoolQuery.hasNextPage,
            fetchNextPage: userGroupsBySchoolQuery.fetchNextPage,
            isFetchingNextPage: userGroupsBySchoolQuery.isFetchingNextPage
          }}
          columns={columns}
        />
      </Box>
    </SchoolViewContainerStyles>
  )
}
