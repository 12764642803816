import React, { lazy } from 'react'
import { Route } from 'react-router-dom'

import { ROUTES } from 'router'

const Library = lazy(() => import('views/library/Library'))

export const renderLibraryRoutes = () => {
  const { LIBRARY } = ROUTES

  return (
    <Route path={LIBRARY}>
      <Route index element={<Library />} />
    </Route>
  )
}
