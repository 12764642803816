import React from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SvgIcon, Typography, useMediaQuery, useTheme } from '@mui/material'

import { useAuth } from 'context'
import { AsideItemPropTypes } from './AsideItem.types'
import { AsideContainerStyles } from './AsideItem.styles'

export const AsideItemComponent: React.FC<AsideItemPropTypes> = ({
  item,
  isCollapsed = false
}) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const location = useLocation()
  const isMobileScreen = useMediaQuery(useTheme().breakpoints.down('md'))

  const isActive = item.to ? location.pathname.startsWith(item.to) : false

  if (item.teacherType && item.teacherType !== user?.teacherType) {
    return null
  }

  if (
    item.hideElement ||
    process.env[`REACT_APP_DISABLE_${item.name}`] === String(true)
  ) {
    return null
  }

  return (
    <AsideContainerStyles
      href={item.to || undefined}
      underline='none'
      isActive={isActive}
      isCollapsed={isMobileScreen ? false : isCollapsed}
      onClick={() => {
        if (!item.to && item.onClick) item.onClick()
      }}
    >
      <SvgIcon
        component={item.filledIcon && isActive ? item.filledIcon : item.icon}
        color='inherit'
        fontSize='medium'
      />
      <Typography
        color='inherit'
        fontWeight={(theme) => theme.typography.fontWeightBold}
      >
        {isMobileScreen && item.title.mobile
          ? t(item.title.mobile)
          : t(item.title.main)}
      </Typography>
    </AsideContainerStyles>
  )
}

export * from './AsideItem.types'
