import paginatedTeachers from './Teachers.paginated'
import teachersCountBySchoolMethods from './TeachersCount'
import { LicenseUserGroupServices } from './userGroups'

export const LicenseManagementSchoolIdServices = {
  ...teachersCountBySchoolMethods,
  teachers: {
    ...paginatedTeachers
  },
  userGroups: LicenseUserGroupServices
}

export * from './userGroups'
export * from './[id].types'
