import { ChipProps } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useIntersectionObserver } from 'hooks'
import { useInView } from 'framer-motion'
import { ChipListPropTypes } from './GameCardChipList.types'

import {
  GameCardChipListStyles,
  GameCardChipStyles
} from './GameCardChipList.styles'

export const GameCardChipListComponent: React.FC<ChipListPropTypes> = ({
  chips,
  containerRef
}) => {
  const [hasMoreIndex, setHasMoreIndex] = useState<number>()
  const inView = useInView(containerRef)

  const chipProps: ChipProps = {
    color: 'secondary',
    variant: 'light'
  }

  const renderChips = useCallback(() => {
    return chips.map((chip, index) => {
      const chipRef = useRef<HTMLDivElement>(null)

      const entry = useIntersectionObserver(chipRef, {
        root: containerRef.current,
        rootMargin: '0px -40px 0px 0px',
        threshold: 1
      })

      useEffect(() => {
        if (entry?.isIntersecting === false && !hasMoreIndex && index !== 0) {
          setHasMoreIndex(index)
        }
      }, [entry])

      return (
        <GameCardChipStyles
          {...chipProps}
          ref={chipRef}
          key={`${chip}-${index.toString()}`}
          label={chip}
          sx={
            entry?.isIntersecting === false && inView
              ? { display: 'none' }
              : undefined
          }
        />
      )
    })
  }, [chips, containerRef, hasMoreIndex, inView])

  return (
    <GameCardChipListStyles>
      {renderChips()}
      {hasMoreIndex && (
        <GameCardChipStyles
          {...chipProps}
          label={`+${chips.length - (hasMoreIndex || 0)}`}
        />
      )}
    </GameCardChipListStyles>
  )
}
