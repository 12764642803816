import { api } from '../../api'
import { StudentTypes } from './Students.types'

const revokeLicenses = async (
  studentIdOrIds: StudentTypes['id'] | StudentTypes['id'][]
) => {
  const studentIds = Array.isArray(studentIdOrIds)
    ? studentIdOrIds
    : [studentIdOrIds]

  const studentIdsAsString = studentIds.flatMap((studentId) =>
    String(studentId)
  )

  const response = await api.put<StudentTypes>(
    `/students/revokeLicenses`,
    studentIdsAsString
  )

  return response.data
}

export default revokeLicenses
