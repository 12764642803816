import styled from 'styled-components'

export const LayoutHeaderStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  strong {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`
