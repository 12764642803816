import { api, ApiResponseTypes } from 'services'
import { TagTypes } from './Tags.types'

const getTag = async (id: number) => {
  const { data: response } = await api.get<ApiResponseTypes<TagTypes>>(
    `/tags/${id}`
  )

  return response.data
}

export default {
  get: getTag
}
