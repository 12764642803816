import { MenuItem } from '@mui/material'
import styled from 'styled-components'

export const MenuItemStyles = styled(MenuItem)`
  padding: 0.4rem 1.2rem;
`

export const MenuItemContentStyles = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  gap: 0.5rem;
  width: 100%;

  p {
    overflow-x: auto;
    text-overflow: clip;
  }
`
