import React, { useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { SearchComponent, SelectComponent } from 'components'
import { ParamKeyTypes, ParamTypes, useParamsSelector } from 'hooks'

import {
  FiltersBarDesktopStyles,
  FiltersBarDesktopWrapperStyles,
  FiltersBarDesktopLeftStyles,
  FiltersBarDesktopRightStyles
} from './FiltersBarDesktop.styles'
import { FiltersBarDesktopPropTypes } from './FiltersBarDesktop.types'
import { FilterByLabelStyles } from '../Template.styles'

export const FiltersBarDesktopComponent: React.FC<
  FiltersBarDesktopPropTypes
> = ({
  renderFilterBySelections,
  variant = 'row',
  isStickedOnTop,
  leftAdornment,
  clearAllFilters,
  borderBottom,
  borderTop,
  disableFilterLabel = false,
  searchPlaceholder,
  flexDirection = 'row'
}) => {
  const { t } = useTranslation()
  const params = useParamsSelector()
  const [searchedField, setSearchedField] = useState<string>('')
  const [selectedFields, setSelectedFields] = useState<ParamTypes[]>([])

  const handleDeleteSearch = () => {
    const searchParamList = params.getAll().filter((param) => {
      return param.key.includes('search')
    })

    searchParamList.forEach(({ key }) => {
      params.delete(key)
    })

    setSearchedField('')
  }

  const handleDeleteSelect = (index: number) => {
    setSelectedFields((prev) =>
      prev.filter((field) => field.key !== selectedFields[index].key)
    )

    params.delete(selectedFields[index].key)
  }

  const handleDeleteSelectedFields = () => {
    selectedFields.forEach((_, index) => {
      handleDeleteSelect(index)
    })

    setSelectedFields([])
  }

  useEffect(() => {
    if (clearAllFilters) {
      handleDeleteSearch()
      handleDeleteSelectedFields()
    }
  }, [clearAllFilters])

  const leftChildren = () => {
    if (!renderFilterBySelections) return null

    return (
      <Stack spacing={2} direction={{ md: 'row', xs: 'column' }} width='100%'>
        {renderFilterBySelections.map(({ ...filter }, index) => {
          const initialValue = ''
          const curValue =
            selectedFields.find((f) => f.key === filter.name)?.value ||
            initialValue

          return (
            <SelectComponent
              {...filter}
              fullWidth={flexDirection === 'column'}
              key={filter.name}
              value={curValue}
              onChange={({ name: key, value }) => {
                const field: ParamTypes = { key: key as ParamKeyTypes, value }

                const hasFieldKey = selectedFields.some((f) => f.key === key)
                const hasSameValue = selectedFields.some(
                  (f) => f.value === value
                )
                if (hasFieldKey && !hasSameValue) {
                  setSelectedFields((prev) =>
                    prev.map((field) =>
                      field.key === key ? { ...field, value } : field
                    )
                  )
                } else {
                  setSelectedFields((prev) => [...prev, field])
                }

                params.add(field)
              }}
              onClearClick={() => {
                handleDeleteSelect(index)
              }}
            />
          )
        })}
        {leftAdornment}
      </Stack>
    )
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchedField) {
        params.add({ key: 'search', value: searchedField })
      } else {
        params.delete('search')
      }
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [searchedField])

  return (
    <FiltersBarDesktopStyles isStickedOnTop={!!isStickedOnTop}>
      <FiltersBarDesktopWrapperStyles
        borderBottom={borderBottom}
        borderTop={borderTop}
        variant={variant}
        flexDirection={flexDirection}
      >
        {renderFilterBySelections?.length && (
          <FiltersBarDesktopLeftStyles spacing={2} direction='row'>
            {!disableFilterLabel && (
              <FilterByLabelStyles
                variant='body2'
                fontWeight={(theme) => theme.typography.fontWeightBold}
              >
                {t('general.filters.text.filterBy')}
              </FilterByLabelStyles>
            )}
            {leftChildren()}
          </FiltersBarDesktopLeftStyles>
        )}

        <FiltersBarDesktopRightStyles direction='row'>
          <SearchComponent
            value={searchedField}
            name='search'
            onChange={(e) => setSearchedField(e.currentTarget.value)}
            onClearClick={handleDeleteSearch}
            placeholder={searchPlaceholder}
          />
        </FiltersBarDesktopRightStyles>
      </FiltersBarDesktopWrapperStyles>
    </FiltersBarDesktopStyles>
  )
}

export * from './FiltersBarDesktop.types'
