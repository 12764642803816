import React from 'react'
import { Check } from '@mui/icons-material'
import { SvgIcon, Typography } from '@mui/material'

import { capitalizeFirstLetter } from 'utils/textHelpers'

import { MenuItemPropTypes } from './MenuItem.types'
import { MenuItemStyles, MenuItemContentStyles } from './MenuItem.styles'

export const MenuItemComponent: React.FC<MenuItemPropTypes> = ({
  enableArrowMark,
  item,
  isSelected,
  ...rest
}) => {
  return (
    <MenuItemStyles
      {...rest}
      value={item.value}
      selected={isSelected}
      tabIndex={0}
    >
      <MenuItemContentStyles>
        {enableArrowMark && (
          <SvgIcon fontSize='small'>{isSelected && <Check />}</SvgIcon>
        )}
        <Typography noWrap>{capitalizeFirstLetter(item.label)}</Typography>
      </MenuItemContentStyles>
    </MenuItemStyles>
  )
}
