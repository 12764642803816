import styled, { css } from 'styled-components'
import { Box } from '@mui/material'
import { from } from 'common/theme'

export const StackStyles = styled(Box)<{ isFeideGroup?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  ${({ isFeideGroup }) =>
    isFeideGroup &&
    css`
      display: grid;

      ${from.sm} {
        grid-template-columns: repeat(2, 1fr);
      }
    `}
`

export const StackFooterStyles = styled.div`
  min-height: 5.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.common.white};
`
