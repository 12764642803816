import { GeneratorInputTypes } from 'utils'
import { SymbolTypes } from '../symbol/symbol.types'

export enum OPERATION_KEY {
  MULTIPLICATION = 'multiplication',
  DIVISION = 'division',
  FRACTION = 'fraction',
  ADDITION = 'addition',
  SUBTRACTION = 'subtraction'
}

export interface OperationTypes {
  key: OPERATION_KEY
  symbol: SymbolTypes
  getName: () => string
  getOperationValues: (factors: GeneratorInputTypes['factors']) => {
    n1: number
    n2: number
    result: number
  }
}
