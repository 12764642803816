import {
  api,
  ApiPaginatedParamTypes,
  PaginatedResponse,
  StudentHasLicenseTypes
} from 'services'
import { makeQueryParams } from 'utils/makeQueryParams'

const teachersCountBySchool = async ({
  params: { page = 1, groupId, ...restOfParams }
}: ApiPaginatedParamTypes) => {
  const response = await api.get<PaginatedResponse<StudentHasLicenseTypes>>(
    `/licenseManagement/user-groups/${groupId}/students${makeQueryParams({
      selectedPage: page,
      ...restOfParams
    })}`
  )

  return response.data
}

export default teachersCountBySchool
